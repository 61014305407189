import {Component, OnInit} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {DashboardLayoutComponent} from "./layouts/dashboard-layout/dashboard-layout.component";
import {LoginComponent} from "./pages/auth/login/login.component";
import {initFlowbite} from "flowbite";
import {NgxSpinnerComponent} from "ngx-spinner";
import {registerLocaleData} from "@angular/common";
import {AuthStateService} from "./core/services/auth/auth-state.service";
import {Store} from "@ngrx/store";
import {updateShowSummary, updateStep, updateStep1} from "./store/reservationForm/stepper.actions";
import {BookFormService} from "./core/services/book-form.service";
import {AuthService} from "./core/services/auth/auth.service";
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DashboardLayoutComponent, LoginComponent, NgxSpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'web_itv';
  ngOnInit() {
    initFlowbite();
  }

  constructor(private authStateService: AuthStateService, private store: Store,
              private bookFormService: BookFormService, public authService: AuthService,) {
    this.authStateService.currentUser.subscribe({
      next: data => {
        if (data && data.roles.includes('professional')) {
          /*this.store.dispatch(updateStep1({ step1: {registration_number: data.profile.name} }));
          this.store.dispatch(updateStep({ step: 2 }));*/
          this.bookFormService.updateStep1({registration_number: data.profile.name,})
          if (window.innerWidth > 1024) {
            this.bookFormService.setShowSummary(true);
          } else {
            this.bookFormService.setShowSummary(false);
          }
        }
      }
    })


    if (this.authStateService.currentUserValue) {
      this.authService.profile().subscribe({
        next: data => {
          if (data.data) {
            this.authStateService.setRoles(data.data.roles);
            this.authStateService.setPermissions(data.data.permissions);
            this.authStateService.setProfile(data.data.profile);
          }
        },
        error: error => {
          console.log(error);
        }
      })
    }
  }
}
