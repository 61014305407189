<div class="w-full animate-fade-in-down">

  <div *ngIf="substep === 1" class="animate-fade-in-down">
    <p class="text-xl text-darkGray mb-10">¿Qué tipo de inspección va a pasar?</p>
    <app-card-shadow [showLoading]="loading">
      <app-card-selector *ngFor="let service of services" (onClickCard)="selectService(service)" [selected]="serviceSelected?.id == service.id">
        {{service.name}}
      </app-card-selector>
    </app-card-shadow>
  </div>

  <div *ngIf="substep === 2 && serviceSelected" class="animate-fade-in-down">
    <div *ngIf="serviceSelected.type == 'message'" class="border-2 border-green text-green p-2 rounded-full font-bold">
      {{serviceSelected.no_open_message}}
    </div>
    <div *ngIf="serviceSelected.type == 'open'">
      <p  class="text-xl text-darkGray mb-10">¿Cuál es tu vehículo?</p>
      <app-card-shadow [showLoading]="loading">
        <app-card-selector *ngFor="let vehicle of vehicles" (onClickCard)="selectVehicle(vehicle)" [selected]="vehicleSelected?.id == vehicle.id">
          <img class="mr-4 w-10 h-10 object-contain" [src]="vehicle.logo ? vehicle.logo.original_url : '/assets/img/not-found.png'" [alt]="vehicle.name"><span>{{vehicle.name}}</span>
        </app-card-selector>
      </app-card-shadow>
    </div>
  </div>

  <div *ngIf="substep === 3 && vehicleSelected" class="animate-fade-in-down">
    <div *ngIf="vehicleSelected.type == 'message'" class="border-2 border-green text-green p-2 rounded-full font-bold">
      {{vehicleSelected.no_open_message}}
    </div>
    <div  *ngIf="vehicleSelected.type == 'open'">
      <p class="text-xl text-darkGray mb-10">¿Cuál es el tipo de transmisión?</p>
      <app-card-shadow [showLoading]="loading">
        <app-card-selector *ngFor="let fuel of fuels" (onClickCard)="selectFuel(fuel)" [selected]="fuelSelected?.id == fuel.id">
          {{fuel.name}}
        </app-card-selector>
      </app-card-shadow>
    </div>
  </div>


  <div class="flex flex-row justify-between mt-4" *ngIf="!isProfessional">
    <button (click)="backSubStep()" appFrontBack>
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
      <span>Atrás</span>

    </button>
  </div>


</div>
