<div class="relative flex min-h-screen">
  <!-- Div izquierda que ocupa todo el espacio disponible -->
  <div class="flex-grow">
    <app-stepperv2 [formPassed]="true"></app-stepperv2>
    <div *ngIf="user && authService.isNotProfessional()" class="w-full bg-yellow text-darkGray text-center py-1">
      <p *ngIf="user.profile">Estás logueado como: {{ user.profile.email }} - {{ user.roles }} - <a routerLink="/dashboard">Dashboard</a></p>
    </div>
    <div [class.max-w-7xl]="(currentStep == 4)" [class.!max-w-4xl]="currentStep == 7 || currentStep == 8"
         [class.max-w-xl]="(currentStep != 4 && currentStep != 7 && currentStep != 1)" [class.max-w-2xl]="currentStep == 1" class="w-full mx-auto mt-12 lg:mt-24">
      <app-formv2></app-formv2>
    </div>
    <button *ngIf="currentStep > 1 && currentStep < 7" (click)="toggleSummary()"
            class="absolute top-5 right-5 hover:shadow-2xl duration-300">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
           stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
           class="w-6 h-6 text-darkGray">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M15 15h2"/>
        <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"/>
        <path d="M11 12h6"/>
        <path d="M13 9h4"/>
      </svg>
    </button>
  </div>

  <!-- Div derecha con ancho fijo de w-80 y animaciones -->
  <div [ngClass]="{'translate-x-0 ': showSummary, 'translate-x-full hidden': !showSummary}"
    class="w-80 h-full xl:h-auto bg-gray-100 py-8 px-6 absolute top-0 right-0 transition-transform duration-300 ease-in-out xl:relative xl:flex-none xl:translate-x-0">
    <div class="sticky top-0 pt-8">
      <app-summaryv2 ></app-summaryv2>
    </div>
  </div>
</div>
