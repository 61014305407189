import {Component, ViewChild} from '@angular/core';
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {ToastrService} from "ngx-toastr";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {Paginator} from "../../../../models/Paginator";
import {Vehicle} from "../../../../models/Vehicle";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {Filter} from "../../../../models/Table/Filter";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";
import {TableActions} from "../../../../models/Table/TableActions";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormErrorDirective} from "../../../../shared/directives/forms/form-error.directive";
import {FormInputDirective} from "../../../../shared/directives/forms/form-input.directive";
import {FormLabelDirective} from "../../../../shared/directives/forms/form-label.directive";
import {InputSwitchModule} from "primeng/inputswitch";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";

@Component({
  selector: 'app-vehicles',
  standalone: true,
  imports: [
    GenericTableComponent,
    TableFiltersComponent,
    TableHeaderWrapperComponent,
    NgIf,
    FormErrorDirective,
    FormInputDirective,
    FormLabelDirective,
    InputSwitchModule,
    LoaderComponent,
    NgForOf,
    ReactiveFormsModule,
    FormsModule,
    JsonPipe,
  ],
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.css'
})
export class VehiclesComponent {

  loading:boolean=false;
  vehicles:Paginator<Vehicle>| null = null;

  editVehicle?: {action: TableActions, item: Vehicle, index: number} = undefined;

  editForm?: FormGroup;
  loadingEdit: boolean = false;

  /*pondOptions: FilePondOptions = {
    allowMultiple: false,
    labelIdle: 'Arrastra archivos aquío'
  }

  pondFiles: FilePondOptions["files"] = []*/

  newImage?: File;
  newImagePreviewUrl?: string;


  @ViewChild('genericTableComponent') genericTableComponent!: GenericTableComponent<Vehicle>;


  constructor(private breadcrumbService: BreadcrumbService,
              private stationService: StationService,
              private vehicleService:VehiclesService,
              private fb: FormBuilder,
              private toastr: ToastrService) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Vehículos',
      items: [{
        name: 'Listado',
      }]
    });
  }

  ngOnInit() {
    this.loading = true;
    this.loadVehicles();
  }

  loadVehicles(){
    this.vehicleService.listpPaginate(this.sorts, this.filters, this.perPage).subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.vehicles = response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  perPage: number = 50;
  loadingList = false;
  loadingFilters = false;

  filters: ActiveFilter[] = [];
  sorts: SortColumn[] = [];

  tableHeaders: TableHeader[] = [
    {
      name: 'ID',
      active: true,
      td_type: 'text',
      td_key: 'id',
      sort: true,
    },
    {
      name: 'ITV ID',
      active: true,
      td_type: 'text',
      td_key: 'other_id',
      sort: false,
    },
    {
      name: 'IMAGEN',
      active: true,
      td_type: 'image',
      td_key: 'logo',
      td_sub_key: 'original_url',
      image_class: 'w-10 h-10',
      sort: false,
    },
    {
      name: 'NOMBRE',
      active: true,
      td_type: 'text',
      td_key: 'name',
      sort: true,
    },
    {
      name: 'NOMBRE CORTO',
      active: true,
      td_type: 'text',
      td_key: 'short_name',
      sort: false,
    },
    {
      name: 'SLUG',
      active: false,
      td_type: 'text',
      td_key: 'slug',
      sort: false,
    },
    {
      name: 'MENSAJE',
      active: false,
      td_type: 'text',
      td_key: 'no_open_message',
      sort: false,
    },
    {
      name: 'TIPO',
      active: true,
      td_type: 'text',
      td_key: 'type',
      sort: false,
    },
    {
      name: 'FECHA CREACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'created_at',
      sort: false,
    },
    {
      name: 'FECHA MODIFICACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'updated_at',
      sort: false,
    }
  ]

  tableActions: TableActions[] = [
    {
      icon: '<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"   class="w-8"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>',
      name: 'Editar',
      key: 'update',
      color: '#ff9900',
      confirm: false,
    }
  ];

  tableFilters: Filter[] = [
    {
      key: 'name',
      type: 'search',
      operator: 'like',
      placeholder: 'Buscar...'
    }
  ]

  displayNames: { [key: string]: string } = {
    name: 'Nombre',
  };

  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    this.loadVehicles();
  }

  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    this.loadVehicles();
  }

  onFileChange(event: any) {
    if (this.editForm) {
      this.newImage = event.target.files[0];

      if (this.newImage) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.newImagePreviewUrl = e.target.result; // Asigna la URL de la imagen previsualizada
        };
        reader.readAsDataURL(this.newImage);
      }
    }
  }

  setEditVehicle(data: {action: TableActions, item: Vehicle, index: number}){
    this.editForm = this.fb.group({
      name: [data.item.name, Validators.required],
      short_name: [data.item.short_name, Validators.required],
      type: [data.item.type, Validators.required],
      no_open_message: [data.item.no_open_message],
      other_id: [data.item.other_id],
      /*paymentMethods: new FormControl<number[] | null>([],[ Validators.required, Validators.minLength(1)])//[],//[null, Validators.required, Validators.minLength(1)],*/
    });
    if (data.item.logo) {
      /*this.pondFiles = [
        {
          source: data.item.logo.original_url,
          options: {
            type: 'input'
          }
        }
      ];*/
    }

    this.editVehicle = data;
  }
  onClickAction(data: {action: TableActions, item: Vehicle, index: number}) {
    if (data.action.key == 'update') {
      this.setEditVehicle(data);
    }

    /*this.confirmationService.confirm({
      target: data.event.target as EventTarget,
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",

      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted' });
      },
      reject: () => {
        // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });*/
  }

  closeEditModal(){
    this.editVehicle = undefined;
    this.editForm = undefined;
    this.newImage = undefined;
    this.newImagePreviewUrl = undefined;
  }

  update(){
    if (this.editForm && this.editForm.valid && this.editVehicle) {

      //console.log(this.editForm.value);
      //this.loadingEdit = true;

      this.loadingEdit = true;
      //console.log(this.newImage);
      this.vehicleService.update(this.editVehicle.item.id, this.editForm.value, this.newImage).subscribe({
        next: data => {
          this.toastr.success('Tipo de vehículo editado correctamente.');
          if (data.data && this.editVehicle) {
            this.editVehicle.item = data.data;
            this.setEditVehicle(this.editVehicle);
            this.newImage = undefined;
            this.genericTableComponent.setItemAt(this.editVehicle.index, this.editVehicle.item);
          }

          this.loadingEdit = false;
        },
        error: err => {
          this.toastr.error('Ha habido un error al editar el tipo de vehiculo.');
          this.loadingEdit = false;
        }
      })
      /*this.stationService.update(this.form.value, this.station!.id).subscribe({
        next: (data) => {
          if (data.data) {
            this.station = data.data;
            this.form.patchValue(data.data);
            this.completeBreadcrumb(this.station);
            this.toastr.success('Estación editada correctamente.');
            this.loadingEdit = false;
          }
        },
        error: error => {
          this.toastr.error('Ha habido un error al editar la estación.');
          this.loadingEdit = false;
        }
      })*/

      //this.stationFormService.setStep1(this.form.value);
    } else {
      this.editForm?.markAllAsTouched();
    }
  }
}
