import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {User} from "../../../models/User";
import {environment} from "../../../../environments/environment";
import {Paginator} from "../../../models/Paginator";
import {Reservation, ReservationCanceled} from "../../../models/Reservation";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {Station} from "../../../models/Station";
import {Service} from "../../../models/Service";
import {Vehicle} from "../../../models/Vehicle";
import {completeHttpFiltersAndSorts, getEventProgress} from "../../../const/functions";

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private _http: HttpClient) { }

  list(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): /*Observable<number> |*/  Observable<Response<Paginator<Reservation>>> {

    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this._http.get<Response<Paginator<Reservation>>>(`${environment.API_URL}/reservations/admin/list/${perPage}`,
      { params/*, reportProgress: true, observe: 'events' */});/*.pipe(
      map(event => getEventProgress(event))
    );*/
  }

  listCanceled(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): /*Observable<number> |*/  Observable<Response<Paginator<ReservationCanceled>>> {
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this._http.get<Response<Paginator<ReservationCanceled>>>(`${environment.API_URL}/reservations/admin/list-cancel/${perPage}`,
      { params/*, reportProgress: true, observe: 'events' */});/*.pipe(
      map(event => getEventProgress(event))
    );*/
  }

  listForCsv(sorts: SortColumn[] = [], filters: ActiveFilter[] = []): /*Observable<number> |*/  Observable<Response<Reservation[]>> {
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this._http.get<Response<Reservation[]>>(`${environment.API_URL}/reservations/admin/list-export`,
      { params/*, reportProgress: true, observe: 'events' */});/*.pipe(
      map(event => getEventProgress(event))
    );*/
  }

  listForCsvCanceled(sorts: SortColumn[] = [], filters: ActiveFilter[] = []): /*Observable<number> |*/  Observable<Response<ReservationCanceled[]>> {
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this._http.get<Response<ReservationCanceled[]>>(`${environment.API_URL}/reservations/admin/list-export-cancel`,
      { params/*, reportProgress: true, observe: 'events' */});/*.pipe(
      map(event => getEventProgress(event))
    );*/
  }


  updateState(reservationId:number){
    return this._http.get<Response<Reservation>>(`${environment.API_URL}/reservations/admin/update-state/${reservationId}`);
  }


  updateAdmin(reservationId:number,data:any){
    return this._http.patch<Response<Reservation>>(`${environment.API_URL}/reservations/admin/update/${reservationId}`,data);
  }

  update(reservationId:number,data:any, phone: string|null) {
    // Crear los encabezados y agregar el número de teléfono si está disponible
    let headers = new HttpHeaders();
    if (phone) {
      headers = headers.set('phone', phone);
    }

    // Realizar la solicitud HTTP con los headers incluidos
    return this._http.patch<Response<Reservation>>(
      `${environment.API_URL}/reservations/update/${reservationId}`,
      data,
      { headers }
    );
  }

}
