import { Component, Input } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { NgForOf } from "@angular/common";

@Component({
  selector: 'app-table-bulk-actions',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf
  ],
  templateUrl: './table-bulk-actions.component.html',
  styleUrl: './table-bulk-actions.component.css'
})
export class TableBulkActionsComponent {

  @Input() items: any[] = []; // Elementos de la tabla
  @Input() bulkActions: { label: string, action: (items: any[]) => void }[] = []; // Acciones en masa

  // Ejecutar una acción en masa en todos los elementos
  executeBulkAction(action: any) {
    if (this.items.length > 0) {
      action.action(this.items);
    } else {
      alert('No hay elementos disponibles.');
    }
  }
}
