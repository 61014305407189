<div class="w-full">

  <div class="grid grid-cols-3 w-fit gap-3 mb-4" *ngIf="currentStep < 5">
    <div (click)="setProfessional(false)" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{!isProfessional ? 'bg-lightBlue' : ''}}">
      <p class="duration-200 {{!isProfessional ? 'text-primary' : 'text-lightGray'}}">PARTICULARES</p>
    </div>
    <div (click)="setProfessional(true)" class="flex cursor-pointer justify-center border border-lightGray2 items-center py-1 px-5 rounded-2xl shadow-md hover:bg-lightBlue duration-300 {{isProfessional ? 'bg-lightBlue' : ''}}">
      <p class="duaration-200 {{isProfessional ? 'text-primary' : 'text-lightGray'}}">PROFESIONALES</p>
    </div>
  </div>

  <div class="grid grid-cols-9 w-full">
    <!-- Componente a la izquierda -->
    <app-summaryv2 class="col-span-2" *ngIf="currentStep < 7"></app-summaryv2>

    <!-- Contenido principal del formulario -->
    <div class="col-span-5" [class.col-span-9]="currentStep >= 7" [class.col-span-7]="currentStep > 5 && currentStep < 7">
      <app-appointment-form-v2 [forceProfessional]="isProfessional" (stationsLoaded)="stations = $event" (loadingStations)="loadingStations = $event"></app-appointment-form-v2>
    </div>

    <!-- Columna desplazable de estaciones -->
    <app-other-stations
      *ngIf="currentStep < 5"
      [stations]="stations"
      [loading]="loadingStations"
      class="col-span-2 scrollable overflow-y-auto">
    </app-other-stations>
  </div>
</div>


