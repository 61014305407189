<div class="animate-fade-in-down">
  <h1 class="text-2xl">Elige la forma de pago</h1>
  <p class="text-xs mb-20">Paga como prefieras</p>
  <div class="flex flex-col mb-10 gap-y-4 w-full">
      <div class="flex flex-row gap-x-2 text-start items-center" *ngFor="let payment_method of paymentMethods">
        <input type="radio" [(ngModel)]="selectedPaymentMethod" [checked]="selectedPaymentMethod?.id===payment_method.id" [value]="payment_method">
        <label class="">{{payment_method.front_name}}</label>
      </div>
      <!--<p class="text-center">46,50€</p>-->

    <!--<div class="grid grid-cols-3 py-8 border-b border-t border-gray-300 items-center w-full">
      <div class="flex flex-row gap-x-2 text-start">
        <input type="radio" formControlName="payment" value="credit_card">
        <label class="">Pagar con tarjeta</label>
      </div>
      <div class="flex flex-row gap-x-3 justify-center items-center">
        <img class="w-12" src="assets/Visa_Inc._logo.svg">
        <img class="w-8" src="assets/Mastercard-logo.svg">
      </div>
      <p class="text-center">46,50€</p>
    </div>
    <div class="grid grid-cols-3 py-5 items-center w-full">
      <div class="flex flex-row gap-x-2 text-start">
        <input type="radio" formControlName="payment" value="bizum">
        <label class="">Pagar con bizum</label>
      </div>
      <div class="flex flex-row justify-center">
        <img class="w-14 fill-gray-500" alt="" src="assets/Bizum.svg">
      </div>
      <p class="text-center">46,50€</p>
    </div>-->
    <div class="flex items-center justify-between mt-3">
      <button (click)="goBack()" appFrontBack>
        ← Atrás
      </button>
      <div class="max-w-48">
        <button (click)="update()" [disabled]="!selectedPaymentMethod" type="button" appNextButton>
          Continuar
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 12l14 0" />
            <path d="M15 16l4 -4" />
            <path d="M15 8l4 4" />
          </svg>
        </button>
      </div>
    </div>
  </div>

</div>

<!--<div class="" *ngIf="substep==2">
  <h1 class="text-2xl text-center">Revisa tus datos y paga telemáticamente</h1>
  <div class="grid grid-cols-3 gap-x-4 mt-10 w-full max-w-3xl mx-auto">
    <div class="border-b border-gray-300 text-blue-500 text-start py-2">Vehículo</div>
    <div class="border-b border-gray-300 text-blue-500 text-start py-2">Centro ITV</div>
    <div class="border-b border-gray-300 text-blue-500 text-start py-2">Fecha de la cita</div>
    <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-gray-700">
      <p>{{ step1?.registration }}</p>
      <p>{{ step2?.inspection_type }}</p>
      <p>{{ step2?.vehicle_type }}</p>
      <p>{{ step2?.transmission_type }}</p>
    </div>
    <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-gray-700">
      <p>{{ step3?.community }}</p>
      <p>{{ step3?.centre }}</p>
      <a class="underline text-blue-500 mt-2 cursor-pointer"> Google maps</a>
    </div>
    <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-gray-700">
      <p>{{ step4?.date }}</p>
      <p>{{ step4?.hour }}h</p>
    </div>
    <div class="border-b border-gray-300 text-blue-500 text-start py-2">Cliente</div>
    <div class="border-b border-gray-300 text-blue-500 text-start py-2 col-span-2">Pago</div>
    <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-gray-700">
      <p>{{ step5?.phone_number }}</p>
      <p>{{ step5?.email }}</p>
    </div>
    <div class="col-span-2 grid grid-cols-4 text-sm pt-2 text-gray-700">
      <p class="text-start">46,50€</p>
      <p class="text-start col-span-3">{{ step6?.payment == 'station' ? 'Pago en de Centro ITV (Efectivo o tarjeta)' : (step6?.payment == 'credit_card' ? 'Pago con tarjeta' : 'Pago por Bizum') }}</p>
    </div>
  </div>
  <div class="flex flex-col text-center justify-center mt-8">
    <button class="mx-auto w-fit font-bold py-3 px-16 rounded-full bg-[#FDDC03] text-blue-500 hover:bg-yellow-400 duration-300" (click)="update()">PAGAR</button>
    <p class="mx-auto text-[10px] text-gray-600 max-w-[160px] mt-2 italic">Paga de manera segura a través del Consell y AHORRA TIEMPO</p>
  </div>
</div>-->


