import {Component, Input} from '@angular/core';
import {CardShadowComponent} from "../../../../../front/components/utils/card-shadow/card-shadow.component";
import {DatePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {CustomDayPipe} from "../../../../../../shared/pipes/custom-day.pipe";
import {CustomMonthPipe} from "../../../../../../shared/pipes/custom-month.pipe";
import {StationService} from "../../../../../../core/services/stations/station.service";
import {Station} from "../../../../../../models/Station";
import {LoaderComponent} from "../../../../../../shared/components/loader/loader.component";

@Component({
  selector: 'app-other-stations',
  standalone: true,
  imports: [
    CardShadowComponent,
    DatePipe,
    NgIf,
    UpperCasePipe,
    CustomDayPipe,
    CustomMonthPipe,
    NgForOf,
    LoaderComponent
  ],
  templateUrl: './other-stations.component.html',
  styleUrl: './other-stations.component.css'
})
export class OtherStationsComponent {

  @Input() loading: boolean = false;
  @Input() stations: Station[] = []

  constructor(private stationService: StationService) {

  }


}
