<app-generic-table
  [loading]="loading"
  [filters]="filters"
  [tableHeaders]="tableHeaders"
  (changeSorts)="onChangeSorts($event)"
  [items]="reservations"
  [tableActions]="tableActions"
  (clickAction)="onClickAction($event)"
  (onBooleanClick)="onBooleanClick($event)">

  <app-table-header-wrapper GTHeader >
    <!--<app-user-table-filters [perPageOptions]="[50,100,150,200]" (onChangePerPage)="perPage = $event; loadReservations()" [perPage]="perPage" [tableHeaders]="tableHeaders" [filters]="filters" (onChangeFilters)="onChangeFilters($event)"></app-user-table-filters>-->
    <app-table-filters
      [filtersList]="tableFilters"
      [loading]="loadingFilters"
      [displayNames]="displayNames"
      [perPageOptions]="[50,100,150,200]"
      (onChangePerPage)="perPage = $event; loadReservations()"
      [perPage]="perPage"
      [tableHeaders]="tableHeaders"
      [filters]="filters"
      (bulkActionClick)="bulkActionClick($event)"
      (onChangeFilters)="onChangeFilters($event)"
      [reservationsCsv]="reservationsCsv"
      [bulkActions]="bulkActions"
      [isCalendar]="true"
    (onChangeColumn)="changeColumns($event)"></app-table-filters>

  </app-table-header-wrapper>
  <!-- <p-confirmDialog /> -->


</app-generic-table>
<p-dialog [style]="{width:'50rem'}" [(visible)]="displayEditDialog" [modal]="true" [closable]="false" header="Editar Reserva">
  <app-existing-reservation-card [editView]="true" (onUpdateReservation)="selectedReservation = $event" *ngIf="selectedReservation" [existingReservation]="selectedReservation"
                                 (onEditReservation)="editReservation=$event" (onCloseEditView)="closeEditView($event)">
  </app-existing-reservation-card>
</p-dialog>
