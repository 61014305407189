<div class="w-full rounded-3xl shadow-sm border-2 border-lightGray2 relative p-4">

  <!--<div class="w-full flex gap-2 mb-4">
    <button class="bg-lightBlue text-primary p-1 px-2 rounded-full text-sm uppercase">Año anterior</button>
    <button class="bg-lightBlue text-primary p-1 px-2 rounded-full text-sm uppercase">Año actual</button>
    <button class="bg-lightBlue text-primary p-1 px-2 rounded-full text-sm uppercase">Mes actual</button>
  </div>-->

  <app-table-filters
    [filtersList]="tableFilters"
    [loading]="loadingFilters"
    [displayNames]="displayNames"
    [maxSelectedDropdowns]="1"
    [perPageOptions]="[50,100,150,200]"
    [filters]="filters"S
    [showColumnsAndPerPage]="false"
    [allInDropdown]="false"
    (onChangeFilters)="onChangeFilters($event)"
  ></app-table-filters>


</div>

<div class="w-full">

  <div class="grid grid-cols-4 mt-4 gap-2" *ngIf="cardsInfo">


    <app-stadistic-card title="Total" [dates]="title" [info]="cardsInfo.reservationsCount" gradientColors="from-[#36a0fc] to-primary" dateBgColor="bg-[#037ae2]" iconBgColor="bg-primary">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-books"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" /><path d="M9 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" /><path d="M5 8h4" /><path d="M9 16h4" /><path d="M13.803 4.56l2.184 -.53c.562 -.135 1.133 .19 1.282 .732l3.695 13.418a1.02 1.02 0 0 1 -.634 1.219l-.133 .041l-2.184 .53c-.562 .135 -1.133 -.19 -1.282 -.732l-3.695 -13.418a1.02 1.02 0 0 1 .634 -1.219l.133 -.041z" /><path d="M14 9l4 -1" /><path d="M16 16l3.923 -.98" /></svg>
    </app-stadistic-card>

    <app-stadistic-card title="Realizadas"  [dates]="title" [info]="cardsInfo.reservationsCountPasts" [sub_info]="'de '+(cardsInfo.reservationsCountPasts + cardsInfo.reservationsCountPastsNoState)+' reservas'" gradientColors="from-[#02e36b] to-green" dateBgColor="bg-[#029748]" iconBgColor="bg-green">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-history"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 8l0 4l2 2" /><path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" /></svg>
    </app-stadistic-card>

    <app-stadistic-card title="Futuras" [dates]="title" [info]="cardsInfo.reservationsCountFutures" gradientColors="from-[#ffe733] to-yellow" dateBgColor="bg-[#ccb400]" iconBgColor="bg-yellow">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-crystal-ball"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6.73 17.018a8 8 0 1 1 10.54 0" /><path d="M5 19a2 2 0 0 0 2 2h10a2 2 0 1 0 0 -4h-10a2 2 0 0 0 -2 2z" /><path d="M11 7a3 3 0 0 0 -3 3" /></svg>
    </app-stadistic-card>

    <app-stadistic-card title="Absentismo" [dates]="title" [info]="cardsInfo.reservationsCountPastsNoState" gradientColors="from-[#ff6b66] to-red2" dateBgColor="bg-[#b30600]" iconBgColor="bg-red2">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-mood-empty"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M9 10l.01 0" /><path d="M15 10l.01 0" /><path d="M9 15l6 0" /></svg>
    </app-stadistic-card>

  </div>

  <div class="grid grid-cols-2 mt-6">

    <div class="text-center" *ngIf="options">
      <div echarts [options]="options" [theme]="theme" class="demo-chart"></div>
    </div>

    <!--<div class="text-center">
      % DE ABSENTISMO
    </div>-->

  </div>

</div>
