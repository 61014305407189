import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Step1, Step2, Step3, Step4, Step5, Step6,} from "../../../../../models/Front/ReservationForm";
import {BookFormService} from "../../../../../core/services/book-form.service";
import {FrontService} from "../../../../../core/services/front/front.service";
import {Router} from "@angular/router";
import {CurrencyPipe, DatePipe, JsonPipe, NgIf} from "@angular/common";
import {LoaderComponent} from "../../../../../shared/components/loader/loader.component";
import {ConfirmCardComponent} from "../../confirm-card/confirm-card.component";
import {StepperComponent} from "../../stepper/stepper.component";
import {AuthStateService} from "../../../../../core/services/auth/auth-state.service";
import {Reservation} from "../../../../../models/Reservation";
import {FormsModule} from "@angular/forms";
import {DialogModule} from "primeng/dialog";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'app-step7',
  standalone: true,
  imports: [
    CurrencyPipe,
    DatePipe,
    LoaderComponent,
    NgIf,
    JsonPipe,
    ConfirmCardComponent,
    FrontBackDirective,
    StepperComponent,
    FormsModule,
    DialogModule
  ],
  templateUrl: './step7.component.html',
  styleUrl: './step7.component.css'
})
export class Step7Component implements OnInit {

  step1?: Step1;
  step2?: Step2;
  step3?: Step3;
  step4?: Step4;
  step5?: Step5;
  step6?: Step6;

  accepted: boolean = false;
  error: string = "";

  errorConfirm: boolean = false;

  professional: boolean = false;
  professional_id?: number;
  @Input() admin: boolean = false;

  visible=false;
  price = null;

  loading = false;

  confirmed = false;

  isProfessional = false;

  isOperator = false;

  existingReservation?:Reservation;

  constructor(private bookFormService: BookFormService, private frontService: FrontService, private router:Router,
              private authStateService: AuthStateService, private cdr: ChangeDetectorRef) {
    this.bookFormService.reservationForm$.subscribe({
      next: data => {
        this.step1 = data.steps.step1;
        this.step2 = data.steps.step2;
        this.step3 = data.steps.step3;
        this.step4 = data.steps.step4;
        this.step5 = data.steps.step5;
        this.step6 = data.steps.step6;
        this.confirmed = data.confirmed;
        this.professional = data.professional;
        this.professional_id = data.professional_id;
      }
    })
    this.authStateService.currentUser.subscribe({
      next: value => {
        if (value && value.roles.includes('professional')) {
          this.isProfessional = true;
        }

        if (value && value.permissions.includes('create_reservation_free_plate')) {
          this.isOperator = true;
        }
      }
    })
  }

  ngOnInit(){
    this.bookFormService.setShowSummary(false);
    /*if (this.step1?.registration_number && this.step2?.fuel && this.step2.vehicle && this.step3?.station) {
      this.frontService.getPrice(this.step1.registration_number, this.step3.station.id, this.step2.vehicle.id, this.step2.fuel.id).subscribe({
        next: (price) => {
          if (price.data) {
            if (price.data.TarifaTotal) {
              this.price = price.data.TarifaTotal;
            } else {
              this.price = null;
            }
          }
        },
        error: (err) => {
          console.log(err);
        }
      })
    }*/

  }

  public confirm(){
    this.errorConfirm = false;
    if(this.step1 && this.step2 && this.step3 && this.step4 && this.step5 && this.step6){
      let data:any = {
        hour_id:this.step4.hour?.id,
        station_id:this.step3.station?.id,
        service_id:this.step2.service?.id,
        vehicle_id:this.step2.vehicle?.id,
        payment_method_id:this.step6.payment?.id,
        fuel_id:this.step2.fuel?.id,
        registration_number:this.step1.registration_number,
        date:this.step4.date,
        phone:this.step5.phone_number,
        email:this.step5.email,
        name:this.step5.name,
        professional_id: this.professional_id
      };

      console.log(data);

      if(this.isOperator){

        this.loading = true;
        this.frontService.createReservation(data).subscribe({
          next: (response) => {
            // console.log(response.data)
            this.existingReservation=response.data;
            this.bookFormService.setConfirmed(true);
            this.bookFormService.setCurrentStep(8);
            this.loading = false;

            /*if(this.existingReservation){
              this.router.navigate(['/confirm', this.existingReservation.id]);
            }*/
          },
          error: (error) => {
            //console.log(error.error)
            this.errorConfirm = true;
            this.loading = false;
          }
        })
        

      } else {

        if (this.accepted) {
          this.loading = true;
          this.frontService.createReservation(data).subscribe({
            next: (response) => {
              
              // console.log(response.data)
              this.existingReservation=response.data;
              this.bookFormService.setConfirmed(true);
              this.bookFormService.setCurrentStep(8);
              this.loading = false;
  
              /*if(this.existingReservation){
                this.router.navigate(['/confirm', this.existingReservation.id]);
              }*/
            },
            error: (error) => {
              this.errorConfirm = true;
              this.loading = false;
            }
          })
        } else {
          this.error = "Debes de aceptar la política legal y de protección de datos";
        }
      }

    }
  }



  public reset(){
    this.bookFormService.reset({
      registration_number: this.existingReservation?.registration_number
    });
  }

  public professionalOtherReservation(){
    if (this.step5) {
      this.bookFormService.resetProfessional({
        registration_number: this.existingReservation?.registration_number
      }, this.step5);
    }
  }

  showDialog(){
    this.visible = true;
  }

  public adminEmitter(){
    this.bookFormService.reset(undefined, this.professional);
  }

  public backStep(){
     this.bookFormService.setCurrentStep(5);
  }

}
