import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../../models/Front/ReservationForm";
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {
  selectShowSummary,
  selectStep1,
  selectStep2,
  selectStep3,
  selectStep4,
  selectStep5,
  selectStep6
} from "../../../../../store/reservationForm/stepper.selector";
import {
  updateShowSummary,
  updateStep,
  updateStep5,
  updateStep6
} from "../../../../../store/reservationForm/stepper.actions";
import {NgForOf, NgIf} from "@angular/common";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {PaymentMethod} from "../../../../../models/PaymentMethod";

@Component({
  selector: 'app-step6',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NextButtonDirective,
    FrontBackDirective,
    NgForOf,
    FormsModule,
  ],
  templateUrl: './step6.component.html',
  styleUrl: './step6.component.css'
})
export class Step6Component implements OnInit {

  substep?: number = 1;

  @Output() stepCompleted = new EventEmitter<void>();
  showSummary$: Observable<boolean>;
  showSummary: boolean = false;
  selectedPaymentMethod?: PaymentMethod;
  step1$: Observable<Step1 | undefined>;
  step1?: Step1;
  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  step3$: Observable<Step3 | undefined>;
  step3?: Step3;
  step4$: Observable<Step4 | undefined>;
  step4?: Step4;
  step5$: Observable<Step5 | undefined>;
  step5?: Step5;
  step6$: Observable<Step6 | undefined>;
  step6?: Step6;

  constructor(private store: Store) {
    this.showSummary$ = this.store.pipe(select(selectShowSummary));
    this.step1$ = this.store.pipe(select(selectStep1));
    this.step2$ = this.store.pipe(select(selectStep2));
    this.step3$ = this.store.pipe(select(selectStep3));
    this.step4$ = this.store.pipe(select(selectStep4));
    this.step5$ = this.store.pipe(select(selectStep5));
    this.step6$ = this.store.pipe(select(selectStep6));
  }

  ngOnInit() {
    this.showSummary$.subscribe(showSummary => {
      this.showSummary = showSummary;
    });
    this.step1$.subscribe(step1 => {
      this.step1 = step1;
    });
    this.step2$.subscribe(step2 => {
      this.step2 = step2;
    });
    this.step3$.subscribe(step3 => {
      this.step3 = step3;
      if(this.step3){
        if(this.step3.station){
          if(this.step3.station.payment_methods.length<2){
            this.store.dispatch(updateStep({ step: 7 }));
          }
        }
      }
    });
    this.step4$.subscribe(step4 => {
      this.step4 = step4;
    });
    this.step5$.subscribe(step5 => {
      this.step5 = step5;
    })
    this.step6$.subscribe(step6 => {
      this.step6 = step6;
      this.selectedPaymentMethod = this.step6?.payment;
    })
    this.step3?.station?.payment_methods.forEach((paymentMethod) => {
      if (paymentMethod.id == this.selectedPaymentMethod?.id) {
        const step6: Step6 = {
          payment: paymentMethod,
        };
        this.store.dispatch(updateStep6({step6}));
      }
    })
  }

  public nextStep() {
    this.store.dispatch(updateStep({step: 7}));
  }

  public update() {
    if (this.selectedPaymentMethod) {
      this.step3?.station?.payment_methods.forEach((paymentMethod) => {
        if (paymentMethod.id == this.selectedPaymentMethod?.id) {
          const step6: Step6 = {
            payment: paymentMethod,
          };
          this.store.dispatch(updateStep6({step6}));
        }
      })
      this.showSummary = false;
      let showSummary = this.showSummary;
      this.store.dispatch(updateShowSummary({showSummary}));
      this.nextStep()
      //this.stepCompleted.emit();
    }
  }

  goBack() {
    this.store.dispatch(updateStep({step: 5}));
  }


}
