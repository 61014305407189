<div class="w-full animate-fade-in-down">
  <div  *ngIf="step3 && step3.station?.limitations && !showMessage"
        class="w-full bg-[#fee4a8] text-black text-start p-4 text-xs mb-4">
    {{step3.station?.limitations}}</div>
  <div  *ngIf="!showMessage" class="w-full gap-4" [ngClass]="'grid grid-cols-1 lg:grid-cols-2'">
    <div>
      <app-month-calendar [loading]="loadingDates" (nextMonth)="nextMonth()" (prevMonth)="prevMonth()" [changeMonth]="true" [frontMode]="true"  [blockedDates]="blockedDates" [month]="month" [year]="year" [selectDaysMode]="true" selectedColor="#168FFB" [currentSelection]="[selectedDate ?? '']" (onSelectDayChange)="onSelectedDay($event)"></app-month-calendar>
    </div>
    <app-card-shadow [showLoading]="loadingHours">
      <!--<div *ngIf="isProfessional" class="uppercase mb-4 text-darkGray text-2xl">
        {{selectedDate | date:'fullDate' }}
      </div>-->
      <app-loader size="large" *ngIf="loadingHours"></app-loader>
      <div id="availableHours" #availableHours class="grid gap-2" [ngClass]="'grid-cols-4 sm:grid-cols-5 md:grid-cols-7'"  *ngIf="!loadingHours">
        <span class="text-darkGray py-2" (click)="hour.available > 0  ? onSelectHour(hour) : null" [ngClass]="hour.available > 0 ? 'bg-lightGreen cursor-pointer' : 'bg-lightGray2'"
              [class.bg-primary]="hour.id == selectedHour?.id"
              [class.text-white]="hour.id == selectedHour?.id"
              *ngFor="let hour of hours">
          {{hour.hour}} <p *ngIf="hour.available>0" class="text-[10px] float-left ml-2">{{hour.available}}</p>
        </span>
      </div>
    </app-card-shadow>
  </div>

  <div class="w-full bg-lightGray2 text-darkGray text-center p-4 text-xs mb-4" *ngIf="showMessage">
    <p>{{showMessage}}</p>
  </div>

  <div class="flex items-center justify-between mt-2" #continue>
    <button (click)="goBack()" appFrontBack>
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
      <span>Atrás</span>

    </button>
    <div class="max-w-96">
      <button appNextButton (click)="update()" [disabled]="!(selectedHour && selectedDate)">
        Continuar
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>
      </button>
    </div>

  </div>
</div>


