import { Component } from '@angular/core';
import {StadisticCardComponent} from "../../../../shared/components/stadistic-card/stadistic-card.component";
import {NgxEchartsDirective, provideEcharts, ThemeOption} from "ngx-echarts";
import {EChartsOption} from "echarts";
import {StatisticsService} from "../../../../core/services/stations/statistics.service";
import {NgxSpinnerService} from "ngx-spinner";
import {DecimalPipe, NgIf} from "@angular/common";
import {elementAt, forkJoin} from "rxjs";
import {SelectItemGroup} from "primeng/api";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {Filter} from "../../../../models/Table/Filter";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {ServicesService} from "../../../../core/services/services/services.service";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {UserService} from "../../../../core/services/users/user.service";
import {State} from "../../../../models/State";

@Component({
  selector: 'app-statistics',
  standalone: true,
  imports: [
    StadisticCardComponent,
    NgxEchartsDirective,
    NgIf,
    DecimalPipe,
    MultiSelectModule,
    FormsModule,
    TableFiltersComponent
  ],
  providers: [
   provideEcharts(),
  ],
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.css'
})
export class StatisticsComponent {

  cardsInfo: any;
  title: string = "";

  loadingFilters = false;

  tableFilters: Filter[] = [
    {
      key: 'date',
      type: 'date-between',
      parsed: 'date-arr',
      operator: 'between',
      placeholder: 'Fecha'
    }
  ]

  displayNames: { [key: string]: string } = {
    date: 'Fecha',
    registration_number: 'Término',
    station_id: 'Estación',
    service_id: 'Servicio',
    vehicle_id: 'Vehiculo',
    user_id: 'Operadora',
    professional_id: 'Profesional',
    state: 'Estado',
    'registration_number|phone|ip': "Término"
  };
  filters: ActiveFilter[] = [];

  constructor(private statisticService: StatisticsService, private spinner: NgxSpinnerService,
              private vehiclesService: VehiclesService, private stationService: StationService,
              private serviceService: ServicesService, private userService: UserService,) {

    this.loadOptions()

   let {startOfYear, endOfYear} = this.getStartAndEndOfYear();

    if(!this.filters.some(f=>f.parsed ==='date-arr')){
      this.filters.push(
        {
          column:"date",
          format:"dd/MM/yyyy",
          operator: "between",
          parsed: "date-arr",
          value:[startOfYear,endOfYear]
        }
      )
    }

    this.loadData();

  }

  loadData(){
    this.spinner.show();

    this.statisticService.statistics([], this.filters).subscribe({
      next: data => {
        this.title = data.data.title;
        // @ts-ignore
        this.cardsInfo = data.data.statistics;

        let items: string[] = [];
        let data2: any[] = [];

        data.data.statistics.reservationsByDevice.forEach((element: any) => {
          if (element.device) {
            items.push(element.device)
            data2.push( { value: element.count, name: element.device },)
          } else {
            items.push('desconocido')
            data2.push( { value: element.count, name: 'desconocido' },)
          }
        })


        this.options = {
          title: {
            left: '50%',
            text: 'Reservas por dispositivo',
            top: 3,
            subtext: '',
            textAlign: 'center',
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          legend: {
            align: 'auto',
            bottom: 5,
            data: items,
          },
          calculable: true,
          series: [
            {
              name: 'Dispositivo',
              type: 'pie',
              radius: [30, 110],
              roseType: 'radius',
              data: data2,
            },
          ],
        }


        this.spinner.hide();
      },
      error: err => {
        this.spinner.hide();
      }
    })
  }


  CoolTheme = {
    color: [
      '#b21ab4',
      '#6f0099',
      '#2a2073',
      '#0b5ea8',
      '#17aecc',
      '#b3b3ff',
      '#eb99ff',
      '#fae6ff',
      '#e6f2ff',
      '#eeeeee',
    ],

    title: {
      fontWeight: 'normal',
      color: '#00aecd',
    },

    visualMap: {
      color: ['#00aecd', '#a2d4e6'],
    },

    toolbox: {
      color: ['#00aecd', '#00aecd', '#00aecd', '#00aecd'],
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#00aecd',
          type: 'dashed',
        },
        crossStyle: {
          color: '#00aecd',
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)',
        },
      },
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(144,197,237,0.2)', // Fill the color
      handleColor: '#00aecd', // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#00aecd',
      },
      controlStyle: {
        color: '#00aecd',
        borderColor: '00aecd',
      },
    },

    candlestick: {
      itemStyle: {
        color: '#00aecd',
        color0: '#a2d4e6',
      },
      lineStyle: {
        width: 1,
        color: '#00aecd',
        color0: '#a2d4e6',
      },
      areaStyle: {
        color: '#b21ab4',
        color0: '#0b5ea8',
      },
    },

    chord: {
      padding: 4,
      itemStyle: {
        color: '#b21ab4',
        borderWidth: 1,
        borderColor: 'rgba(128, 128, 128, 0.5)',
      },
      lineStyle: {
        color: 'rgba(128, 128, 128, 0.5)',
      },
      areaStyle: {
        color: '#0b5ea8',
      },
    },

    graph: {
      itemStyle: {
        color: '#b21ab4',
      },
      linkStyle: {
        color: '#2a2073',
      },
    },

    map: {
      itemStyle: {
        color: '#c12e34',
      },
      areaStyle: {
        color: '#ddd',
      },
      label: {
        color: '#c12e34',
      },
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#dddddd'],
            [0.8, '#00aecd'],
            [1, '#f5ccff'],
          ],
          width: 8,
        },
      },
    },
  };

  theme?: any;
  coolTheme = this.CoolTheme;
  options?: EChartsOption = undefined;


  loadOptions(){
    this.loadingFilters = true;
    const requests: any = {
      // stations: this.stationService.list(),
      services: this.serviceService.list(),
      vehicles: this.vehiclesService.list(),
      stations2: this.stationService.listByState()
    };
    requests.users = this.userService.listOperadoras();
    requests.professionals = this.userService.listProfessionals();

    forkJoin(requests).subscribe({
      next: (results: any) => {
        this.tableFilters.push({
          key: 'service_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.services.data ?? [],
          placeholder: 'Servicio',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        });
        this.tableFilters.push({
          key: 'vehicle_id',
          type: 'multiselect',
          operator: 'in',
          multiSelectItems: results.vehicles.data ?? [],
          placeholder: 'Vehículo',
          parsed:"relationship-arr",
          relation_column: 'name',
          relation_filter_column: 'id'
        })

        /*if (results.users) {
          this.tableFilters.push({
            key: 'user_id',
            type: 'multiselect',
            operator: 'in',
            multiSelectItems: results.users.data ?? [],
            placeholder: 'Operadoras',
            parsed:"relationship-arr",
            relation_column: 'name',
            relation_filter_column: 'id'
          })
        }*/

        /*if (results.professionals) {
          this.tableFilters.push({
            key: 'professional_id',
            type: 'multiselect',
            operator: 'in',
            multiSelectItems: results.professionals.data ?? [],
            placeholder: 'Profesionales',
            parsed:"relationship-arr",
            relation_column: 'name',
            relation_filter_column: 'id'
          })
        }*/



        if (results.stations2.data) {
          let groupedStates: any[] = [];
          results.stations2.data.forEach((item: State) => {
            let data = {
              label: item.name,
              value: item.id,
              img: item.logo?.original_url ?? null,
              items: []
            }
            item.stations?.forEach(station => {
              // @ts-ignore
              data.items.push(station)
            })
            groupedStates.push(data);
          })
          this.tableFilters.push({
            key: 'station_id',
            type: 'multiselect',
            group: true,
            operator: 'in',
            multiSelectItems: groupedStates ?? [],
            placeholder: 'Estación',
            parsed:"relationship-arr",
            relation_column: 'name',
            relation_filter_column: 'id'
          });
        }

        // console.log(results.stations2);


        this.loadingFilters = false;
      },
      error: (error) => {
        this.loadingFilters = false;
        // Manejar errores aquí
        console.error('Error al llamar a los observables:', error);
      }
    });
  }

  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;

    this.loadData();
    // localStorage.setItem('itv_reservation_filters',JSON.stringify(this.filters))
  }

  getStartAndEndOfYear() {
    const currentYear = new Date().getFullYear();

    const startOfYear = new Date(currentYear, 0, 1); // 1 de enero del año actual
    const endOfYear = new Date(currentYear, 11, 31, 23, 59, 59, 999); // 31 de diciembre del año actual

    return {
      startOfYear,
      endOfYear
    };
  }

}
