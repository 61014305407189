import {HttpEvent, HttpEventType, HttpParams} from "@angular/common/http";
import {SortColumn} from "../models/Table/SortColumn";
import {ActiveFilter} from "../models/Table/ActiveFilter";

export function formatDate(date: Date, separator:string = '/', lang: string = 'es'): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses en Date son de 0 a 11, por eso sumamos 1
  const day = date.getDate().toString().padStart(2, '0');

  if (lang == 'es') {
    return `${day}${separator}${month}${separator}${year}`;
  }

  if (lang == 'en') {
    return `${year}${separator}${month}${separator}${day}`;
  }

  return 's';




  // Asegurarse de que el parámetro es un objeto Date


  // Obtener el año, el mes y el día


  // Formatear la cadena en el formato YYYY-MM-DD

}

export function formatDateEn(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript son 0-11, por lo que añadimos 1
  const year = date.getFullYear();

  return `${year}/${month}/${day}`;
}

export function completeHttpFiltersAndSorts(sorts: SortColumn[] = [], filters: ActiveFilter[]){
  let params = new HttpParams();
  // Filtrar duplicados ignorando la propiedad "order"
  const uniqueSorts = sorts.filter(
    (sort, index, self) =>
      index ===
      self.findIndex(
        (s) =>
          s.column === sort.column &&
          (!s.relationship ||
            (sort.relationship &&
              s.relationship.table === sort.relationship.table &&
              s.relationship.column === sort.relationship.column))
      )
  );
// Procesar los únicos
  uniqueSorts.forEach((sort, index) => {
    if (sort.order) {
      params = params.append(`sorts[${index}][column]`, sort.column);
      if (sort.relationship) {
        params = params.append(`sorts[${index}][relationship][table]`, sort.relationship.table);
        params = params.append(`sorts[${index}][relationship][column]`, sort.relationship.column);
      }
      params = params.append(`sorts[${index}][order]`, sort.order);
    }
  });

  filters.forEach((filter, index) => {
    params = params.append(`filters[${index}][column]`, filter.column);
    params = params.append(`filters[${index}][operator]`, filter.operator);

    if (Array.isArray(filter.value)) {
      if (filter.parsed == 'relationship-arr' || filter.parsed == 'array') {
        filter.value.forEach((val, valIndex) => {
          params = params.append(`filters[${index}][value][${valIndex}]`, val[filter.relation_filter_column ?? 'id']);
        });
      } else if (filter.parsed == 'date-arr') {
        filter.value.forEach((val, valIndex) => {
          params = params.append(`filters[${index}][value][${valIndex}]`,  formatDateEn(val));
        });
      }

    } else {
      params = params.append(`filters[${index}][value]`, parseFilters(filter));
    }
  });
  return params;
}

function parseFilters(filter: ActiveFilter) {
  if (filter.parsed == "date") {
    return formatDateEn(filter.value);
  } else if (filter.parsed == "date-arr") {
    let arr = filter.value;
    // console.log(arr);
  }
  return filter.value;
}


export function getEventProgress(event: HttpEvent<any>): any {
  switch (event.type) {
    case HttpEventType.DownloadProgress:
      return Math.round(100 * (event.loaded / (event.total ?? 1)));

    case HttpEventType.Response:
      return event.body as any;

    default:
      return 0;
  }
}

/**
 * Rellena la cadena original con un carácter dado hasta alcanzar la longitud especificada.
 *
 * @param {string} str - La cadena original.
 * @param {number} targetLength - La longitud deseada de la cadena resultante.
 * @param {string} padChar - El carácter con el que se rellenará la cadena original.
 * @return {string} La cadena resultante después de añadir los caracteres al inicio.
 */
export function pad2Start(str: string, targetLength: number, padChar: string) {
  // Convertir el valor a una cadena en caso de que no lo sea
  str = String(str);

  // Verificar si el carácter de relleno es una cadena de longitud 1
  if (typeof padChar !== 'string' || padChar.length !== 1) {
    throw new Error('El carácter de relleno debe ser una cadena de longitud 1');
  }

  // Calcular el número de caracteres que se necesitan para rellenar
  const paddingNeeded = targetLength - str.length;

  // Si no se necesita rellenar, devolver la cadena original
  if (paddingNeeded <= 0) {
    return str;
  }

  // Crear la cadena de relleno
  const padding = padChar.repeat(paddingNeeded);

  // Devolver la cadena rellenada
  return padding + str;
}
