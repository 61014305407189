import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {OldReservation, Reservation} from "../../../models/Reservation";
import {Response} from "../../../models/Response";
import {Station} from "../../../models/Station";
import {environment} from "../../../../environments/environment";
import {Service} from "../../../models/Service";
import {Vehicle} from "../../../models/Vehicle";
import {Fuel} from "../../../models/Fuel";
import {State} from "../../../models/State";
import {AvailableDates} from "../../../models/AvailableDates";
import {AvailableHoursInfo} from "../../../models/AvailableHours";
import {AuthStateService} from "../auth/auth-state.service";

@Injectable({
  providedIn: 'root'
})
export class FrontService {

  constructor(private http: HttpClient, private authStateService: AuthStateService) {}

  // STEP 1
  checkReservation(data: any, professional_id:number|undefined = undefined): Observable<Response<null|Reservation|OldReservation>> {
    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;
      // @ts-ignore
      data.professional_id = id;
    }
    return this.http.post<Response<null|Reservation|OldReservation>>(`${environment.API_URL}/reservations/search`,data);
  }
  // STEP 2
  listServices(professional_id:number|undefined = undefined): Observable<Response<Service[]>> {
    let params = new HttpParams();

    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;
      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<Service[]>>(`${environment.API_URL}/reservations/services`,  { params: params });
  }

  listVehicles(service_id: number, professional_id:number|undefined = undefined) {
    let params = new HttpParams();

    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;

      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<Vehicle[]>>(`${environment.API_URL}/reservations/vehicles/${service_id}`, { params: params });
  }

  listFuels(service_id: number, vehicle_id: number, professional_id:number|undefined = undefined) {
    let params = new HttpParams();

    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;

      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<Fuel[]>>(`${environment.API_URL}/reservations/fuels/${service_id}/${vehicle_id}`,{ params: params });
  }

  // STEP 3
  listStates(service_id: number, vehicle_id: number, fuel_id: number, professional_id:number|undefined = undefined) {
    let params = new HttpParams();

    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;

      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<State[]>>(`${environment.API_URL}/reservations/states/${service_id}/${vehicle_id}/${fuel_id}`,{ params: params });
  }

  listStations(service_id: number, vehicle_id: number, fuel_id: number, state_id: number, professional_id:number|undefined = undefined) {
    let params = new HttpParams();

    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;

      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<Station[]>>(`${environment.API_URL}/reservations/stations/${service_id}/${vehicle_id}/${fuel_id}/${state_id}`, { params: params });
  }

  listStationsOpe(service_id: number, vehicle_id: number, fuel_id: number, state_id: number, professional_id:number|undefined = undefined) {
    let params = new HttpParams();
    params = params.set('operadora', false);
    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;

      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<Station[]>>(`${environment.API_URL}/reservations/stations/${service_id}/${vehicle_id}/${fuel_id}/${state_id}`, { params: params });
  }

  // STEP 4
  listAvailableDates(month: number, year: number, service_id: number, vehicle_id: number, fuel_id: number, station_id: number, professional_id:number|undefined = undefined) {
    let params = new HttpParams();
    // Si professional_id está definido, lo agregamos a los parámetros
    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;

      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<AvailableDates[]>>(`${environment.API_URL}/reservations/available-dates/${month}/${year}/${service_id}/${vehicle_id}/${fuel_id}/${station_id}`, { params: params } );
  }
  // TODO: ¿Por dónde se le pasa la fecha del día que quieres ver las horas disponibles?
  listAvailableHours(date: string, service_id: number, vehicle_id: number, fuel_id: number, station_id: number, professional_id:number|undefined = undefined) {
    let params = new HttpParams();

    // Si professional_id está definido, lo agregamos a los parámetros
    // Si professional_id está definido, lo agregamos a los parámetros
    if (professional_id !== undefined || !this.authStateService.isNotProfessional()) {
      let id = professional_id || this.authStateService.currentUserValue?.profile.id;

      // @ts-ignore
      params = params.set('professional_id', id);
    }
    return this.http.get<Response<AvailableHoursInfo>>(`${environment.API_URL}/reservations/available-hours/${date}/${service_id}/${vehicle_id}/${fuel_id}/${station_id}` , { params: params } );
  }

  // STEP 5

  // STEP 6

  // STEP 7
  createReservation(data: any): Observable<Response<Reservation>> {
    return this.http.post<Response<Reservation>>(`${environment.API_URL}/reservations/confirm`, data);
  }
  getReservation(id:string):Observable<Response<Reservation>>{
    return this.http.get<Response<Reservation>>(`${environment.API_URL}/reservations/find/${id}`);
  }

  getPrice(registration_number: string, stationId: number, vehicleId: number, fuelId: number):Observable<Response<any>>{
    return this.http.get<Response<any>>(`${environment.API_URL}/reservations/price/${registration_number}/${stationId}/${vehicleId}/${fuelId}`);
  }
}
