import {Component, OnInit} from '@angular/core';
import {ReservationForm, Step3, Step5, Step6} from "../../../../../models/Front/ReservationForm";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AuthStateService} from "../../../../../core/services/auth/auth-state.service";
import {selectStep3, selectStep5} from "../../../../../store/reservationForm/stepper.selector";
import {BookFormService} from "../../../../../core/services/book-form.service";
import {
  updateShowSummary,
  updateStep,
  updateStep5,
  updateStep6
} from "../../../../../store/reservationForm/stepper.actions";
import {FormErrorDirective} from "../../../../../shared/directives/forms/form-error.directive";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";

@Component({
  selector: 'app-step5',
  standalone: true,
  imports: [
    FormErrorDirective,
    FrontBackDirective,
    NextButtonDirective,
    NgIf,
    PaginatorModule,
    ReactiveFormsModule
  ],
  templateUrl: './step5.component.html',
  styleUrl: './step5.component.css'
})
export class Step5Component implements OnInit {
  step3?: Step3;
  step5Form: FormGroup;

  constructor(private authStateService: AuthStateService, private bookFormService: BookFormService,) {
    this.step5Form = new FormGroup({
      phone_number: new FormControl('', [Validators.required, Validators.maxLength(9), Validators.minLength(9)]),
      email: new FormControl('',[Validators.email]),
      name: new FormControl(''),
    });

    this.bookFormService.reservationForm$.subscribe({
      next: (form: ReservationForm) => {
        this.step3 = form.steps.step3;
        if (form.steps.step5) {
          this.step5Form.patchValue(form.steps.step5);
        }
      }
    })
  }

  ngOnInit() {
    this.authStateService.currentUser.subscribe({
      next: value => {
        if (value && value.roles.includes('professional')) {
          this.step5Form.patchValue({
            phone_number: value.profile.phone ?? null,
            email: value.profile.email,
          })
        }
      }
    })
  }

  public validate(): boolean {
    return this.step5Form.valid;
  }

  /*public nextStep() {
    if(this.step3){
      if(this.step3.station){
        if(this.step3.station.payment_methods.length>1){
          this.store.dispatch(updateStep({ step: 6 }));

        } else if(this.step3.station.payment_methods.length===1){
          let paymentMethod = this.step3.station.payment_methods[0];
          let step6: Step6 = {
            payment: paymentMethod,
          };
          this.store.dispatch(updateStep6({step6}));
          let showSummary = false;
          this.store.dispatch(updateShowSummary({showSummary}));
          this.store.dispatch(updateStep({ step: 7 }));
        }
      }
    }

  }*/

  public update() {
    if (this.validate()) {
      const step5: Step5 = this.step5Form.value;
      this.bookFormService.setStep5(step5);
      // this.nextStep()
      //this.stepCompleted.emit();
    }
  }

  goBack(){
    this.bookFormService.setCurrentStep(4);
  }
}
