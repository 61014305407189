import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {Fuel} from "../../../models/Fuel";
import {environment} from "../../../../environments/environment";
import {Vehicle} from "../../../models/Vehicle";
import {Service} from "../../../models/Service";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {Paginator} from "../../../models/Paginator";
import {completeHttpFiltersAndSorts} from "../../../const/functions";
import {Alert, Intent} from "../../../models/Alert";

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  constructor(private http: HttpClient) { }

  list(): Observable<Response<Vehicle[]>>{
    return this.http.get<Response<Vehicle[]>>(`${environment.API_URL}/vehicles/list`)
  }


  listpPaginate(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<Paginator<Vehicle>>>{
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this.http.get<Response<Paginator<Vehicle>>>(`${environment.API_URL}/vehicles/list-paginate/${perPage}`, {params})
  }

  listPaginateAlerts(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<Paginator<Alert>>>{
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this.http.get<Response<Paginator<Alert>>>(`${environment.API_URL}/alerts/list/${perPage}`, {params})
  }

  listPaginateIntents(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<Paginator<Intent>>>{
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this.http.get<Response<Paginator<Intent>>>(`${environment.API_URL}/alerts/list-intents/${perPage}`, {params})
  }

  deleteAlert(alertId: number){
    return this.http.delete<Response<Paginator<Alert>>>(`${environment.API_URL}/alerts/delete/${alertId}`)
  }

  exportAlerts(){
    return this.http.get(`${environment.API_URL}/alerts/export-alerts`, {
      responseType: 'blob' // Esto asegura que la respuesta sea tratada como un archivo
    });
  }

  importAlerts(data:any):Observable<Response<null>>{
    return this.http.post<Response<null>>(`${environment.API_URL}/alerts/import-alerts`,data);
  }

  update(vehicleId: number, data: any, image: any): Observable<Response<Vehicle>>{
    const formData: FormData = new FormData();

    // Agregar datos al FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    // Agregar el archivo al FormData si existe
    if (image) {
      formData.append('image', image);
    }
    return this.http.post<Response<Vehicle>>(`${environment.API_URL}/vehicles/update/${vehicleId}`, formData)
  }

}
