<div class="w-full mt-3 mb-3 bg-lightGray2 text-darkGray p-4 text-xs" *ngIf="quadrantMessage">
  <p>{{ quadrantMessage}}</p>
</div>

<div class="grid grid-cols-4 gap-x-6 animate-fade-in" *ngIf="step2 && step3">

  <div class="col-span-3 flex flex-col">
    <h1 class="text-lg text-darkGray">Fecha</h1>
    <app-month-calendar [loading]="loadingDates"
                        (nextMonth)="nextMonth()"
                        (prevMonth)="prevMonth()"
                        [changeMonth]="true"
                        [frontMode]="true"
                        [blockedDates]="blockedDates"
                        [month]="month" [year]="year"
                        [selectDaysMode]="true"
                        selectedColor="#168FFB"
                        [currentSelection]="[selectedDate ?? '']"
                        (onSelectDayChange)="onSelectedDay($event)"></app-month-calendar>

  </div>
  <div *ngIf="selectedDate" class="flex flex-col animate-fade-in">
    <label class="text-lg text-darkGray">Hora</label>
    <app-loader size="large" *ngIf="loadingHours"></app-loader>
    <select *ngIf="!loadingHours" [(ngModel)]="selectedHour" (ngModelChange)="onSelectHour()" appFormSelectSquare >
      <option disabled [ngValue]="undefined">Selecciona una hora</option>
      <option class="{{hour.available<1 ? 'bg-lightGray2 text' : ''}}" [ngValue]="hour" [disabled]="hour.available<1" *ngFor="let hour of hours">{{hour.hour}} {{hour.available>0 ? ' - '+hour.available : ''}}</option>
    </select>
    <button class="mt-6 animate-fade-in" *ngIf="selectedHour" (click)="next()" appNextSquare>Continuar <!--<app-loader size="extra-small" *ngIf="loading"></app-loader>--> </button>
  </div>
</div>
