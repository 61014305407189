<div class="w-full border-l border-lightGray2 h-full overflow-scroll px-2">

  <app-loader size="large" *ngIf="loading"></app-loader>

  <div *ngIf="!loading && stations.length > 0">
    <app-card-shadow padding="p-3 mb-2" *ngFor="let station of stations">
      <p class="text-left text-primary text-xl">{{station.name}}</p>
      <div class="flex justify-start items-center gap-4">
        <span class="text-darkGray text-sm">{{ station.code }}</span> <span *ngIf="!station.start && !station.end" class="text-xs text-darkGray">{{ station.last_available | date:'dd/MM/YYYY' }}</span> <span *ngIf="station.start && station.end" class="text-xs text-darkGray">{{ station.start | date:'dd/MM/YYYY' }} - {{ station.end | date:'dd/MM/YYYY' }}</span>
      </div>
      <div class="w-full px-12 border-b border-lightGray pb-2 mt-2">
        <div class="flex flex-col text-xs text-center min-w-24">
          <!--<p class="text-[10px] text-primary px-4">Primera cita disponible</p>-->
          <div class="flex flex-row w-full gap-x-1 " *ngIf="station.first_day_available && station.first_hour_available; else noinfo">
            <p class="p-1 bg-lightBlue text-primary rounded-full w-full text-center">{{ station.first_day_available | date:'dd MMM':'':'es' | uppercase }}</p>
            <p class="p-1 bg-lightGreen2 text-primary rounded-full w-full text-center">{{station.first_hour_available.hour}}</p>
          </div>
          <ng-template #noinfo>
            <span class="text-[9px] text-darkGray px-4">Sin información</span>
          </ng-template>
        </div>
      </div>
      <div class="mt-2">
        <div class="w-full flex justify-between text-sm" >
          <div *ngIf="!station.available || station.available.length == 0" class="text-center w-full">
            <span class="text-darkGray text-sm">No hay información para mostrar</span>
          </div>
          <!-- [ngClass]="i == 0 ? 'bg-primary !text-white rounded-xl' : 'text-primary'"-->
          <div  class="text-xs flex flex-col items-center px-2 py-1 cursor-pointer text-primary" *ngFor="let day of station.available; let i = index">
            <span class="">{{day.date | customDay}}</span>
            <span [class.text-green]="day.available > 0" [class.text-red-500]="day.available <= 0">{{day.date | date:'dd'}}</span>
            <!-- <span *ngIf="i%2 != 0" [class.text-red-500]="i%2 != 0" [class.text-white]="i == 0">{{day.date | customDay}}</span> -->
            <span class="">{{ day.date | customMonth }}</span>
          </div>


        </div>
      </div>
    </app-card-shadow>
  </div>


</div>
