<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
  <div>
    <h1 class="text-2xl text-primary font-bold mb-2">MATRÍCULAS</h1>
    <div>
      <app-generic-table class="col-span-1 order-2 xl:order-1 xl:col-span-2"
                         [loading]="loading"
                         [tableHeaders]="tableHeaders"
                         (changeSorts)="onChangeSorts($event)"
                         [tableActions]="tableActions"
                         (clickAction)="onClickAction($event)"
                         [filters]="filters"
                         [showDirectAction]="true"
                         #genericTableComponent
                         [items]="alerts">

        <app-table-header-wrapper GTHeader >
          <!--<app-user-table-filters [perPageOptions]="[50,100,150,200]" (onChangePerPage)="perPage = $event; loadReservations()" [perPage]="perPage" [tableHeaders]="tableHeaders" [filters]="filters" (onChangeFilters)="onChangeFilters($event)"></app-user-table-filters>-->
          <app-table-filters
            [loading]="loadingFilters"
            [filters]="filters"
            [displayNames]="displayNames"
            [filtersList]="tableFilters"
            [perPageOptions]="[50,100,150,200]"
            [perPage]="perPage"
            (onChangeFilters)="onChangeFilters($event)"
            [tableHeaders]="tableHeaders"
            [bulkActions]="bulkActions"
            (bulkActionClick)="bulkActionClick($event)"
          >

          </app-table-filters>
        </app-table-header-wrapper>
      </app-generic-table>
    </div>

    <input
      #fileInput
      type="file"
      accept=".csv, .xls, .xlsx"
      (change)="onFileSelected($event)"
      style="display: none;"
    />
  </div>
  <div>
    <h1 class="text-2xl text-red2 font-bold mb-2">INTENTOS</h1>
    <app-intents></app-intents>
  </div>
</div>



