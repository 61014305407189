import {Component, Input, ViewChild} from '@angular/core';
import {State} from "../../../../../../models/State";
import {BlockedDatesComponent} from "../../../../../../shared/components/blocked-dates/blocked-dates.component";
import {NextOutlinedButtonDirective} from "../../../../../../shared/directives/buttons/next-outlined.directive";
import {NgForOf, NgIf} from "@angular/common";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {FormErrorDirective} from "../../../../../../shared/directives/forms/form-error.directive";
import {FormInputDirective} from "../../../../../../shared/directives/forms/form-input.directive";
import {FormLabelDirective} from "../../../../../../shared/directives/forms/form-label.directive";
import {CommunityBlockedFormComponent} from "../community-blocked-form/community-blocked-form.component";
import {StatesService} from "../../../../../../core/services/states/states.service";
import {ToastrService} from "ngx-toastr";
import {NextGreenButtonDirective} from "../../../../../../shared/directives/buttons/next-green.directive";
import {data} from "autoprefixer";

@Component({
  selector: 'app-community-card',
  standalone: true,
  imports: [
    BlockedDatesComponent,
    NextOutlinedButtonDirective,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    FormErrorDirective,
    FormInputDirective,
    FormLabelDirective,
    CommunityBlockedFormComponent,
    FormsModule,
    NextGreenButtonDirective
  ],
  templateUrl: './community-card.component.html',
  styleUrl: './community-card.component.css'
})
export class CommunityCardComponent {
  @Input() state!: State;

  @ViewChild('blockedForm') blockedForm!: CommunityBlockedFormComponent;

  selectedFile: any = null;

  constructor(private stateService: StatesService, private toastr: ToastrService) {

  }

  saveBlockedDates(info: any){
    this.stateService.addQuadrantReduction(this.state.id, info).subscribe({
      next:(response)=>{
        if (response.data) {
          this.state.quadrant_lines?.push(response.data);
          this.blockedForm.reset();
        }
      },
      error:(error)=>{
        this.toastr.error(error.error.message);
        console.log(error.error.message);
      }
    })
  }

  uploadimage(file: any){
    if (this.selectedFile) {
      this.stateService.setImage(this.state.id, file.files[0]).subscribe({
        next:(response)=>{
          if (response.data) {
            this.state = response.data;
            // console.log(response.data);
            this.selectedFile = null;
            this.toastr.success(response.message);
          }
        },
        error:(error)=>{
          this.toastr.error(error.error.message);
          this.selectedFile = null;
        }
      })
    }
  }
}
