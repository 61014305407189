<div class="w-full grid grid-cols-12 gap-8 justify-start mt-4 animate-fade-in-down" *ngIf="quadrant && !quadrant.link_type">

  <div class="col-span-2 gap-y-2 flex flex-col border-r border-r-lightGray px-4">
    <div class="flex items-center justify-between gap-2" *ngFor="let section of quadrant.sections; let i = index">
      <div class="flex items-center gap-2">
        <span class="text-darkGray">{{ section.name }}</span>
        <span class="bg-lightBlue text-primary rounded-full text-[10px] w-5 h-5 text-center flex items-center justify-center">{{ totalCapacityLine(section) }}</span>
      </div>
      <div class="flex items-center justify-end gap-2">
        <button (click)="selectSection(section)" *ngIf="editSection?.name != section.name" [style.background]="section.color" class="text-white rounded-full p-1 hover:shadow-xl duration-300 hover:scale-105">
          <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
        </button>
        <button (click)="cancelEdit()" *ngIf="editSection?.name === section.name" [style.background]="section.color" class="text-white rounded-full p-1 hover:shadow-xl duration-300 hover:scale-105">
          <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M10.507 10.498l-1.507 1.502v3h3l1.493 -1.498m2 -2.01l4.89 -4.907a2.1 2.1 0 0 0 -2.97 -2.97l-4.913 4.896" /><path d="M16 5l3 3" /><path d="M3 3l18 18" /></svg>
        </button>
        <button (click)="removeSection(section)" class="bg-red-500 hover:bg-red-600 text-white p-1 rounded-2xl hover:shadow-xl duration-300 hover:scale-105">
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16zm-9.489 5.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" /><path d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z" /></svg>
        </button>
      </div>
    </div>
    <div class="border-t border-lightGray mt-2 pt-4 flex flex-col gap-2">
      <app-export [excludes]="['csv', 'text']" (onExportClick)="exportSections($event)"  *ngIf="quadrant.sections.length > 0"></app-export>
      <app-import  [excludes]="['csv','text']" (onImportClick)="importSections($event)"></app-import>
      <!--<button class="w-full bg-primary text-white hover:shadow rounded-md py-0.5" *ngIf="quadrant.sections.length > 0">Exportar</button>-->
      <!--<button class="w-full bg-green text-white hover:shadow rounded-md py-0.5">Importar</button>-->
    </div>
  </div>
  <div class="col-span-6 grid grid-cols-4 gap-6 gap-y-0 max-h-[782px]">
    <app-month-calendar
      [currentSelection]="currentSelectedDays"
      (onSelectDayChange)="onSelectDate($event)"
      [selectDaysMode]="manualSelectDays"
      *ngFor="let month of months"
      [year]="year"
      [month]="month"
      [sections]="quadrant.sections"></app-month-calendar>
  </div>

  <div class="col-span-4">
    <app-hours-form
      #hoursForm
      (onManualSelectDays)="manualSelectDays = $event"
      (onCancel)="editSection = undefined"
      (onUpdateDates)="onUpdateDates($event)"
      (onFormSubmit)="onFormSubmit($event)"
      [manualSelectDays]="manualSelectDays"
      [selectedQuadrantLine]="editSection"></app-hours-form>
    <button *ngIf="!editSectionMode && !editSection" (click)="save()" appNextButton class=" mt-10">{{editStation ? 'AÑADIR CUADRANTES' : 'GUARDAR ESTACIÓN'}}</button>
  </div>
</div>


<div class="mb-2 max-w-3xl" *ngIf="quadrant && quadrant.link_type">
  <label appFormLabel>Link</label>
  <div class="flex gap-2">
    <input class="flex-1 " appFormInput type="text" [(ngModel)]="quadrant.link">
  </div>

  <button *ngIf="!editSectionMode && !editSection" (click)="save()" appNextButton class=" mt-10">{{editStation ? 'AÑADIR CUADRANTES' : 'GUARDAR ESTACIÓN'}}</button>
</div>



<p-confirmDialog #cancel>
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-col items-center p-5 surface-overlay border-round bg-white rounded-2xl">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
        <i class="pi pi-question text-5xl"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>
      <p class="mb-0">{{ message.message }}</p>
      <div class="flex items-center gap-2 mt-4">
        <button
          (click)="cancel.accept()"
          class="bg-primary text-white p-2 bg-opacity-95 rounded-md hover:bg-opacity-90">
          Aceptar
        </button>
        <button
          pButton
          label="Cancelar"
          (click)="cancel.reject()"
          class="bg-primary bg-opacity-0 p-2 hover:bg-opacity-10 text-primary border border-primary">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

<!--
<pre>
  {{quadrant | json}}
</pre>-->
