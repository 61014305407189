<div *ngIf="existingReservation" class="w-full mx-auto p-4 animate-fade-in">
  <div class="flex flex-row gap-x-3 justify-center items-center mb-4">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.00"
         stroke-linecap="round" stroke-linejoin="round" class="w-12 text-primary">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M4 5h2"/>
      <path d="M5 4v2"/>
      <path d="M11.5 4l-.5 2"/>
      <path d="M18 5h2"/>
      <path d="M19 4v2"/>
      <path d="M15 9l-1 1"/>
      <path d="M18 13l2 -.5"/>
      <path d="M18 19h2"/>
      <path d="M19 18v2"/>
      <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z"/>
    </svg>
    <h1 class="text-2xl">¡Genial! Has reservado tu cita para la ITV</h1>
  </div>

  <div class="grid grid-cols-2 md:grid-cols-3 gap-x-2 mt-6 text-sm">

    <div class="col-span-2 md:col-span-1">
      <div class="border-b border-gray-300 text-primary text-start py-2">Vehículo</div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ existingReservation.registration_number }}</p>
        <p>{{ existingReservation.service.name }}</p>
        <p>{{ existingReservation.vehicle.name }}</p>
        <p>{{ existingReservation.fuel.name }}</p>
      </div>
    </div>

    <div>
      <div class="border-b border-gray-300 text-primary text-start py-2">Centro ITV</div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ existingReservation.station.state.name }}</p>
        <p>{{ existingReservation.station.name }}</p>
        <a href="{{existingReservation.station.maps_link}}" target="_blank" class="underline text-primary mt-2 cursor-pointer"> Google maps</a>
      </div>
    </div>

    <div>
      <div class="border-b border-gray-300 text-primary text-start py-2">Fecha de la cita</div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ existingReservation.date | date:'dd/MM/yyyy' }}</p>
        <p>{{ existingReservation.hour.hour }}h</p>
        <!--      <a class="underline text-primary mt-2 cursor-pointer">Añadir a iCalendar</a>
              <a class="underline text-primary mt-2 cursor-pointer">Añadir a Wallet</a>-->
      </div>
    </div>


    <div class="col-span-2 md:col-span-1">
      <div class="border-b border-gray-300 text-primary text-start py-2 col-span-1">Cliente</div>
      <div class="flex flex-col text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ existingReservation.phone }}</p>
        <p>{{ existingReservation.email }}</p>
        <p>{{ existingReservation.name }}</p>
      </div>
    </div>

    <div  class="col-span-2">
      <div class="border-b border-gray-300 text-primary text-start py-2 col-span-2">Pago</div>

      <div class="col-span-2 grid grid-cols-4 text-sm pt-2 text-darkGray">
        <!--<p class="text-start">46,50€</p>-->
        <p class="text-start col-span-3">{{ existingReservation.payment_method.front_name }}</p>
      </div>
    </div>

    <div class="col-span-2 md:col-span-3 mt-4 bg-lightGray2 text-darkGray p-4 text-xs" *ngIf="existingReservation.station.limitations || existingReservation.station.information">
      <p>{{existingReservation.station.information}}</p>
      <p class="mt-2">{{existingReservation.station.limitations}}</p>
    </div>


    <div class="col-span-2 md:col-span-3 mt-4">
      <div class="border-b border-gray-300 text-primary text-start pt-2 pb-2">Justificante</div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-y-8 md:gap-y-0 gap-x-3">

        <div class="hidden md:block"></div>
        <div class="hidden md:block">
          <input type="text" [(ngModel)]="email" placeholder="Email" class="w-full text-sm px-3 py-1 rounded-full outline-primary my-2">
        </div>
        <div class="hidden md:block"></div>

        <div>
          <a appNextButton  target="_blank" href="{{environment.API_URL}}/reservations/proof/{{existingReservation.id}}/{{existingReservation.phone}}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.00"
                 stroke-linecap="round" stroke-linejoin="round" class="w-5">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"/>
              <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"/>
              <path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z"/>
            </svg>
            <p>Imprimir justificante</p>
          </a>
        </div>

        <div>
          <input type="text" [(ngModel)]="email" placeholder="Email" class="w-full text-sm px-3 py-1 rounded-full outline-primary my-2 block md:hidden">
          <button class="w-full" appNextButton  [disabled]="loadingEmail" (click)="sendProofToEmail()">
            Enviar email <app-loader size="extra-small" *ngIf="loadingEmail"></app-loader>
          </button>
        </div>

        <div *ngIf="!messageSended || messageError" appNextButton (click)="sendMessage()">
          Enviar SMS <app-loader size="extra-small" *ngIf="loadingSms"></app-loader>
        </div>
        <div *ngIf="messageSended && !messageError" appNextGreenButton (click)="sendMessage()">
          Reenviar SMS <app-loader size="extra-small" *ngIf="loadingSms"></app-loader>
        </div>
        <div class="hidden md:block"></div>
        <div class="hidden md:block"></div>
        <div class="flex justify-center">
          <span *ngIf="messageSended && !messageError" class="text-xs text-green">Mensaje enviado correctamente</span>
          <span *ngIf="messageSended && messageError" class="text-xs text-red2">Error al enviar el mensaje</span>
        </div>
      </div>

    </div>

  </div>

  <!--<button *ngIf="showResetWithoutRegistration && !isProfessional && !admin" (click)="resetWithoutRegistrationClick.emit()" class="mt-8 w-full" appOutlineButton>Recuerda que puedes gestionar tu cita</button>-->

  <button *ngIf="isProfessional && !admin" (click)="professionalOtherReservation.emit()" class="mt-8 w-full" appOutlineButton>Reservar otra cita</button>

  <div class="w-full flex justify-center mt-4" *ngIf="admin">
    <button (click)="reset()" appNextButton>
      <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-6"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" /><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></svg>
      Volver a reservar otra cita
    </button>
  </div>

</div>
