<div class="grid grid-cols-1 lg:grid-cols-4 gap-y-8 bg-gray-50 w-full border-b-2 border-gray-300 px-4 py-6">
  <div class="flex justify-center">
    <img (click)="reset()" src="assets/img/logo.svg" class="w-20 sm:w-20 md:w-24 lg:w-28 cursor-pointer" alt="logo">
  </div>
  <div class="flex flex-row justify-center lg:col-span-2">
    <div *ngFor="let num of steps; let i = index" class="flex flex-col items-center justify-center">
      <div class="flex flex-row justify-center items-center">
        <div *ngIf="(i+1) != 1"
             class="h-0 w-1 sm:w-7 border {{currentStep == num.pos ? 'border-amber-300' : ''}} {{currentStep < num.pos ? 'border-gray-400':''}} {{ currentStep > num.pos ? 'border-gray-600' : ''}} duration-300"></div>
        <div (click)="goStep(num.pos)"
             class="w-8 h-8 sm:w-8 sm:h-8  flex justify-center {{formPassed ? '' : 'cursor-pointer'}} items-center rounded-full border {{(i+1)==1 ? 'ml-7':''}} {{(i+1)==steps.length ? 'mr-8' : ''}} {{currentStep == num.pos ? 'border-amber-300 bg-amber-300 text-gray-800' : ''}} {{ currentStep > num.pos ? 'bg-gray-600 text-gray-50 border-gray-600' : ''}} {{currentStep < num.pos ? 'border-gray-400 text-gray-400':''}} duration-300">{{ (i+1) }}
        </div>
        <div *ngIf="(i+1) != steps.length"
             class="h-0 w-1 sm:w-7 border {{currentStep == num.pos ? 'border-amber-300' : ''}} {{currentStep < num.pos ? 'border-gray-400':''}} {{ currentStep > num.pos ? 'border-gray-600' : ''}} duration-300"></div>
      </div>
      <div class="text-[10px] mt-2  hidden sm:block">{{ num.name }}</div>
    </div>
  </div>
  <div class="flex gap-2 items-center justify-center">
    <a *ngIf="!authStateService.isLoggedIn()" routerLink="/login" class="bg-yellow text-white font-bold p-2 rounded-md mr-4 text-sm">PROFESIONALES</a>
    <div class="flex items-center justify-end gap-2 mr-4" *ngIf="user">
      <span class="text-darkGray text-xs animate-fade-in">{{ user.profile.name }}</span>
      <!--<img [alt]="user.profile.name" ngSrc="https://ui-avatars.com/api/?name={{user.profile.name.replace(' ', '+')}}&background=168FFB&color=fff&size=128" class="object-cover w-10 h-10 rounded-full animate-fade-in"
           height="10" width="10"/>-->
      <button class="bg-green hover:bg-green2 text-white hover:text-green border hover:border-green p-2 rounded-full" (click)="logOut()">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-logout"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M9 12h12l-3 -3" /><path d="M18 15l3 -3" /></svg>
      </button>
    </div>

    <a href="#" onclick="doGTranslate('es|es');return false;" title="Español" id="español" class="glink nturl notranslate"><img class="w-5" src="/assets/img/es.svg" alt="Español"/></a>
    <a href="#" onclick="doGTranslate('es|ca');return false;" title="Catalá" id="catala" class="glink nturl notranslate"><img class="w-5"  src="/assets/img/ca.png" alt="Catalá"/></a>
  </div>
</div>
