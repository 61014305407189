import {Component, OnInit} from '@angular/core';
import {TableHeader} from "../../../../models/Table/TableHeader";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {Filter} from "../../../../models/Table/Filter";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {Paginator} from "../../../../models/Paginator";
import {Alert, Intent} from "../../../../models/Alert";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";

@Component({
  selector: 'app-intents',
  standalone: true,
  imports: [
    GenericTableComponent,
    TableFiltersComponent,
    TableHeaderWrapperComponent
  ],
  templateUrl: './intents.component.html',
  styleUrl: './intents.component.css'
})
export class IntentsComponent implements OnInit {
  loading:boolean=false;
  intents:Paginator<Intent>| null = null;

  tableHeaders: TableHeader[] = [
    {
      name: 'MATRÍCULA',
      active: true,
      td_type: 'text',
      td_key: 'registration_number',
      sort: true,
    },
    {
      name: 'IFORMACIÓN',
      active: true,
      td_type: 'text',
      td_key: 'description',
      sort: false,
    },
    {
      name: 'FECHA Y HORA',
      active: true,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'created_at',
      sort: false,
    },
  ]
  sorts: SortColumn[] = [
    {
      column: 'created_at',
      order: 'desc'
    }
  ];

  tableFilters: Filter[] = [
    {
      key: 'registration_number',
      type: 'search',
      operator: 'like',
      placeholder: 'Buscar...'
    }
  ]
  perPage: number = 50;
  loadingList = false;
  loadingFilters = false;

  displayNames: { [key: string]: string } = {
    registration_number: 'Matrícula',
  };
  filters: ActiveFilter[] = [];

  constructor(private vehicleService: VehiclesService, private toastr: ToastrService, private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.loading = true;
    this.loadAlerts();
  }



  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    this.loadAlerts();
  }

  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    this.loadAlerts();
  }

  loadAlerts(){
    this.vehicleService.listPaginateIntents(this.sorts, this.filters, this.perPage).subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.intents = response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }
}
