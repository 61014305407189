<div class="mt-12">

  <p class="text-primary text-xl font-bold">Resumen de SMS por estación</p>
  <div class="w-full mt-4">
    <app-generic-table [tableHeaders]="tableHeaders" [items2]="data" [loading]="loadingInfo"  [filters]="filters">

      <app-table-header-wrapper GTHeader >
        <app-table-filters
          [filtersList]="tableFilters"
          [loading]="loadingFilters"
          [displayNames]="displayNames"
          [perPageOptions]="[50,100,150,200]"
          [tableHeaders]="tableHeaders"
          [filters]="filters"
          (onChangeFilters)="onChangeFilters($event)"></app-table-filters>
      </app-table-header-wrapper>

    </app-generic-table>
  </div>

</div>
