import { Component } from '@angular/core';
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../../../models/Front/ReservationForm";
import {BookFormService} from "../../../../../../core/services/book-form.service";
import {DatePipe, NgIf} from "@angular/common";
import {StepperNumberDirective} from "../../../../../../shared/directives/stepper/stepper-number.directive";

@Component({
  selector: 'app-summaryv2',
  standalone: true,
  imports: [
    NgIf,
    StepperNumberDirective,
    DatePipe
  ],
  templateUrl: './summaryv2.component.html',
  styleUrl: './summaryv2.component.css'
})
export class Summaryv2Component {

  step1?: Step1;
  step2?: Step2;
  step3?: Step3;
  step4?: Step4;
  step5?: Step5;
  step6?: Step6;

  constructor(private bookFormService: BookFormService) {
    this.bookFormService.reservationForm$.subscribe({
      next: params => {
        this.step1 = params.steps.step1;
        this.step2 = params.steps.step2;
        this.step3 = params.steps.step3;
        this.step4 = params.steps.step4;
        this.step5 = params.steps.step5;
        this.step6 = params.steps.step6;
      }
    })
  }
}
