import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {ReservationForm, Step1, Step2, Step3, Step4, Step5, Step6} from "../../models/Front/ReservationForm";

@Injectable({
  providedIn: 'root'
})
export class BookFormService {
  private reservationFormSubject: BehaviorSubject<ReservationForm>;
  public reservationForm$: Observable<ReservationForm>;


  private reservationResetFormSubject: BehaviorSubject<undefined>;
  public reservationResetForm$: Observable<undefined>;

  private localStorageId = 'itvFrontForm';
  private expirationTime = 50 * 60 * 1000; // 15 minutos en milisegundos

  private initialFormState: ReservationForm = {
    step: 1,
    professional: false,
    showSummary: false,
    confirmed: false,
    steps: {
      step1: undefined,
      step2: undefined,
      step3: undefined,
      step4: undefined,
      step5: undefined,
      step6: undefined,
    }
  };

  reset(step1:Step1|undefined, professional: boolean = false) {
    this.reservationFormSubject.next({
      step: 1,
      showSummary: false,
      professional: professional,
      professional_id: undefined,
      confirmed: false,
      steps: {
        step1: step1,
        step2: undefined,
        step3: undefined,
        step4: undefined,
        step5: undefined,
        step6: undefined,
      }
    });  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
    if (!step1) {
      this.reservationResetFormSubject.next(undefined);
    }

  }

  resetProfessional(step1:Step1, step5: Step5) {
    this.reservationFormSubject.next({
      step: 1,
      showSummary: false,
      professional: true,
      confirmed: false,
      steps: {
        step1: step1,
        step2: undefined,
        step3: undefined,
        step4: undefined,
        step5: undefined,
        step6: undefined,
      }
    });  // Asegúrate de actualizar el BehaviorSubject
    // console.log(this)
    this.updateLocalStorage();
  }

  fillFormUntilStep4(step1:Step1,step2:Step2,step3:Step3,step4:Step4){
    this.reservationFormSubject.next({
      step:5,
      showSummary: true,
      professional: false,
      confirmed: false,
      steps: {
        step1: step1,
        step2: step2,
        step3: step3,
        step4: step4,
        step5: undefined,
        step6: undefined,
      }
    });
    this.updateLocalStorage();
  }

  fillFormUntilStep3State(step1:Step1,step2:Step2,step3:Step3){
    this.reservationFormSubject.next({
      step:3,
      showSummary: true,
      professional: false,
      confirmed: false,
      steps: {
        step1: step1,
        step2: step2,
        step3: step3,
        step4: undefined,
        step5: undefined,
        step6: undefined,
      }
    });
    this.updateLocalStorage();
  }

  setProfessionalId(professionalId: number){
    let state = this.currentReservationFormValue;
    state.professional_id = professionalId;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  fillFormUntilStep3(step1:Step1,step2:Step2,step3:Step3){
    this.reservationFormSubject.next({
      step:4,
      showSummary: true,
      professional: false,
      confirmed: false,
      steps: {
        step1: step1,
        step2: step2,
        step3: step3,
        step4: undefined,
        step5: undefined,
        step6: undefined,
      }
    });
    this.updateLocalStorage();
  }



  constructor() {
    const savedData = null;//localStorage.getItem(this.localStorageId);
    const initialForm = savedData ? this.loadForm(savedData) : this.initialFormState;

    this.reservationFormSubject = new BehaviorSubject<ReservationForm>(initialForm);
    this.reservationForm$ = this.reservationFormSubject.asObservable();

    this.reservationResetFormSubject = new BehaviorSubject<undefined>(undefined);
    this.reservationResetForm$ = this.reservationResetFormSubject.asObservable();

    //this.selectedSportCenterSubject = new BehaviorSubject<SportCenter>(null);
    // this.reservationForm$ = this.reservationFormSubject.asObservable();
  }

  public get currentReservationFormValue(): ReservationForm {
    return this.reservationFormSubject.value;
  }

  private updateLocalStorage(): void {
    /*const dataToSave = {
      form: this.currentReservationFormValue,
      timestamp: new Date().getTime()
    };
    localStorage.setItem(this.localStorageId, JSON.stringify(dataToSave));*/
  }

  private loadForm(savedData: string): ReservationForm {
    const parsedData = JSON.parse(savedData);
    const currentTime = new Date().getTime();

    if (parsedData.timestamp && (currentTime - parsedData.timestamp) < this.expirationTime) {
      return parsedData.form;
    } else {
      // Si ha expirado, eliminar los datos de localStorage
      localStorage.removeItem(this.localStorageId);
      return this.initialFormState;
    }
  }

  setCurrentStep(currentStep: number): void {
    let state = this.currentReservationFormValue;
    state.step = currentStep;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setCurrentStep3WithReset(currentStep: number): void {
    let state = this.currentReservationFormValue;
    state.step = currentStep;
    state.steps.step3 = undefined;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setShowSummary(showSummary: boolean) {
    let state = this.currentReservationFormValue;
    state.showSummary = showSummary;
    this.reservationFormSubject.next(state);
    this.updateLocalStorage();
  }

  toggleSummary() {
    let state = this.currentReservationFormValue;
    state.showSummary = !state.showSummary;
    this.reservationFormSubject.next(state);
    this.updateLocalStorage();
  }

  updateRegistrationNumber(registration_number: string): void {
    let state = this.currentReservationFormValue;
    if (state.steps.step1){
      state.steps.step1.registration_number = registration_number;
      state.step = 2;
    }
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  updateStep1(step1: Step1) {
    let state = this.currentReservationFormValue;
    state.steps.step1 = step1;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setStep1(step1: Step1, redirect: boolean = true) {
    let state = this.currentReservationFormValue;
    state.steps.step1 = step1;
    if (redirect) {
      state.step = 2;
      if (window.innerWidth >= 1280) {
        state.showSummary = true;
      }
    } else {
      state.step = 1;
    }
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  updateStep2(step2: Step2) {
    let state = this.currentReservationFormValue;
    state.steps.step2 = step2;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setStep2(step2: Step2) {
    let state = this.currentReservationFormValue;
    state.steps.step2 = step2;
    state.step = 3;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  resetStep3(){
    // console.log('Hola');
    let state = this.currentReservationFormValue;
    // state.steps.step3 = undefined;
    state.steps.step3 = {
      subStep: 0,
      station: undefined,
      state: undefined
    }
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  updateStep3(step3: Step3) {
    let state = this.currentReservationFormValue;
    state.steps.step3 = step3;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setStep3(step3: Step3) {
    let state = this.currentReservationFormValue;
    state.steps.step3 = step3;
    state.step = 4;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  resetStep4(){
    let state = this.currentReservationFormValue;
    state.steps.step4 = undefined;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  updateStep4(step4: Step4) {
    let state = this.currentReservationFormValue;
    state.steps.step4 = step4;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setStep4(step4: Step4) {
    let state = this.currentReservationFormValue;
    state.steps.step4 = step4;
    state.step = 5;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }
  setStep4WithoutPass(step4: Step4) {
    let state = this.currentReservationFormValue;
    state.steps.step4 = step4;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  updateStep5(step5: Step5){
    let state = this.currentReservationFormValue;
    state.steps.step5 = step5;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setStep5(step5: Step5) {
    let state = this.currentReservationFormValue;
    state.steps.step5 = step5;
    if (state.steps.step3?.station?.payment_methods.length == 1) {
      state.step = 7;
      state.steps.step6 = {payment: state.steps.step3?.station?.payment_methods[0] }
    } else {
      state.step = 6;
    }
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setStep6(step6: Step6) {
    let state = this.currentReservationFormValue;
    state.steps.step6 = step6;
    state.step = 7;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }

  setConfirmed(confirm: boolean){
    let state = this.currentReservationFormValue;
    state.confirmed = confirm;
    this.reservationFormSubject.next(state);  // Asegúrate de actualizar el BehaviorSubject
    this.updateLocalStorage();
  }




}
