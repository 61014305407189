import {Component, Input} from '@angular/core';
import {LoaderComponent} from "../../../../../shared/components/loader/loader.component";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-card-shadow',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    NgClass
  ],
  templateUrl: './card-shadow.component.html',
  styleUrl: './card-shadow.component.css'
})
export class CardShadowComponent {
  @Input() showLoading:boolean = false;
  @Input() padding: string  = 'px-4 md:px-8 py-8';
}
