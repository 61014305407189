import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Step1, Step2} from "../../../../../models/Front/ReservationForm";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {selectStep2} from "../../../../../store/reservationForm/stepper.selector";
import {updateStep, updateStep1, updateStep2} from "../../../../../store/reservationForm/stepper.actions";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {update} from "@angular-devkit/build-angular/src/tools/esbuild/angular/compilation/parallel-worker";
import {Service} from "../../../../../models/Service";
import {ServicesService} from "../../../../../core/services/services/services.service";
import {Vehicle} from "../../../../../models/Vehicle";
import {Fuel} from "../../../../../models/Fuel";
import {VehiclesService} from "../../../../../core/services/vehicles/vehicles.service";
import {FrontService} from "../../../../../core/services/front/front.service";
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {CardSelectorComponent} from "../../utils/card-selector/card-selector.component";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {AuthStateService} from "../../../../../core/services/auth/auth-state.service";

@Component({
  selector: 'app-step2',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    CardShadowComponent,
    JsonPipe,
    CardSelectorComponent,
    FrontBackDirective
  ],
  templateUrl: './step2.component.html',
  styleUrl: './step2.component.css'
})
export class Step2Component implements OnInit{

  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  serviceSelected?:Service;
  vehicleSelected?:Vehicle;
  fuelSelected?:Fuel;
  services?:Service[];
  vehicles?:Vehicle[];
  fuels?:Fuel[];
  substep: number = 1;

  loading: boolean = false;

  isProfessional = false;

  constructor(private store: Store, private frontService:FrontService, private authStateService: AuthStateService) {
    this.step2$ = this.store.select(selectStep2);

    this.authStateService.currentUser.subscribe({
      next: data => {
        if (data && data.roles.includes('professional')) {
          this.isProfessional = true;
        }
      }
    })

    this.step2$.subscribe(step2 => {
      this.step2 = step2;

      // console.log(step2);
      if (step2) {
       //  this.substep = step2.subStep ?? 1;
        this.serviceSelected = step2.service;
        /*if (step2.service) {
          this.selectService(step2.service);
        }*/
        this.vehicleSelected = step2.vehicle;
        /*if (step2.vehicle) {
          this.selectVehicle(step2.vehicle);
        }*/
        this.fuelSelected = step2.fuel;

      }
    });
    this.loading = true;
    this.frontService.listServices().subscribe(
      {
        next: (response) => {
          if(response.data){
            this.services=response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );

  }

  ngOnInit() {
    if (this.step2 && this.step2.service) {
      // this.selectService(this.step2.service);
      this.loadVehicles(this.step2.service)
    }
    /*if (this.step2 && this.step2.vehicle && this.step2.vehicle) {
      this.loadFuels(this.step2.vehicle, this.step2.service)
      this.selectVehicle(this.step2.vehicle);
    }*/
  }




  public nextSubStep(step: number) {
    this.substep = step;
    // this.update();
  }

  public backSubStep(){
    if (this.substep > 1) {
      this.substep--;
    } else {
      this.store.dispatch(updateStep({ step: 1 }));
    }
  }

  public nextStep() {
    this.store.dispatch(updateStep({ step: 3 }));
  }

  public update() {
    const step2: Step2 = {
      subStep: this.substep,
      service:this.serviceSelected,
      vehicle:this.vehicleSelected,
      fuel:this.fuelSelected,
    };
    this.store.dispatch(updateStep2({step2}));

    if (this.serviceSelected && this.vehicleSelected && this.fuelSelected) {
      // this.stepCompleted.emit();
      this.nextStep();
    }
  }

  public selectService(service: Service) {
      this.serviceSelected = service;
      this.loadVehicles(service);
      this.nextSubStep(2);
  }

  loadVehicles(service: Service) {
    this.loading = true;
    this.frontService.listVehicles(service.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.vehicles=response.data;
          }
          this.loading = false;
        },
        error: err => {
          // console.log(err);
          this.loading = false;
        }
      }
    );
  }

  public selectVehicle(vehicle:Vehicle) {
      this.vehicleSelected = vehicle;
      if(this.serviceSelected){
        this.loadFuels(vehicle, this.serviceSelected)
      }
    this.nextSubStep(3);
  }

  loadFuels(vehicle:Vehicle, service: Service){
    this.loading = true;
    this.frontService.listFuels(service.id,vehicle.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.fuels=response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  public selectFuel(fuel: Fuel) {
      this.fuelSelected = fuel;
      this.update();
  }


}
