import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import {AuthStateService} from "../services/auth/auth-state.service";

@Injectable()
export class AddBearerTokenInterceptor implements HttpInterceptor {
  constructor(private authStateService: AuthStateService) { }

  /**
   * Interceptor para añadir el token del usuario autenticado en cada request que se haga.
   *
   * Añade a todas las peticiones que se hagan desde la app el Bearer token del usuario si este está autenticado.
   *
   * @param req Request completa.
   * @param next Continuar con la petición.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const excludedHost = '85.187.56.108:5081';

    // Si el usuario está logueado y la URL no contiene la IP excluida
    if (this.authStateService.isLoggedIn()) {
      let accessToken = this.authStateService.currentUserValue?.token;
      if (req.url.includes(excludedHost)) {
        accessToken = "b4WWQPavAJyvEXXZOmIlh2ylHtSQ3rIQR4Vk4O33q8UetfdMyyqrIOOmqsZd2SELbbDcRhPxm82lvM38DCCzjcLh9wj4oOwbzDXvPtOjxX0i0Q70wYDDsybKJ6OP4IbFe8orsxFETX4RQH2FdeqXIyfAttEVSTwtKvGjAZJhXqze7urWpCmBf8R6HxErvrsI7MGBPm9lD54eumSxIGHO29UmaLVXyLBDJsZJa6gJhhT7RV2eRSRw6zXpxxcO0s9G";
        req = req.clone({
          setHeaders: {
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Authorization': "Bearer " + accessToken,
            'Access-Control-Allow-Origin': '*'
          }
        });
      } else {
        req = req.clone({
          setHeaders: {
            Authorization: "Bearer " + accessToken,
            "X-Requested-With": "XMLHttpRequest"
          }
        });
      }

    }

    return next.handle(req);
  }
}
