import {Component, ElementRef, ViewChild} from '@angular/core';
import {AuthFormInputDirective} from "../../../../shared/directives/auth/auth-form-input.directive";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {InputSwitchModule} from "primeng/inputswitch";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgxSpinnerComponent} from "ngx-spinner";
import {RouterOutlet} from "@angular/router";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {ReservationService} from "../../../../core/services/reservations/reservation.service";
import {Paginator} from "../../../../models/Paginator";
import {Reservation} from "../../../../models/Reservation";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {find, forkJoin} from "rxjs";
import {Filter} from "../../../../models/Table/Filter";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {User} from "../../../../models/User";
import {CardShadowComponent} from "../../../front/components/utils/card-shadow/card-shadow.component";
import {FormInputOutlinedDirective} from "../../../../shared/directives/forms/form-input-outlined.directive";
import {NextSquareButtonDirective} from "../../../../shared/directives/buttons/next-square.directive";
import {FormInputDirective} from "../../../../shared/directives/forms/form-input.directive";
import {FormSelectSquareDirective} from "../../../../shared/directives/forms/form-select-square.directive";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";
import {RoleV2, UserV2} from "../../../../models/UserV2";
import {UserService} from "../../../../core/services/users/user.service";
import {ToastrService} from "ngx-toastr";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {FormInputBlueNoFullDirective} from "../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {RoleService} from "../../../../core/services/roles/role.service";
import {FormInputNoFullDirective} from "../../../../shared/directives/forms/form-input-no-full.directive";
import {FormErrorDirective} from "../../../../shared/directives/forms/form-error.directive";
import {StationService} from "../../../../core/services/stations/station.service";
import {Station} from "../../../../models/Station";
import {MultiSelectModule} from "primeng/multiselect";
import {TableActions} from "../../../../models/Table/TableActions";
import {DialogModule} from "primeng/dialog";
import {
  ExistingReservationCardComponent
} from "../../../front/components/existing-reservation-card/existing-reservation-card.component";
import {FormLabelDirective} from "../../../../shared/directives/forms/form-label.directive";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {Service} from "../../../../models/Service";



@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    AuthFormInputDirective,
    NgIf,
    InputSwitchModule,
    FormsModule,
    NgxSpinnerComponent,
    RouterOutlet,
    TableHeaderWrapperComponent,
    TableFiltersComponent,
    GenericTableComponent,
    CardShadowComponent,
    FormInputOutlinedDirective,
    NextSquareButtonDirective,
    FormInputDirective,
    FormSelectSquareDirective,
    NextButtonDirective,
    ReactiveFormsModule,
    FormInputBlueNoFullDirective,
    NgForOf,
    LoaderComponent,
    FormInputNoFullDirective,
    FormErrorDirective,
    MultiSelectModule,
    DialogModule,
    ExistingReservationCardComponent,
    FormLabelDirective,
    JsonPipe
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent {

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @ViewChild('genericTableComponent') genericTableComponent!: GenericTableComponent<UserV2>;

  userForm : FormGroup;

  editUserForm : FormGroup;

  selectedEditUserRole?:RoleV2;

  stations: Station[] = [];

  displayEditDialog:boolean=false;

  loadingEdit:boolean=false;

  selectedUser?: {action: TableActions, item: UserV2, index: number};

  constructor(private userService:UserService, private roleService:RoleService, private toastr: ToastrService,
              private stationService: StationService, private  breadcrumbService: BreadcrumbService) {

    this.breadcrumbService.setBreadcrumb({
      display_name: 'Dashboard',
      items: [{
        name: 'Usuarios',
      }]
    })

    this.userForm = new FormGroup({
      name:new FormControl('',[Validators.required,Validators.minLength(3)]),
      email:new FormControl('',[Validators.required,Validators.email]),
      phone:new FormControl('',[]),
      password:new FormControl('',[Validators.required]),
      stations: new FormControl([])
    })

    this.editUserForm = new FormGroup({
      name:new FormControl('',[Validators.required,Validators.minLength(3)]),
      email:new FormControl('',[Validators.required,Validators.email]),
      phone:new FormControl('',[]),
      password:new FormControl('',[]),
      stations: new FormControl([])
    })

    this.loadUsers();
    this.loadStations();
    this.loadRoles();
  }

  perPage: number = 50;
  loadingList = false;
  loadingFilters = false;
  loadingCreate = false;
  roles?:RoleV2[];
  selectedRole?:RoleV2;

  filters: ActiveFilter[] = [];
  sorts: SortColumn[] = [];

  tableHeaders: TableHeader[] = [
    {
      name: 'ID',
      active: true,
      td_type: 'text',
      td_key: 'id',
      sort: false,
    },
    {
      name: 'NOMBRE',
      active: true,
      td_type: 'text',
      td_key: 'name',
      sort: false,
    },
    {
      name: 'EMAIL',
      active: true,
      td_type: 'text',
      td_key: 'email',
      sort: false,
    },
    {
      name: 'ROL',
      td_type: 'text',
      td_key: 'role',
      active: true,
      table: 'roles',
      td_sub_key: 'name',
      sort: false,
    },
    {
      name: 'FECHA VERIFICACIÓN EMAIL',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'email_verified_at',
      sort: false,
    },
    {
      name: 'FECHA CREACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'created_at',
      sort: false,
    },
    {
      name: 'FECHA MODIFICACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'updated_at',
      sort: false,
    }
  ]

  tableFilters: Filter[] = [
    {
      key: 'name',
      type: 'search',
      operator: 'like',
      placeholder: 'Buscar...'
    }
  ]

  tableActions: TableActions[] = [
    {
      icon: '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>',
      name: 'Editar',
      key: 'edit',
      color: '#FF8A4C',
      confirm: false,
    }
  ];


  users: Paginator<UserV2> | null = null;

  loadUsers(){
    this.loadingList = true;
    this.userService.list(this.sorts, this.filters, this.perPage).subscribe({
      next: data => {
        if (data.data) {
          this.users = data.data;
        } else {
          this.users = null;
        }
        this.loadingList = false;
      },
      error: err => {
        this.loadingList = false;
      },
    })
  }

  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    this.loadUsers();
  }

  loadStations(){
    this.stationService.list().subscribe({
      next: data => {
        this.stations = data.data ?? [];
      }
    })
  }

  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    this.loadUsers();
  }


  loadRoles(){
    this.roleService.list().subscribe({
      next: data => {
        if (data.data) {
          this.roles = data.data;
        }
      },
    })
  }

  createUser(){
    this.loadingCreate=true;
    let data:any = {
      name:this.userForm.get('name')?.value,
      email:this.userForm.get('email')?.value,
      phone:this.userForm.get('phone')?.value,
      password:this.userForm.get('password')?.value,
      role_id:this.selectedRole?.id,
      stations: this.userForm.get('stations')?.value
    }

    // console.log(data);

    if(this.userForm.invalid || this.selectedRole===undefined){
      if(this.userForm.get('name')?.invalid){
        this.toastr.error('La logitud del nombre no puedes ser menor a 3')
      }
      if(this.userForm.get('email')?.invalid){
        this.toastr.error('El email no es válido')
      }
      if(this.userForm.get('password')?.invalid){
        this.toastr.error('La contraseña no es válida')
      }
      if(this.selectedRole===undefined){
        this.toastr.error('Debe seleccionar un rol para el usuario')
      }
      this.loadingCreate = false;
    } else {
      this.userService.create(data).subscribe({
        next: (response) => {
          this.toastr.success(response.message)
          if(response.data){
            this.addUserToPaginator(response.data)
            this.userForm.patchValue({
              name:'',
              email:'',
              password:'',
            })
            this.selectedRole=undefined;
          }
          this.loadingCreate = false;
        },
        error: (err) => {
          this.toastr.error(err.error.message)
          this.loadingCreate = false;
        }
      })
    }
  }

  editUser(){
    this.loadingEdit=true;
    let data:any = {
      name:this.editUserForm.get('name')?.value,
      email:this.editUserForm.get('email')?.value,
      phone:this.editUserForm.get('phone')?.value,
      password:this.editUserForm.get('password')?.value,
      role_id:this.selectedEditUserRole?.id,
      stations: this.editUserForm.get('stations')?.value
    }
    if(this.editUserForm.invalid || this.selectedEditUserRole===undefined){
      if(this.editUserForm.get('name')?.invalid){
        this.toastr.error('La logitud del nombre no puedes ser menor a 3')
      }
      if(this.editUserForm.get('email')?.invalid){
        this.toastr.error('El email no es válido')
      }
      if(this.editUserForm.get('password')?.invalid){
        this.toastr.error('La contraseña no es válida')
      }
      if(this.editUserForm===undefined){
        this.toastr.error('Debe seleccionar un rol para el usuario')
      }
      this.loadingEdit = false;
    } else {
      if(this.selectedUser){
        this.userService.update(this.selectedUser.item.id,data).subscribe({
          next: (response) => {
            this.toastr.success(response.message)
            if(response.data && this.selectedUser){
              this.selectedUser.item = response.data;
              // this.replaceUserInPaginator(response.data)
              this.genericTableComponent.setItemAt(this.selectedUser.index, this.selectedUser.item);
              this.editUserForm.patchValue({
                name:'',
                email:'',
                password:'',
              })
              this.displayEditDialog=false;
              this.selectedEditUserRole=undefined;
            }
            this.loadingEdit = false;
          },
          error: (err) => {
            this.toastr.error(err.error.message)
            this.loadingEdit = false;
          }
        })
      }
    }
  }


  addUserToPaginator(newUser: UserV2) {
    if (this.users) {
      // Add the new user to the beginning of the current page
      this.users.data.push(newUser);

      // If the number of users exceeds the perPage limit, remove the last one
      if (this.users.data.length > this.perPage) {
        this.users.data.pop();
      }

      // Increment the total count of users
      this.users.total += 1;
    }
  }

  replaceUserInPaginator(updatedUser: UserV2) {
    if (this.users) {
      // Encuentra el índice del usuario que se quiere reemplazar
      const index = this.users.data.findIndex(user => user.id === updatedUser.id);

      // Si el usuario existe, reemplázalo
      if (index !== -1) {
        this.users.data[index] = updatedUser;
      }
    }
  }

  openEditDialog(user: {action: TableActions, item: UserV2, index: number}) {
    this.selectedUser = user; // Asigna la reserva seleccionada
    this.editUserForm.get('name')?.patchValue(user.item.name);
    this.editUserForm.get('email')?.patchValue(user.item.email);
    this.editUserForm.get('phone')?.patchValue(user.item.phone);
    this.editUserForm.get('stations')?.patchValue(user.item.stations ? user.item.stations.map(s=>s.id) : []);
    this.selectedEditUserRole = this.roles?.find(r => r.id === user.item.role.id);
    this.displayEditDialog = true; // Muestra el diálogo
  }

  onClickAction(data: {action: TableActions, item: UserV2, index: number}) {
    if(data.action.key === 'edit'){
      this.openEditDialog(data);
    }
  }


  bulkActions = [
    { label: 'Importar usuarios', key: 'import' }
  ];

  bulkActionClick(key: string) {
    if (key === 'import') {
      this.fileInput.nativeElement.click();
    }
  }

  // 4. Implementar el Método para Manejar la Selección y Subida del Archivo
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    // Validar el tipo de archivo
    const allowedExtensions = ['csv', 'xls', 'xlsx'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      this.toastr.error('Por favor, selecciona un archivo CSV o Excel válido.');
      return;
    }

    // Crear FormData para enviar el archivo
    const formData = new FormData();
    formData.append('excel_file', file);

    // Mostrar un loader o indicador de carga
    this.loadingList = true; // Puedes usar otro flag si prefieres

    // Enviar el archivo al backend usando UserService
    this.userService.importUsers(formData).subscribe({
      next: (response) => {
        this.toastr.success(response.message);
        // Opcional: Recargar la lista de usuarios
        this.loadUsers();
        this.loadingList = false;
      },
      error: (err) => {
        this.toastr.error(err.error.message || 'Error al importar usuarios.');
        this.loadingList = false;
      }
    });

    // Reiniciar el valor del input para permitir subir el mismo archivo nuevamente si es necesario
    input.value = '';
  }

}
