import {Component, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {ToastrService} from "ngx-toastr";
import {FrontService} from "../../../../core/services/front/front.service";
import {Service} from "../../../../models/Service";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {Filter} from "../../../../models/Table/Filter";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";
import {Paginator} from "../../../../models/Paginator";
import {ServicesService} from "../../../../core/services/services/services.service";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormErrorDirective} from "../../../../shared/directives/forms/form-error.directive";
import {FormInputDirective} from "../../../../shared/directives/forms/form-input.directive";
import {FormLabelDirective} from "../../../../shared/directives/forms/form-label.directive";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {NgIf} from "@angular/common";
import {TableActions} from "../../../../models/Table/TableActions";
import {Vehicle} from "../../../../models/Vehicle";

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    GenericTableComponent,
    TableFiltersComponent,
    TableHeaderWrapperComponent,
    FormErrorDirective,
    FormInputDirective,
    FormLabelDirective,
    FormsModule,
    LoaderComponent,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent implements OnInit{

  editService?: {action: TableActions, item: Service, index: number} = undefined;
  editForm?: FormGroup;
  loadingEdit: boolean = false;

  @ViewChild('genericTableComponent') genericTableComponent!: GenericTableComponent<Service>;

  loading:boolean=false;
  services:Paginator<Service>| null = null;
  constructor(private breadcrumbService: BreadcrumbService, private stationService: StationService, private serviceService:ServicesService,
              private toastr: ToastrService,  private fb: FormBuilder) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Servicios',
      items: [{
        name: 'Listado',
      }]
    });
  }

  ngOnInit() {
    this.loading=true;
    this.loadServices();
  }

  loadServices(){
    this.serviceService.listpPaginate().subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.services = response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  perPage: number = 50;
  loadingList = false;
  loadingFilters = false;

  filters: ActiveFilter[] = [];
  sorts: SortColumn[] = [];

  tableHeaders: TableHeader[] = [
    {
      name: 'ID',
      active: true,
      td_type: 'text',
      td_key: 'id',
      sort: false,
    },
    {
      name: 'ITV ID',
      active:true,
      td_type:"text",
      td_key: 'other_id',
      sort:false,
    },
    {
      name: 'NOMBRE',
      active: true,
      td_type: 'text',
      td_key: 'name',
      sort: false,
    },
    {
      name: 'SLUG',
      active: true,
      td_type: 'text',
      td_key: 'slug',
      sort: false,
    },
    {
      name: 'MENSAJE',
      active: false,
      td_type: 'text',
      td_key: 'no_open_message',
      sort: false,
    },
    {
      name: 'SERVICIO PADRE',
      td_type: 'text',
      td_key: 'service_parent',
      active: false,
      table: 'service_parents',
      td_sub_key: 'name',
      sort: false,
    },
    {
      name: 'TIPO',
      active: true,
      td_type: 'text',
      td_key: 'type',
      sort: false,
    },
    {
      name: 'FECHA CREACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'created_at',
      sort: false,
    },
    {
      name: 'FECHA MODIFICACIÓN',
      active: false,
      td_type: 'date',
      format:'dd/MM/Y HH:mm',
      td_key: 'updated_at',
      sort: false,
    }
  ]

  tableFilters: Filter[] = [
    {
      key: 'name',
      type: 'search',
      operator: 'like',
      placeholder: 'Buscar...'
    }
  ]

  tableActions: TableActions[] = [
    {
      icon: '<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"   class="w-8"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>',
      name: 'Editar',
      key: 'update',
      color: '#ff9900',
      confirm: false,
    }
  ];

  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    this.loadServices();
  }

  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    this.loadServices();
  }

  closeEditModal(){
    this.editService = undefined;
    this.editForm = undefined;
    // this.newImage = undefined;
    // this.newImagePreviewUrl = undefined;
  }

  setEditVehicle(data: {action: TableActions, item: Service, index: number}){
    this.editForm = this.fb.group({
      name: [data.item.name, Validators.required],
      // short_name: [data.item.short_name, Validators.required],
      type: [data.item.type, Validators.required],
      no_open_message: [data.item.no_open_message],
      other_id: [data.item.other_id]
      /*paymentMethods: new FormControl<number[] | null>([],[ Validators.required, Validators.minLength(1)])//[],//[null, Validators.required, Validators.minLength(1)],*/
    });
    this.editService = data;
    //console.log(this.editService);
  }
  onClickAction(data: {action: TableActions, item: Service, index: number}) {
    if (data.action.key == 'update') {
      this.setEditVehicle(data);
    }
  }

  update(){
    if (this.editForm && this.editForm.valid && this.editService) {

      //console.log(this.editForm.value);
      //this.loadingEdit = true;

      console.log(this.editForm.value);
      this.loadingEdit = true;
      this.serviceService.update(this.editService.item.id, this.editForm.value).subscribe({
        next: data => {
          this.toastr.success('Tipo de vehículo editado correctamente.');
          if (data.data && this.editService) {
            this.editService.item = data.data;
            this.setEditVehicle(this.editService);
            this.genericTableComponent.setItemAt(this.editService.index, this.editService.item);
          }

          this.loadingEdit = false;
        },
        error: err => {
          this.toastr.error('Ha habido un error al editar el tipo de vehiculo.');
          this.loadingEdit = false;
        }
      })
    } else {
      this.editForm?.markAllAsTouched();
    }
  }
}
