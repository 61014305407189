import { Component } from '@angular/core';
import {BookFormService} from "../../../../../../../../core/services/book-form.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NextSquareButtonDirective} from "../../../../../../../../shared/directives/buttons/next-square.directive";
import {FormErrorDirective} from "../../../../../../../../shared/directives/forms/form-error.directive";
import {LoaderComponent} from "../../../../../../../../shared/components/loader/loader.component";
import {NgIf} from "@angular/common";
import {updateStep, updateStep5, updateStep6} from "../../../../../../../../store/reservationForm/stepper.actions";
import {Step5, Step6} from "../../../../../../../../models/Front/ReservationForm";
import {FrontBackDirective} from "../../../../../../../../shared/directives/buttons/front.back.directive";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-step5',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NextSquareButtonDirective,
    FormErrorDirective,
    LoaderComponent,
    NgIf,
    FrontBackDirective
  ],
  templateUrl: './step5.component.html',
  styleUrl: './step5.component.css'
})
export class Step5Component{

  step5Form: FormGroup;
  loading: boolean = false;

  step5?: Step5;

  private destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next(); // Emite un valor para completar las suscripciones
    this.destroy$.complete(); // Finaliza el Subject
  }

  constructor(private bookFormService: BookFormService) {
    this.bookFormService.reservationForm$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        if (data.steps.step5) {
          this.step5 = data.steps.step5;
        }
      }
    })

    this.step5Form = new FormGroup({
      name: new FormControl(''),
      email: new FormControl('',[Validators.email]),
      phone_number: new FormControl('',[Validators.required,Validators.maxLength(9),Validators.minLength(9)])
    });

    this.step5Form.patchValue({
      name: this.step5?.name,
      email: this.step5?.email,
      phone_number: this.step5?.phone_number,
    })
  }

  goBack(){
    this.bookFormService.setCurrentStep(4);
  }

  next(){
    let step5:Step5 = {
      name:this.step5Form.get('name')?.value,
      phone_number:this.step5Form.get('phone_number')?.value,
      email:this.step5Form.get('email')?.value,
    }
    this.bookFormService.setStep5(step5);
    // console.log(step5);
    /*this.loading=true;
    let step=5;
    this.store.dispatch(updateStep({step}));

    let step5:Step5 = {
      name:this.step5Form.get('name')?.value,
      phone_number:this.step5Form.get('phone_number')?.value,
      email:this.step5Form.get('email')?.value,
    }
    this.store.dispatch(updateStep5({
      step5
    }))
    if(this.selectedStation){
      if(this.selectedStation.payment_methods.length>1){
        this.nextStep();
      } else {
        this.selectedPayment=this.selectedStation.payment_methods[0];
        let step6:Step6 = {
          payment:this.selectedPayment,
        }
        this.store.dispatch(updateStep6({step6}))
        this.confirm()
        let step=7;
        this.store.dispatch(updateStep({step}))
      }
    }

    this.loading=false;*/
  }


}
