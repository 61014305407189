<div class="w-full text-red2 font-bold mb-2" *ngIf="stolen">
  <p>ESTA MATRÍCULA ESTÁ EN LA LISTA DE ALERTAS</p>
</div>

<app-existing-reservation-card [admin]="true" [editReservation]="false" [phoneConfirm]="existingReservation.phone" (onUpdateReservation)="existingReservation = $event" (onCancelReservation)="existingReservation=$event" *ngIf="existingReservation" [existingReservation]="existingReservation"
                               (onReturnClick)="returnRegistration()" (onEditReservation)="editReservation=$event">
</app-existing-reservation-card>
<!--<button class="mx-auto" *ngIf="existingReservation && !editReservation" (click)="next()" appNextButton>
  Continuar con la reserva
  <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>

</button>-->
<app-old-reservation *ngIf="oldReservation" (onClearOldReservation)="clearOldReservation($event)" [oldReservation]="oldReservation" (onReturnClick)="returnRegistration()"></app-old-reservation>

<form [formGroup]="step1Form" *ngIf="!existingReservation && !oldReservation">
  <div *ngIf="professional">
    <div *ngIf="!loading" class="flex flex-col gap-y-2 animate-fade-in" >
      <!--<label class="text-darkGray">Tipo de inspección</label>-->
      <select id appFormInputBlueNoFull [(ngModel)]="selectedUser" (change)="onSelectUser()" [ngModelOptions]="{standalone: true}">
        <option disabled [ngValue]="undefined">Selecciona un profesional</option>
        <option *ngFor="let user of users" [ngValue]="user" [selected]="selectedUser?.id===user.id ? user : ''"> {{user.name}} </option>
      </select>
    </div>
  </div>

  <div *ngIf="!professional || selectedUser" [class.mt-4]="selectedUser" class="flex flex-row gap-x-6 items-start justify-center w-full">
    <div class="flex flex-col w-2/3">
      <input formControlName="registration_number" type="text" placeholder="0000AAA"
             class="w-full rounded-xl border-2 border-lightGray2">
      <div class="my-1"
           *ngIf="step1Form.get('registration_number')?.invalid && step1Form.get('registration_number')?.touched"
           appFormError> Matrícula no valida
      </div>
      <div class="my-1" *ngIf="error" appFormError> {{ error }}</div>
    </div>
    <div class="w-1/3">
      <button appNextOutlined (click)="onAddRegistrationNumber()" type="button" *ngIf="currentStep == 1">
        <p>Continuar</p>
        <svg *ngIf="!loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M5 12l14 0"/>
          <path d="M15 16l4 -4"/>
          <path d="M15 8l4 4"/>
        </svg>
        <app-loader size="extra-small" *ngIf="loading"></app-loader>
      </button>
      <button appNextOutlined (click)="updateRegistrationNumber()" type="button" *ngIf="currentStep > 1">
        <p>Cambiar</p>
        <svg *ngIf="!loading" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M5 12l14 0"/>
          <path d="M15 16l4 -4"/>
          <path d="M15 8l4 4"/>
        </svg>
        <app-loader size="extra-small" *ngIf="loading"></app-loader>
      </button>
    </div>

  </div>
</form>


