<div class="w-full mx-auto p-4 animate-fade-in" *ngIf="!confirmed">
  <h1 class="text-2xl text-center text-darkGray">Confirma tu cita</h1>
  <p class="text-sm text-center mb-6 text-darkGray">¡Revisa todos tus datos! Y confirma tu reserva</p>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-x-4 mt-6">
    <div class="col-span-2 md:col-span-1">
      <div class="border-b border-gray-300 text-primary text-start py-2">Vehículo</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p >{{ step1?.registration_number }}</p>
        <p >{{ step2?.service?.name }}</p>
        <p >{{ step2?.vehicle?.name }}</p>
        <p >{{ step2?.fuel?.name }}</p>
      </div>
    </div>

    <div>
      <div class="border-b border-gray-300 text-primary text-start py-2">Centro ITV</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step3?.state?.name }}</p>
        <p>{{ step3?.station?.name }}</p>
        <a *ngIf="step3?.station?.maps_link" href="{{step3?.station?.maps_link}}" target="_blank" class="underline text-primary mt-2 cursor-pointer"> Google maps</a>
      </div>
    </div>

    <div>
      <div class="border-b border-gray-300 text-primary text-start py-2">Fecha de la cita</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step4?.date | date:'dd/MM/yyyy' }}</p>
        <p>{{ step4?.hour?.hour }}h</p>
      </div>
    </div>


    <div class="col-span-2 md:col-span-1">
      <div class="border-b border-gray-300 text-primary text-start py-2">Cliente</div>
      <div class="flex flex-col text-sm text-start gap-y-1 pt-2 text-darkGray">
        <p>{{ step5?.phone_number }}</p>
        <p>{{ step5?.email }}</p>
      </div>
    </div>

    <div class="col-span-2">
      <div class="border-b border-gray-300 text-primary text-start py-2 col-span-2">Pago</div>

      <div class="col-span-2 grid grid-cols-4 text-sm pt-2 text-darkGray">
        <p class="text-start" *ngIf="price">{{price | currency:'EUR'}}</p>
        <p class="text-start col-span-3">{{step6?.payment?.front_name}}</p>
      </div>
    </div>



  </div>
  <div class="w-full mt-6 bg-lightGray2 text-darkGray p-4 text-xs" *ngIf="step3?.station?.information || step3?.station?.limitations">
    <p>{{step3?.station?.information}}</p>
    <p class="mt-2">{{step3?.station?.limitations}}</p>
  </div>
  <div *ngIf="!isOperator" class="w-full flex gap-2 items-center text-primary mt-2">

    <input [(ngModel)]="accepted" type="checkbox" />
    <p (click)="showDialog()">He leído y acepto la política legal y de protección de datos *</p>
    <p-dialog
      header="Política Legal y Protección de datos"
      [modal]="true"
      [(visible)]="visible"
      [style]="{ width: '50rem' }"
      [breakpoints]=""
      [maximizable]="true">
      <div class="text-justify flex flex-col gap-2">
        <p>Todos los datos de carácter personal que se solicitan en la página web, son recabados para la correcta gestión de las actividades de <b>ESTACIÓN ITV VEGA BAJA S.A.</b>: gestión contable, fiscal, administrativa, comercial y envío de publicidad por medios electrónicos.</p>
        <p>En ningún caso serán utilizados para fines distintos, ni pueden ser cedidos a terceros relacionados con la empresa para cumplir con los fines anteriores. <b>ESTACIÓN ITV VEGA BAJA S.A.</b> está claramente posicionada en contra del “spam”, por lo que en ningún caso utilizará los datos personales para el envío de comunicaciones comerciales no solicitadas sin su autorización.</p>
        <p>Los datos de carácter personal que se solicitan en los distintos formularios disponibles en la página web, son incorporados a los ficheros de tratamiento de datos que <b>ESTACIÓN ITV VEGA BAJA S.A.</b> tiene convenientemente inscritos en la Agencia Española de Protección de Datos.</p>
        <p>Puede consultar la finalidad de la recogida de los datos, así como el número de inscripción de los ficheros, en las páginas en las que figuran dichos cuestionarios.</p>
        <p>Esta página web utiliza tecnología “cookies” por utilizar el servicio Google Analytics, prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”).</p>
        <p>Google Analytics utiliza “cookies”, que son archivos de texto ubicados en su ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web.</p>
        <p>La información que genera la cookie acerca de su uso del website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.</p>
        <p>Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet.</p>
        <p>Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google.</p>
        <p>Usted puede rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, debe saber que si lo hace puede ser que no pueda usar la plena funcionabilidad de este website.</p>
        <p>Al utilizar este website Ud. consiente el tratamiento de información acerca de Ud. por Google en la forma y para los fines arriba indicados.</p>
        <p>El departamento Comercial y Administrativo es el órgano responsable del tratamiento de los datos dentro de <b>ESTACIÓN ITV VEGA BAJA S.A.</b>. </p>
        <p>Los derechos de acceso, rectificación, cancelación y oposición reconocidos en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos Personales y en su normativa de desarrollo, deberán ejercerse dirigiendo un escrito y su DNI al departamento de atención al cliente de <b>ESTACIÓN ITV VEGA BAJA S.A.</b> mediante correo postal indicando “Protección de Datos” a Ctra. N340 km 691,5 Redován (Alicante) email (itvredovan&#64;itevebasa.com) teléfono 966 75 44 97.</p>
      </div>
    </p-dialog>

  </div>
  <div *ngIf="error != ''" class="text-left mt-1">
    <span class="text-red2">{{error}}</span>
  </div>
  <div *ngIf="errorConfirm" class="text-left mt-2 p-2 bg-red-100">
    <span class="text-red2 text-sm">Esta reserva ya no se encuentra disponible. Disculpe las molestias.</span>
  </div>
  <div class="flex flex-row justify-between mt-4">
    <button  appFrontBack (click)="backStep()">
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
      <span>Atrás</span>
    </button>
    <button (click)="confirm()" [disabled]="loading" class="flex gap-4 items-center w-fit font-bold py-2 px-10 rounded-full bg-yellow text-primary hover:shadow-md duration-300 max-h-12">
      <a >CONFIRMAR</a> <app-loader *ngIf="loading" size="extra-small"></app-loader>
    </button>
  </div>
</div>

<div class="min-h-screen flex flex-col" *ngIf="confirmed">
  <!--<app-stepper [formPassed]="true"></app-stepper>-->
  <app-confirm-card (adminEmitter)="adminEmitter()" [admin]="admin" [isProfessional]="isProfessional" *ngIf="existingReservation" [existingReservation]="existingReservation"
                    (resetWithoutRegistrationClick)="reset()" (professionalOtherReservation)="professionalOtherReservation()" [showResetWithoutRegistration]="true"
  ></app-confirm-card>

</div>
