import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Paginator} from "../../../../models/Paginator";
import {Vehicle} from "../../../../models/Vehicle";
import {Alert} from "../../../../models/Alert";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {SortColumn} from "../../../../models/Table/SortColumn";
import {Filter} from "../../../../models/Table/Filter";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {TableActions} from "../../../../models/Table/TableActions";
import {VehiclesService} from "../../../../core/services/vehicles/vehicles.service";
import {ToastrService} from "ngx-toastr";
import {NgxSpinner, NgxSpinnerService} from "ngx-spinner";
import {IntentsComponent} from "../intents/intents.component";

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [
    GenericTableComponent,
    TableFiltersComponent,
    TableHeaderWrapperComponent,
    IntentsComponent
  ],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.css'
})
export class AlertsComponent implements OnInit {
  loading:boolean=false;
  alerts:Paginator<Alert>| null = null;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  tableHeaders: TableHeader[] = [
    /*{
      name: 'ID',
      active: true,
      td_type: 'text',
      td_key: 'id',
      sort: true,
    },*/
    {
      name: 'MATRÍCULA',
      active: true,
      td_type: 'text',
      td_key: 'registration_number',
      sort: true,
    },
  ]
  sorts: SortColumn[] = [];

  tableFilters: Filter[] = [
    {
      key: 'registration_number',
      type: 'search',
      operator: 'like',
      placeholder: 'Buscar...'
    }
  ]
  perPage: number = 50;
  loadingList = false;
  loadingFilters = false;

  displayNames: { [key: string]: string } = {
    registration_number: 'Matrícula',
  };
  filters: ActiveFilter[] = [];

  tableActions: TableActions[] = [
    {
      icon: '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-6"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>',
      name: 'Eliminar',
      key: 'delete',
      color: '#ff3333',
      confirm: true,
      confirmTitle: 'Eliminar matrícula',
      confirmSubTitle: '¿Estás seguro de que deseas eliminar esta matrícula?',
    }
    /*{
      icon: '<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"   class="w-8"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>',
      name: 'Editar',
      key: 'update',
      color: '#ff9900',
      confirm: false,
    }*/
  ];

  @ViewChild('genericTableComponent') genericTableComponent!: GenericTableComponent<Vehicle>;

  constructor(private vehicleService: VehiclesService, private toastr: ToastrService, private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.loading = true;
    this.loadAlerts();
  }



  onChangeSorts(sorts: SortColumn[]): void {
    this.sorts = sorts;
    this.loadAlerts();
  }

  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    this.loadAlerts();
  }

  loadAlerts(){
    this.vehicleService.listPaginateAlerts(this.sorts, this.filters, this.perPage).subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.alerts = response.data;
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  removeAlertFromPaginator(alertId: number): void {
    if (this.alerts && this.alerts.data) {
      this.alerts.data = this.alerts.data.filter(alert => alert.id !== alertId);
    }
  }

  onClickAction(data: {action: TableActions, item: Vehicle, index: number}) {
    if(data.action.key === 'delete'){
      this.vehicleService.deleteAlert(data.item.id).subscribe({
        next:(response) => {
          if(response.success){
            this.removeAlertFromPaginator(data.item.id);
            this.toastr.success(response.message)
          } else {
            this.toastr.error(response.message);
          }
        },
        error: (err)=>{
          this.toastr.error(err.error.message);
        }
      });
    }
    console.log(data);
  }

  bulkActions = [
    { label: 'Importar', key: 'import' },
    { label: 'Exportar', key: 'export' },
  ];

  bulkActionClick(key: string) {
    if (key === 'import') {
      this.fileInput.nativeElement.click();
    }
    if (key === 'export') {
      this.spinner.show();
      this.vehicleService.exportAlerts().subscribe({
        next: (response: Blob) => {
          if (response) {
            // Crear un enlace temporal para la descarga
            const url = window.URL.createObjectURL(response);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'lista_negra_matriculas.csv';
            document.body.appendChild(a);
            a.click();

            // Limpiar
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }
          this.spinner.hide();
        }, error: err => {
          this.spinner.hide();
          console.log(err);

        }
      });
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    // Validar el tipo de archivo
    const allowedExtensions = ['csv', 'xls', 'xlsx'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      this.toastr.error('Por favor, selecciona un archivo CSV o Excel válido.');
      return;
    }

    // Crear FormData para enviar el archivo
    const formData = new FormData();
    formData.append('excel_file', file);

    // Mostrar un loader o indicador de carga
    this.loadingList = true; // Puedes usar otro flag si prefieres

    // Enviar el archivo al backend usando UserService
    this.vehicleService.importAlerts(formData).subscribe({
      next: (response) => {
        this.toastr.success(response.message);
        // Opcional: Recargar la lista de usuarios
        this.loadAlerts();
        this.loadingList = false;
      },
      error: (err) => {
        this.toastr.error(err.error.message || 'Error al importar matrículas.');
        this.loadingList = false;
      }
    });

    // Reiniciar el valor del input para permitir subir el mismo archivo nuevamente si es necesario
    input.value = '';
  }



}
