import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  FormInputBlueNoFullDirective
} from "../../../../../../shared/directives/forms/form-input-blue-no-full.directive";
import {MonthCalendarComponent} from "../../../../../../shared/components/month-calendar/month-calendar.component";
import {DatePipe, NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {Observable} from "rxjs";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../../../models/Front/ReservationForm";
import {select, Store} from "@ngrx/store";
import {
  selectCurrentStep,
  selectStep1,
  selectStep2,
  selectStep3,
  selectStep4, selectStep5, selectStep6
} from "../../../../../../store/reservationForm/stepper.selector";
import {
  updateStep,
  updateStep1,
  updateStep2,
  updateStep3, updateStep4, updateStep5, updateStep6
} from "../../../../../../store/reservationForm/stepper.actions";
import {FrontService} from "../../../../../../core/services/front/front.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {environment} from "../../../../../../../environments/environment";
import {OldReservation, Reservation} from "../../../../../../models/Reservation";
import {Service} from "../../../../../../models/Service";
import {Vehicle} from "../../../../../../models/Vehicle";
import {Fuel} from "../../../../../../models/Fuel";
import {State} from "../../../../../../models/State";
import {Station} from "../../../../../../models/Station";
import {AvailableHour} from "../../../../../../models/AvailableHours";
import {NextButtonDirective} from "../../../../../../shared/directives/buttons/next.directive";
import {NextOutlinedButtonDirective} from "../../../../../../shared/directives/buttons/next-outlined.directive";
import {FormErrorDirective} from "../../../../../../shared/directives/forms/form-error.directive";
import {FrontBackDirective} from "../../../../../../shared/directives/buttons/front.back.directive";
import {NextSquareButtonDirective} from "../../../../../../shared/directives/buttons/next-square.directive";
import {LoaderComponent} from "../../../../../../shared/components/loader/loader.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  FormInputOutlinedDirective,
} from "../../../../../../shared/directives/forms/form-input-outlined.directive";
import {
  SecundaryNextOutlinedButtonDirective
} from "../../../../../../shared/directives/buttons/secundary-next-outlined.directive";
import {CardShadowComponent} from "../../../../../front/components/utils/card-shadow/card-shadow.component";
import {OutlineButtonDirective} from "../../../../../../shared/directives/outline/outline-button.directive";
import {OutlineInputDirective} from "../../../../../../shared/directives/outline/outline-input.directive";
import {NextOutlinedRedButtonDirective} from "../../../../../../shared/directives/buttons/next-outlined-red.directive";
import {FormSelectSquareDirective} from "../../../../../../shared/directives/forms/form-select-square.directive";
import {PaymentMethod} from "../../../../../../models/PaymentMethod";
import {
    ExistingReservationCardComponent
} from "../../../../../front/components/existing-reservation-card/existing-reservation-card.component";
import {Step7Component} from "../../../../../front/components/form/step7/step7.component";
import {ConfirmCardComponent} from "../../../../../front/components/confirm-card/confirm-card.component";
import {StationService} from "../../../../../../core/services/stations/station.service";
import {data} from "autoprefixer";
import {OldReservationComponent} from "../../../../../front/components/old-reservation/old-reservation.component";

@Component({
  selector: 'app-appointment-form',
  standalone: true,
  imports: [
    FormInputBlueNoFullDirective,
    MonthCalendarComponent,
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    FormsModule,
    NextButtonDirective,
    NextButtonDirective,
    NextOutlinedButtonDirective,
    FormErrorDirective,
    FrontBackDirective,
    NextSquareButtonDirective,
    LoaderComponent,
    DatePipe,
    FormInputOutlinedDirective,
    SecundaryNextOutlinedButtonDirective,
    CardShadowComponent,
    NgTemplateOutlet,
    OutlineButtonDirective,
    OutlineInputDirective,
    NextOutlinedRedButtonDirective,
    FormSelectSquareDirective,
    ExistingReservationCardComponent,
    Step7Component,
    ConfirmCardComponent,
    NgClass,
    OldReservationComponent
  ],
  templateUrl: './appointment-form.component.html',
  styleUrl: './appointment-form.component.css'
})
export class AppointmentFormComponent implements OnInit {
  existingReservation?: Reservation;
  existingReservationCreate?: Reservation;
  oldReservation?: OldReservation;
  editReservation:boolean = false;
  exitingReservationAlert: boolean = false;
  existingOldReservationAlert:boolean = false;
  step1Form: FormGroup;
  step5Form: FormGroup;

  services: Service[] = [];
  selectedService?: Service;

  vehicles: Vehicle [] = [] ;
  selectedVehicle?:Vehicle ;

  fuels?:Fuel[];
  selectedFuel?:Fuel;

  states?:State[];
  selectedState?:State;

  stations?:Station[];
  selectedStation?:Station;

  // stationsInfo?:Station[];
  loadStationInfo: boolean = false;

  @Output() stationsInforEmit = new EventEmitter<Station[]>();
  @Output() stationsInforLoading = new EventEmitter<boolean>();

  loadingHours: boolean = false;
  loadingDates: boolean = false;

  blockedDates: string[] = [];

  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();

  selectedDate: string|undefined;
  selectedHour: AvailableHour|undefined;

  selectedPayment:PaymentMethod | undefined;

  hours: AvailableHour[] = [];

  currentStep$: Observable<number>;
  currentStep: number = 1;
  step1$: Observable<Step1 | undefined>;
  step1?: Step1;
  error: string|null = null;
  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  step3$: Observable<Step3 | undefined>;
  step3?: Step3;
  step4$: Observable<Step4 | undefined>;
  step4?: Step4;
  step5$: Observable<Step5 | undefined>;
  step5?: Step5;
  step6$: Observable<Step6 | undefined>;
  step6?: Step6;
  loading = false;
  loadingStep1=false;

  constructor(private store: Store, private cd: ChangeDetectorRef, private frontService: FrontService, private stationService: StationService) {
    this.currentStep$ = this.store.pipe(select(selectCurrentStep));
    this.step1$ = this.store.pipe(select(selectStep1));
    this.step2$ = this.store.pipe(select(selectStep2));
    this.step3$ = this.store.pipe(select(selectStep3));
    this.step4$ = this.store.pipe(select(selectStep4));
    this.step5$ = this.store.pipe(select(selectStep5));
    this.step6$ = this.store.pipe(select(selectStep6));

    this.step1Form = new FormGroup({
      registration_number: new FormControl('', [Validators.required/*, Validators.maxLength(7), Validators.minLength(7)*/])
    });

    this.step5Form = new FormGroup({
      name: new FormControl(''),
      email: new FormControl('',[Validators.email]),
      phone_number: new FormControl('',[Validators.required,Validators.maxLength(9),Validators.minLength(9)])
    });

    this.currentStep$.subscribe(step => {
      this.currentStep = step;
    });
    this.step1$.subscribe(step1 => {
      this.step1 = step1;
    });
    this.step2$.subscribe(step2 => {
      this.step2 = step2;
    });
    this.step3$.subscribe(step3 => {
      this.step3 = step3;
    });
    this.step4$.subscribe(step4 => {
      this.step4 = step4;
    });
    this.step5$.subscribe(step5 => {
      this.step5 = step5;
    })
    this.step6$.subscribe(step6 => {
      this.step6 = step6;
    })
  }


  ngOnInit() {
    /*this.currentStep=1;
    let step = this.currentStep;
    this.store.dispatch(updateStep({step}));
    */

    if(this.step1) {
      this.step1Form.patchValue({
        registration_number: this.step1.registration_number,
      })
      this.step5Form.patchValue({
        name: '',
        email: this.step5?.email,
        phone_number: this.step5?.phone_number
      })
    }
      /*
      if(this.step2?.service){
        this.currentStep=2;
        let step = this.currentStep;
        this.store.dispatch(updateStep({step}));
        this.selectedService = this.step2.service;
        this.loadServices()
        if(this.step2.vehicle){
          this.selectedVehicle=this.step2.vehicle;
          //this.onSelectVehicle();

          if(this.step2.fuel){
            this.selectedFuel=this.step2.fuel;
            //this.onSelectFuel()
          }
        }
      }
    }*/
  }

  public nextStep() {
    if (this.currentStep === 1 && this.step1?.registration_number) {
      //this.showSidebar=true;
      this.currentStep=2;
      let step = this.currentStep;
      this.store.dispatch(updateStep({step}));

    } else if (this.currentStep === 2 && this.step2?.service && this.step2.vehicle && this.step2.fuel) {
      this.currentStep++;
      let step = this.currentStep;
      this.store.dispatch(updateStep({step}));
    } else if (this.currentStep === 3 && this.step3?.state && this.step3?.station) {
      this.currentStep++
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }));
    } else if (this.currentStep === 4 && this.step4?.date && this.step4?.hour) {
      this.loading=true;
      this.currentStep++
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }));
      this.loading=false
    } else if (this.currentStep === 5 && this.step5?.phone_number) {
      this.currentStep++;
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }))
    } else if (this.currentStep === 6 && this.step6?.payment) {
      this.currentStep++;
      let step = this.currentStep;
      this.store.dispatch(updateStep({ step }))
    }
  }

  loadServices(){
    this.frontService.listServices().subscribe(
      {
        next: (response) => {
          if (response.data) {
            this.services = response.data;
            if(this.step2 && this.step2.service){
              this.selectedService=this.services.find(s => s.id === this.step2?.service?.id);
              if(this.selectedService){
                this.loadVehicles(this.selectedService)
              }
            }
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  loadVehicles(service: Service) {
    this.loading = true;
    this.frontService.listVehicles(service.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.vehicles=response.data;
            if(this.step2 && this.step2.vehicle){
              this.selectedVehicle = this.vehicles.find(v => v.id === this.step2?.vehicle?.id)
              if(this.selectedVehicle && this.selectedService){
                this.loadFuels(this.selectedVehicle, this.selectedService)
              }
            }
          }
          this.loading = false;
        },
        error: err => {
          console.log(err);
          this.loading = false;
        }
      }
    );
  }

  loadFuels(vehicle:Vehicle, service: Service){
    this.loading = true;
    this.frontService.listFuels(service.id,vehicle.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.fuels=response.data;
            if(this.step2 && this.step2.fuel){
              this.selectedFuel = this.fuels.find(f => f.id === this.step2?.fuel?.id)
              if(this.selectedFuel && this.selectedVehicle && this.selectedService){
                this.loadStates(this.selectedService,this.selectedVehicle,this.selectedFuel)
              }
            }
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  loadStates(service:Service,vehicle:Vehicle,fuel:Fuel){

    this.frontService.listStates(service.id,vehicle.id,fuel.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.states = response.data;
            if(this.step3 && this.step3.state){
              this.selectedState = this.states.find(s => s.id === this.step3?.state?.id)
              if(this.selectedState && this.selectedFuel && this.selectedVehicle && this.selectedService){
                this.loadStations(this.selectedService,this.selectedVehicle,this.selectedFuel, this.selectedState)
              }
            }
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
        }
      }
    );
  }

  loadStations(service:Service,vehicle:Vehicle,fuel:Fuel,state:State){
    this.frontService.listStations(service.id, vehicle.id, fuel.id, state.id).subscribe(
      {
        next: (response) => {
          if(response.data){
            this.stations = response.data;
            if(this.step3 && this.step3.station){
              this.selectedStation = this.stations.find(s => s.id === this.step3?.station?.id)
              if(this.selectedStation && this.selectedState && this.selectedFuel && this.selectedVehicle && this.selectedService){
                this.loadDates()
              }
            }
          }
          this.loading = false;
        },
        error: err => {
          console.log(err);
          this.loading = false;
        }
      }
    );
  }

  loadDates(){
    if (this.step2 && this.step3) {
      this.listAvailableDates(this.month, this.year, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station);
    }
  }



  listAvailableDates(month: number, year: number, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined){
    this.loadingDates = true;
    this.blockedDates = [];
    this.frontService.listAvailableDates(month, year, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data) {
          response.data.forEach(item => {
            if (item.available == 0) {
              this.blockedDates.push(item.date);
              if(this.step4 && this.step4.date && this.step4.hour){
                this.onSelectedDay(this.step4.date);
                if(this.selectedDate){
                  this.year = new Date(this.selectedDate).getFullYear();
                  this.month = new Date(this.selectedDate).getMonth() + 1;
                }
              }
            }
            this.loadingDates = false;
          });
        }
      },
      error: err => {
        this.loadingDates = false;
      }
    })
  }

  listAvailableHours(date: string, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined) {
    this.loadingHours = true;
    this.frontService.listAvailableHours(date, service!.id, vehicle!.id, fuel!.id, station!.id).subscribe({
      next: (response) => {
        if (response.data) {
          this.hours = response.data.hours;
          if(this.step4 && this.step4.hour){
            this.selectedHour = this.hours.find(h => h.id === this.step4?.hour?.id);
            //this.onSelectHour()
          }
        }
        this.loadingHours = false;
      }, error: err => {
        this.loadingHours = false;
      }
    })
  }

  nextMonth() {
    // Incrementa el mes actual
    this.month++;

    // Verifica si el mes excede diciembre
    if (this.month > 12) {
      this.month = 1;  // Reinicia el mes a enero
      this.year++;     // Incrementa el año
    }

    this.loadDates();
  }

  /**
   * Retrocede al mes anterior si no se baja del mes 1.
   */
  prevMonth() {
    // Decrementa el mes actual
    this.month--;

    // Verifica si el mes es menor a enero
    if (this.month < 1) {
      this.month = 12; // Ajusta el mes a diciembre
      this.year--;     // Decrementa el año
    }

    this.loadDates();
  }

  public returnRegistration(){
    this.existingReservation=undefined;
    this.oldReservation=undefined;
    this.exitingReservationAlert = false;
  }

  onAddRegistrationNumber() {
    this.error = null;
    if(this.step1Form.valid){
      let data:any = {
        registration_number: this.step1Form.get('registration_number')?.value,
      }
      this.frontService.checkReservation(data).subscribe(
        {
          next: (response) => {
            this.loadingStep1=true;

            this.resetWithoutRegistration()

            if (response.data) {
              // Verificar si es una OldReservation por la existencia de 'expiration' y ausencia de 'id'
              if ('expiration' in response.data && !('id' in response.data)) {
                this.oldReservation = response.data as OldReservation;
                this.existingOldReservationAlert = true;
              }

              // Verificar si es una Reservation por la existencia de las propiedades clave como 'id'
              if ('id' in response.data) {
                this.existingReservation = response.data as Reservation;
                console.log('hola')
                this.exitingReservationAlert = true;
              }

              const step1: Step1 = this.step1Form.value;
              this.store.dispatch(updateStep1({ step1 }));
            } else {
              let step1:Step1 = this.step1Form.value;
              this.store.dispatch(updateStep1({
                step1
              }));
              this.nextStep()
              this.loadServices()
            }
            this.loadingStep1 = false;
          },
          error: error => {
            this.error = error.error.message;
            this.loadingStep1 = false;
          }
        }
      );
    }
  }

  onSelectService() {
    let step=2;
    this.store.dispatch(updateStep({step}));
    this.selectedVehicle = undefined;
    this.selectedFuel = undefined;
    this.resetStep3();
    this.resetStep4();
    this.resetStep5();
    if (this.selectedService){
      this.loadVehicles(this.selectedService);

      const step2: Step2 = {
        subStep: 2,
        service: this.selectedService,
      };
      this.store.dispatch(updateStep2({step2}));

    } else {
      const step2: Step2 = {
        subStep: 1,
      };
      this.store.dispatch(updateStep2({step2}));
    }
  }

  onSelectVehicle() {
    let step=2;
    this.store.dispatch(updateStep({step}));
    this.selectedFuel = undefined;
    this.resetStep3();
    this.resetStep4();
    this.resetStep5();
    if(this.selectedService && this.selectedVehicle){
      this.loadFuels(this.selectedVehicle,this.selectedService)

      const step2: Step2 = {
        subStep: 3,
        service: this.selectedService,
        vehicle:this.selectedVehicle,
      };
      this.store.dispatch(updateStep2({step2}));

    } else {
      const step2: Step2 = {
        subStep: 2,
      };
      this.store.dispatch(updateStep2({step2}));
    }
  }

  onSelectFuel(){
    let step=2;
    this.store.dispatch(updateStep({step}));
    this.resetStep3();
    this.resetStep4();
    this.resetStep5();
    if(this.selectedService && this.selectedVehicle && this.selectedFuel){
        const step2: Step2 = {
          subStep: 3,
          service:this.selectedService,
          vehicle:this.selectedVehicle,
          fuel:this.selectedFuel,
        };
        this.store.dispatch(updateStep2({step2}));

      this.selectedState=undefined;
      this.selectedStation=undefined;
      const step3: Step3 = {
        subStep: 1,
      };
      this.store.dispatch(updateStep3({step3}));

        this.nextStep();
        this.loadStates(this.selectedService,this.selectedVehicle,this.selectedFuel)

      } else {
      let step = 2;
      this.store.dispatch(updateStep({step}));
    }

  }

  onSelectState()
  {
    let step=3;
    this.store.dispatch(updateStep({step}));
    if(this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState){
      this.loadStations(this.selectedService, this.selectedVehicle, this.selectedFuel, this.selectedState)

      const step3: Step3 = {
        subStep: 2,
      };
      this.store.dispatch(updateStep3({step3}));

      this.loadStationWeekInf();

    } else {
      const step3: Step3 = {
        subStep: 1,
      };
      this.store.dispatch(updateStep3({step3}));
    }
  }

  loadStationWeekInf(){
    this.stationsInforLoading.emit(true);
    if(this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState){
      this.stationService.listAvailability(this.selectedService.id, this.selectedVehicle.id, this.selectedFuel.id, this.selectedState.id).subscribe({
        next: value => {
          if (value.data) {
            this.stationsInforEmit.emit(value.data);
          }
          this.stationsInforLoading.emit(false);
        },
        error: (err) => {
          this.stationsInforLoading.emit(false);
        }
      })
    }

  }

  onSelectStation(){
    let step=3;
    this.store.dispatch(updateStep({step}));
    if(this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation){
      const step3: Step3 = {
        subStep: 2,
        state:this.selectedState,
        station:this.selectedStation,

      };
      this.store.dispatch(updateStep3({step3}));
      this.selectedDate=undefined;
      this.selectedHour=undefined;
      const step4:Step4 = {
      }
      this.store.dispatch(updateStep4({step4}));
      this.nextStep();
      this.loadDates()
    }
  }

  onSelectedDay(date: string){
    let step = 4
    this.store.dispatch(updateStep({step}));

    this.selectedDate = date;
    this.selectedHour = undefined;

    if (this.step2 && this.step3) {
      this.listAvailableHours(this.selectedDate, this.selectedService, this.selectedVehicle, this.selectedFuel, this.selectedStation);
    }

  }

  onSelectHour(){
    if(this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation && this.selectedHour){
      const step4:Step4 = {
        hour:this.selectedHour,
        date:this.selectedDate,
      }
      this.store.dispatch(updateStep4({step4}));
    }
  }

  onAddInfo(){
    this.loading=true;
    let step=5;
    this.store.dispatch(updateStep({step}));

    let step5:Step5 = {
      name:this.step5Form.get('name')?.value,
      phone_number:this.step5Form.get('phone_number')?.value,
      email:this.step5Form.get('email')?.value,
    }
    this.store.dispatch(updateStep5({
      step5
    }))
    if(this.selectedStation){
      if(this.selectedStation.payment_methods.length>1){
        this.nextStep();
      } else {
        this.selectedPayment=this.selectedStation.payment_methods[0];
        let step6:Step6 = {
          payment:this.selectedPayment,
        }
        this.store.dispatch(updateStep6({step6}))
        this.confirm()
        let step=7;
        this.store.dispatch(updateStep({step}))
      }
    }

    this.loading=false;
  }

  onSelectPayment(){
    let step = 6;
    this.store.dispatch(updateStep({step}));

    let step6:Step6 = {
      payment:this.selectedPayment
    }
    this.store.dispatch(updateStep6({
      step6
    }))

  }

  goBack(){
    if(this.currentStep===5){
      let step:number=4
      this.store.dispatch(updateStep({step}));
    } else if (this.currentStep===6){
      let step:number=5
      this.store.dispatch(updateStep({step}));
    }
  }

  clearOldReservation(key:string){
      this.loadServices();
    this.oldReservation=undefined;
  }

  public confirm(){
    let step = 6;
    this.store.dispatch(updateStep({step}));
    if(this.step1 && this.step2 && this.step3 && this.step4 && this.step5 && this.step6){
      this.loading = true;
      let data:any = {
        hour_id:this.step4.hour?.id,
        station_id:this.step3.station?.id,
        service_id:this.step2.service?.id,
        vehicle_id:this.step2.vehicle?.id,
        fuel_id:this.step2.fuel?.id,
        payment_method_id: this.step6.payment?.id,
        registration_number:this.step1.registration_number,
        date:this.step4.date,
        phone:this.step5.phone_number,
      };
      this.frontService.createReservation(data).subscribe({
        next: (response) => {
          this.existingReservationCreate = response.data;
          this.loading = false;
          this.nextStep()
        },
        error: (error) => {
          this.loading = false;
          console.log(error.error.message);
        }
      })
    }
  }

  public reset(){

    let step = 1;
    this.store.dispatch(updateStep({step}));

    this.resetStep1();

    this.resetWithoutRegistration()
  }

  public resetWithoutRegistration(){
    let step = 1;
    this.store.dispatch(updateStep({step}));

    this.resetStep2();

    this.resetStep3();

    this.resetStep4();

    this.resetStep5();

    this.resetStep6();
  }

  public resetStep1(){
    let step1:Step1 = {
      registration_number:'',
    }

    this.step1Form.patchValue(step1);
    this.step1Form.markAsUntouched();
    this.store.dispatch(updateStep1({step1}))
  }

  public resetStep2(){
    this.selectedService=undefined;
    this.selectedVehicle=undefined;
    this.selectedFuel=undefined;

    let step2: Step2 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep2({step2}));

  }

  public resetStep3(){
    this.selectedState=undefined;
    this.selectedStation=undefined;
    let step3: Step3 = {
      subStep: 1,
    };
    this.store.dispatch(updateStep3({step3}));
  }

  public resetStep4(){
    this.selectedDate=undefined;
    this.selectedHour=undefined;
    let step4:Step4={
      date:undefined,
      hour:undefined,
    }
    this.store.dispatch(updateStep4({
      step4
    }))
  }

  public resetStep5(){
    let step5:Step5={
      phone_number: '',
      email: '',
      name: '',
    }
    this.step5Form.patchValue(step5);
    this.step5Form.markAsUntouched();
    this.store.dispatch(updateStep5({
      step5
    }))
  }

  public resetStep6(){
    let step6:Step6={
      payment:undefined,
    }
    this.store.dispatch(updateStep6({
      step6
    }))
  }

  public forceWithReservationAlert(){
    let step1:Step1 = this.step1Form.value;
    this.store.dispatch(updateStep1({
      step1
    }));
    this.existingReservation=undefined;
    this.exitingReservationAlert=false;
    this.nextStep()
    this.loadServices()
  }


}
