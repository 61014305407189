<div class="w-full">
  <div class="flex gap-8 items-center animate-fade-in">

    <ng-container *ngIf="stationForm">
     <!-- <span *ngIf="stationForm.currentStep == 1" class=" text-sm">Información</span>-->

      <span *ngIf="!editStation" [ngClass]="stationForm.currentStep == 1 ? 'text-primary' : stationForm.step1 ? 'text-darkGray hover:text-primary cursor-pointer' : 'text-darkGray'" (click)="stationForm.step1 ? goToSteep(1) : null" class="text-sm">Información</span>


      <span [ngClass]="stationForm.currentStep == 2 ? 'text-primary' : stationForm.step1  ? 'text-darkGray hover:text-primary cursor-pointer' : 'text-darkGray'" (click)="stationForm.step1 ? goToSteep(2) : null" class="text-sm">Servicios/Vehículos</span>

      <span [ngClass]="stationForm.currentStep == 3 ? 'text-primary' : stationForm.step2 && stationForm.step2.quadrants.length > 0 && !isAnyQuadrantEmpty() ? 'text-darkGray hover:text-primary cursor-pointer' : 'text-darkGray'" (click)="stationForm.step2 && stationForm.step2.quadrants.length > 0 && !isAnyQuadrantEmpty() ? goToSteep(3) : null" class="text-sm">Cuadrantes</span>

      <!--<span *ngIf="stationForm.currentStep == 2 || stationForm.currentStep < 2" [ngClass]="stationForm.currentStep == 2 ? 'text-primary' :'text-darkGray'" class=" text-sm">Servicios/Vehículos</span>
      <span (click)="goToSteep(2)" *ngIf="stationForm.currentStep > 2 || stationForm.step2" class="text-darkGray text-sm cursor-pointer hover:text-primary">Servicios/Vehículos</span>-->

      <!--<span *ngIf="stationForm.currentStep == 3 || stationForm.currentStep < 3" [ngClass]="stationForm.currentStep == 3 ? 'text-primary' :'text-darkGray'" class=" text-sm">Cuadrantes</span>
      <span (click)="goToSteep(3)" *ngIf="stationForm.currentStep > 3  || stationForm.step2" class="text-darkGray text-sm cursor-pointer hover:text-primary">Cuadrantes</span>-->
    </ng-container>

    <button *ngIf="stationForm.step2 && stationForm.step2.quadrants.length > 0 && stationForm.currentStep == 3" class="border border-lightGray2 p-2 rounded-xl hover:bg-lightGray2 text-darkGray flex justify-center items-center gap-2 disabled:bg-darkGray disabled:text-lightGray2" (click)="quadrantOverlay.toggle($event)">
      <span *ngIf="!selectedQuadrant || !isQuadrantInArray(selectedQuadrant,stationForm.step2.quadrants)">Elige Cuadrante</span>
      <span *ngIf="selectedQuadrant && isQuadrantInArray(selectedQuadrant,stationForm.step2.quadrants)">{{ selectedQuadrant.name }}</span>
      <svg  xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
    </button>

    <button *ngIf="(stationForm.currentStep > 1 || stationForm.step1) && !editStation" (click)="cancel()" class="text-sm bg-red-500 text-white hover:bg-red-600 rounded-xl p-2">Cancelar</button>
    <p-overlayPanel #quadrantOverlay>
      <ul class="flex flex-col gap-2">
        <li [class.text-green]="(quadrant.sections.length > 0 && !quadrantIsInComplete(quadrant)) || (quadrant.link_type && quadrant.link)" [class.text-primary]="(quadrant.sections.length > 0 && quadrantIsInComplete(quadrant)) || (quadrant.link_type && quadrant.link == '')" [class.text-red-500]="quadrant.sections.length == 0 && !quadrant.link_type" [ngClass]="quadrant.name === selectedQuadrant?.name ?  'underline': 'hover:underline'" (click)="selectedQuadrant = quadrant; quadrantOverlay.toggle($event)" class="text-sm cursor-pointer text-darkGray" *ngFor="let quadrant of stationForm.step2?.quadrants ?? []" >
          {{quadrant.name}}
        </li>
      </ul>
    </p-overlayPanel>


    <!--<span  [ngClass]="currentStep == 1 ? 'text-primary' :'text-darkGray'" class=" text-sm">Información</span>
    <span  [ngClass]="currentStep == 2 ? 'text-primary' :'text-darkGray'" class=" text-sm">Servicios/Vehículos</span>
    <span  [ngClass]="currentStep == 3 ? 'text-primary' :'text-darkGray'"  class="text-darkGray text-sm">Cuadrantes</span>-->
  </div>
</div>

<app-step1-station *ngIf="stationForm && !editStation" [class.hidden]="stationForm.currentStep != 1" />
<app-step2-station [editStation]="editStation" [quadrants]="quadrants" *ngIf="stationForm" [class.hidden]="stationForm.currentStep != 2" />
<app-step3-station [editStation]="editStation" [quadrant]="selectedQuadrant" *ngIf="stationForm && stationForm.currentStep === 3" [class.hidden]="" />
