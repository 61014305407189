import {CanActivateFn, Router} from '@angular/router';
import {AuthStateService} from "../services/auth/auth-state.service";
import { inject } from '@angular/core';
import {User} from "../../models/User";

export const permissionGuard: CanActivateFn = (route, state) => {
  const userService = inject(AuthStateService);
  const router = inject(Router);

  // @ts-ignore
  let permission = route.data.permission;

  // console.log(permission);

  if (!userService.isLoggedIn()) {
    router.navigate(['login']); // Redirige a la página de login si no está autenticado
    return false;
  }
  // @ts-ignore
  let hasAccess = hasPermission(userService.currentUserValue, permission);

  // Evitar redirección si ya estás en 'dashboard/agenda'
  if (!hasAccess && state.url !== '/dashboard/agenda') {
    router.navigate(['/dashboard/agenda']);
    return false;
  }
  //

  if (!hasAccess && !userService.isNotProfessional()) {
    if (state.url !== '/dashboard/agenda') {
      router.navigate(['/dashboard/agenda']);
      return false;
    }
  } else if (!hasAccess && userService.isNotProfessional()) {
    router.navigate(['/dashboard']);
    return false;
  }


  return true;
};

const hasPermission = (user: User, permission: string): boolean => {
  return user.permissions.some(permi => permission === permi);
};
