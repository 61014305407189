import { Directive } from '@angular/core';

@Directive({
  selector: '[appOutlineGreenButton]',
  host: {
    'class': 'border duration-300 border-green rounded-full hover:bg-green disabled:bg-green disabled:text-lightGray2 hover:text-white px-4 py-4 text-green flex justify-center text-sm font-light'
  },
  standalone: true
})
export class OutlineButtonGreenDirective {

  constructor() { }

}
