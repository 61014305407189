import {Component, Input} from '@angular/core';
import {Service} from "../../../models/Service";
import {QuadrantService} from "../../../core/services/quadrants/quadrant.service";
import {Quadrant} from "../../../models/Quadrant";
import {ToastrService} from "ngx-toastr";
import {FormInputDirective} from "../../directives/forms/form-input.directive";
import {FormLabelDirective} from "../../directives/forms/form-label.directive";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-quadrant-edit-link',
  standalone: true,
  imports: [
    FormInputDirective,
    FormLabelDirective,
    FormsModule,
    NgIf
  ],
  templateUrl: './quadrant-edit-link.component.html',
  styleUrl: './quadrant-edit-link.component.css'
})
export class QuadrantEditLinkComponent {

  @Input() quadrant!: Quadrant;

  constructor(private quadrantService: QuadrantService, private toastr: ToastrService) {
  }

  updateLink(){
    if (this.quadrant && this.quadrant.link) {
      this.quadrantService.updateLink(this.quadrant.id, this.quadrant.link).subscribe({
          next:(response) => {
            if(response.success){
              this.toastr.success(response.message);
            }
          },
          error: (err) => {
            this.toastr.error(err.error.message)
          }
        }
      )
    }
  }
}
