<div  *ngIf="selectedStation" class="flex flex-col w-full mx-auto gap-y-8 mt-10 animate-fade-in">
  <div class="flex flex-col gap-y-2 animate-fade-in" >
    <label class="text-darkGray text-lg">Método de pago</label>
    <select appFormInputBlueNoFull [(ngModel)]="selectedPayment" (change)="onSelectPayment()">
      <option disabled [ngValue]="undefined">Selecciona un método de pago</option>
      <option *ngFor="let payment of selectedStation.payment_methods" [ngValue]="payment">{{payment.front_name}}</option>
    </select>
  </div>
  <div class="flex items-center justify-between mt-2">
    <button (click)="goBack()" appFrontBack>
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
      <span>Atrás</span>

    </button>
    <button (click)="next()" [disabled]="!selectedPayment" type="button" appNextSquare>
      Reservar
      <app-loader size="extra-small" *ngIf="loading"></app-loader>
    </button>
    <!--<button type="button" (click)="update()" class="bg-blue-500 px-4 py-2 mt-10 shadow-2xl duration-300 text-xl rounded-2xl text-white w-fit">Continuar →</button>-->
  </div>
</div>
