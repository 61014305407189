import { Injectable } from '@angular/core';
import {map, Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {User} from "../../../models/User";
import {environment} from "../../../../environments/environment";
import {Paginator} from "../../../models/Paginator";
import {Reservation} from "../../../models/Reservation";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {Station} from "../../../models/Station";
import {Service} from "../../../models/Service";
import {Vehicle} from "../../../models/Vehicle";
import {completeHttpFiltersAndSorts, getEventProgress} from "../../../const/functions";

@Injectable({
  providedIn: 'root'
})
export class ReservationGesService {

  constructor(private _http: HttpClient) { }


  sendProofToEmail(reservationId: number, email: string, phone: string|null): Observable<Response<null>> {
    // Crear el encabezado con el teléfono si existe
    let headers = new HttpHeaders();
    if (phone) {
      headers = headers.set('phone', phone);
    }

    // Realizar la solicitud HTTP con los headers incluidos
    return this._http.get<Response<null>>(`${environment.API_URL}/reservations/proof/email/${reservationId}/${email}`, { headers });
  }

  sendMessage(data: any,reservationId:number): Observable<Response<string>>{
    // console.log(data.phone);
    let headers = new HttpHeaders();
    if (data.phone) {
      headers = headers.set('phone', data.phone);
    }
    return this._http.post<Response<string>>(`${environment.API_URL}/reservations/send-message/${reservationId}`,data,{headers});
  }


  cancel(reservationId: number, phone: string|null): Observable<Response<null>>{
    let headers = new HttpHeaders();
    if (phone) {
      headers = headers.set('phone', phone);
    }
    return this._http.delete<Response<null>>(`${environment.API_URL}/reservations/cancel/${reservationId}`, { headers });
  }

  update(data: any, reservationId: number, phone: string|null) {
    // Crear los encabezados y agregar el número de teléfono si está disponible
    let headers = new HttpHeaders();
    if (phone) {
      headers = headers.set('phone', phone);
    }

    // Realizar la solicitud HTTP con los headers incluidos
    return this._http.patch<Response<Reservation>>(
      `${environment.API_URL}/reservations/update/${reservationId}`,
      data,
      { headers }
    );
  }

  check(reservationId: number, phone: string|null){
    return this._http.post<Response<boolean>>(`${environment.API_URL}/reservations/check/${reservationId}/${phone}`, {});
  }

  /*downloadProof(reservationId: number) {
    // Realizar la petición GET para obtener el PDF
    return this._http.get(`${environment.API_URL}/reservations/proof/${reservationId}`, {
      responseType: 'blob',
      observe: 'response' // Para obtener la respuesta completa, incluyendo las cabeceras
    }).pipe(
      map(response => {
        console.log(response.headers);
        // Extraer el nombre del archivo desde la cabecera Content-Disposition
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'justificante.pdf';

        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="(.+?)"/);
          if (matches && matches.length > 1) {
            fileName = matches[1];
          }
        }

        return { blob: response.body, fileName };
      })
    );
  }*/
}
