<div class="max-w-sm w-full bg-gradient-to-r p-6 rounded-lg shadow-xl transform transition-all" [ngClass]="gradientColors">
  <div class="flex justify-between items-center">
    <!-- Icono -->
    <div class="text-white text-3xl p-2 rounded-full" [ngClass]="iconBgColor">
      <ng-content></ng-content>
    </div>
    <!-- Etiqueta de tiempo o estado -->
    <span class="text-white text-sm font-medium  px-3 py-1 rounded-full" [ngClass]="dateBgColor">{{ dates }}</span>
  </div>
  <h2 class="text-white text-4xl font-semibold mt-4">{{title}}</h2>
  <p class="text-white text-2xl mt-2">{{info}} <span class="text-sm font-light" *ngIf="sub_info">{{sub_info}}</span></p>
  <!--<div class="mt-6">
    &lt;!&ndash; Botón de acción &ndash;&gt;
    <button class="px-4 py-2 bg-indigo-700 text-white rounded-full hover:bg-indigo-600 transition-colors duration-200">
      Ver más
    </button>
  </div>-->
</div>
