import {Component, OnInit} from '@angular/core';
import {User} from "../../../../models/User";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {CalendarComponent} from "../calendar/calendar.component";
import {DecimalPipe, NgIf} from "@angular/common";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";
import {RouterLink} from "@angular/router";
import {TableFiltersComponent} from "../../../../shared/components/generic-table/table-filters/table-filters.component";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {Filter} from "../../../../models/Table/Filter";
import {
  TableHeaderWrapperComponent
} from "../../../../shared/components/generic-table/table-header-wrapper/table-header-wrapper.component";
import {SortColumn} from "../../../../models/Table/SortColumn";

@Component({
  selector: 'app-sms',
  standalone: true,
  imports: [
    CalendarComponent,
    DecimalPipe,
    GenericTableComponent,
    LoaderComponent,
    NextButtonDirective,
    NgIf,
    RouterLink,
    TableFiltersComponent,
    TableHeaderWrapperComponent
  ],
  templateUrl: './sms.component.html',
  styleUrl: './sms.component.css'
})
export class SmsComponent  implements OnInit {

  user: User|null = null;
  canCreateStations: boolean = false;
  canAddNewBooks: boolean = false;

  loadingInfo = false;

  sorts: SortColumn[] = [];

  filters: ActiveFilter[] = [];
  tableFilters: Filter[] = [
    {
      key: 'created_at',
      type: 'date-between',
      parsed: 'date-arr',
      operator: 'between',
      placeholder: 'Fecha'
    }
  ];
  loadingFilters = false;

  displayNames: { [key: string]: string } = {
    created_at: 'Fecha',
  };


  tableHeaders: TableHeader[] = [
    {
      name: 'ESTACIÓN',
      active: true,
      td_type: 'text',
      td_key: 'name',
      td_class: 'font-bold',
      sort: false,
    },
    {
      name: 'TOTAL',
      active: true,
      td_type: 'numeric',
      td_key: 'sms_count',
      sort: false,
    },

  ]

  data: any[] = [];


  constructor(private breadcrumbService: BreadcrumbService, private authStateService: AuthStateService,
              private stationService: StationService,) {

    this.breadcrumbService.setBreadcrumb({
      display_name: 'Dashboard',
      items: [{
        name: 'SMS',
      }]
    })
  }

  ngOnInit() {
    this.loadSms()
  }

  loadSms(){
    this.loadingInfo = true;
    this.stationService.smsIfo(this.sorts, this.filters).subscribe({
      next: (result) => {
        this.data = result;
        this.loadingInfo = false;
      },
      error: err => {
        console.log(err);
        this.loadingInfo = false;
      }
    });
  }
  onChangeFilters(filters: ActiveFilter[]){
    this.filters = filters;
    // localStorage.setItem('itv_reservation_filters',JSON.stringify(this.filters))
    this.loadSms()
  }

}
