import { Component } from '@angular/core';
import {BookFormService} from "../../../../../core/services/book-form.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {environment} from "../../../../../../environments/environment";
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {ExistingReservationCardComponent} from "../../existing-reservation-card/existing-reservation-card.component";
import {FormErrorDirective} from "../../../../../shared/directives/forms/form-error.directive";
import {LoaderComponent} from "../../../../../shared/components/loader/loader.component";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {OldReservationComponent} from "../../old-reservation/old-reservation.component";
import {PaginatorModule} from "primeng/paginator";
import {OldReservation, Reservation} from "../../../../../models/Reservation";
import {ReservationForm, Step1} from "../../../../../models/Front/ReservationForm";
import {updateStep1} from "../../../../../store/reservationForm/stepper.actions";
import {FrontService} from "../../../../../core/services/front/front.service";

@Component({
  selector: 'app-step1',
  standalone: true,
  imports: [
    CardShadowComponent,
    ExistingReservationCardComponent,
    FormErrorDirective,
    LoaderComponent,
    NextButtonDirective,
    NgIf,
    NgTemplateOutlet,
    OldReservationComponent,
    PaginatorModule,
    ReactiveFormsModule
  ],
  templateUrl: './step1.component.html',
  styleUrl: './step1.component.css'
})
export class Step1Component {
  step1Form: FormGroup;
  visible: boolean = false;

  existingReservation?:Reservation;
  oldReservation?:OldReservation;

  error: string|null = null;

  loading = false;

  constructor(private bookFormService: BookFormService, private frontService:FrontService) {
    this.step1Form = new FormGroup({
      registration_number: new FormControl(environment.production ? '' :'3729KMl', [Validators.required/*, Validators.maxLength(7), Validators.minLength(7)*/])
    });
    this.bookFormService.reservationForm$.subscribe({
      next: (form: ReservationForm) => {
        if (form.steps.step1) {
          this.step1Form.patchValue(form.steps.step1);
        }
      }
    })
  }

  next(){
    this.loading = true;
    this.error = null;
    if (this.step1Form.valid) {
      let data:any = {
        registration_number:this.step1Form.get('registration_number')?.value
      }

      this.frontService.checkReservation(data).subscribe(
        {
          next: (response) => {
            if(response.data){
              // Verificar si es una OldReservation por la existencia de 'expiration' y ausencia de 'id'
              if ('expiration' in response.data && !('id' in response.data)) {
                this.oldReservation = response.data as OldReservation;
              }

              // Verificar si es una Reservation por la existencia de las propiedades clave como 'id'
              if ('id' in response.data) {
                this.existingReservation = response.data as Reservation;
              }

              if ('continue' in response.data) {
                // @ts-ignore
                const step1: Step1 = this.step1Form.value;
                this.bookFormService.setStep1(step1);
              }

              //const step1: Step1 = this.step1Form.value;
              // this.store.dispatch(updateStep1({ step1 }));
              //this.bookFormService.setStep1(step1);

            }
            this.loading = false;
          },
          error: error => {
            this.error = error.error.message;
            // console.error(error.error.message);
            this.loading = false;
          }
        }
      );
    } else {
      this.loading = false;
      this.step1Form.markAllAsTouched();
    }
  }

  back(){
    this.existingReservation=undefined;
    this.oldReservation=undefined;
  }

  toggleDialog() {
    this.visible = !this.visible;
  }

}
