import {Component, OnInit, ViewChild} from '@angular/core';
import {State} from "../../../../models/State";
import {StatesService} from "../../../../core/services/states/states.service";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {ToastrService} from "ngx-toastr";
import {NgForOf, NgIf} from "@angular/common";
import {NextOutlinedButtonDirective} from "../../../../shared/directives/buttons/next-outlined.directive";
import {BlockedDatesComponent} from "../../../../shared/components/blocked-dates/blocked-dates.component";
import {CommunityCardComponent} from "./components/community-card/community-card.component";
import {QuadrantLine} from "../../../../models/QuadrantLine";
import {CommunityBlockedFormComponent} from "./components/community-blocked-form/community-blocked-form.component";

@Component({
  selector: 'app-comunity',
  standalone: true,
  imports: [
    NgForOf,
    NextOutlinedButtonDirective,
    BlockedDatesComponent,
    NgIf,
    CommunityCardComponent,
    CommunityBlockedFormComponent
  ],
  templateUrl: './comunity.component.html',
  styleUrl: './comunity.component.css'
})
export class ComunityComponent implements OnInit{
  states? : State[]

  nationalLines: QuadrantLine[] = [];
  @ViewChild('blockedForm') blockedForm!: CommunityBlockedFormComponent;

  constructor(private stateService: StatesService, private breadcrumbService:BreadcrumbService, private toastr:ToastrService) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Comunidades',
      items: [{
        name: 'Listado',
      }]
    });
  }

  ngOnInit() {
    this.loadStates();
    this.loadNational();
  }
  loadStates(){
    this.stateService.list().subscribe({
      next:(response)=>{
        if(response.success){
          this.states = response.data;
        }
      },
      error: (err) =>{
        this.toastr.error(err.error.message);
      }
    })
  }

  loadNational(){
    this.stateService.national().subscribe({
      next:(response)=>{
        if (response.data) {
          this.nationalLines = response.data;
        }
      },
      error: (err) =>{
        this.toastr.error(err.error.message);
      }
    })
  }

  saveBlockedDates(info: any){
    this.stateService.addQuadrantReductionNational(info).subscribe({
      next:(response)=>{
        if (response.data) {
          // console.log(response.data);
          this.nationalLines.push(response.data);
          this.blockedForm.reset();
        }
      },
      error:(error)=>{
        this.toastr.error(error.error.message);
        console.log(error.error.message);
      }
    })
  }


}
