<div class="w-full text-start flex flex-col">

  <h1 class="text-green text-lg">Nueva cita para ITV</h1>
  <div *ngIf="step1 && step1.registration_number" class="mt-6 flex flex-col gap-y-6 animate-fade-in">
    <h1 class="text-darkGray text-lg">Datos de reserva</h1>

    <div class="text-sm flex flex-col gap-y-6">
      <div class="flex flex-row items-center gap-x-2">
        <div appStepperNumber>1
        </div>
        <p class="text-darkGray">{{ step1.registration_number }}</p>
      </div>

      <div *ngIf="step2 && step2.fuel" class="flex flex-row items-center gap-x-2 animate-fade-in">
        <div appStepperNumber>2
        </div>
        <div class="text-darkGray">
          <p *ngIf="step2.service">{{ step2.service.name }}</p>
          <p *ngIf="step2.vehicle">{{ step2.vehicle.name }}</p>
          <p *ngIf="step2.fuel">{{ step2.fuel.name }}</p>
        </div>
      </div>

      <div *ngIf="step3 && step3.station" class="flex flex-row items-center gap-x-2 animate-fade-in">
        <div appStepperNumber>3
        </div>
        <div class="text-darkGray">
          <p *ngIf="step3.state">{{ step3.state.name }}</p>
          <p *ngIf="step3.station">{{ step3.station.name }}</p>
        </div>
      </div>

      <div *ngIf="step4 && step4.hour" class="flex flex-row items-center gap-x-2 animate-fade-in">
        <div appStepperNumber>4
        </div>
        <div class="text-darkGray">
          <p>{{ step4.date | date:'fullDate' }}</p>
          <p *ngIf="step4.hour">{{ step4.hour.hour }}h</p>
        </div>
      </div>
      <div *ngIf="step5 && step5.phone_number" class="flex flex-row items-center gap-x-2 animate-fade-in">
        <div appStepperNumber>5
        </div>
        <div class="text-darkGray">
          <p>{{step5.name}}</p>
          <p>{{ step5.email }}</p>
          <p>{{ step5.phone_number }}</p>
        </div>
      </div>

      <div *ngIf="step6 && step6.payment" class="flex flex-row items-center gap-x-2 animate-fade-in">
        <div appStepperNumber>5
        </div>
        <div class="text-darkGray">
          <p>{{ step6.payment.front_name }}</p>
        </div>
      </div>

    </div>

  </div>

</div>

