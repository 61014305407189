<div class="w-full text-darkGray border-2 border-lightGray2 rounded-2xl p-2 px-4 flex justify-between">
  <div class="flex flex-col w-full">
    <span class="mb-2 flex items-center gap-4">{{ quadrant.name }}
      <span class="text-xs !mb-0" *ngIf="quadrant.professional" appFormLabel>PROFESIONALES</span>

      <a  *ngIf="showEdit && quadrant.type != 'link'" (click)="onEditClick.emit(quadrant)" class="cursor-pointer">
                <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-orange"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
              </a>
      </span>
    <app-quadrant-edit-link [quadrant]="quadrant"></app-quadrant-edit-link>
    <!--<div *ngIf="quadrant.type == 'link'" class="mb-2">
      <label appFormLabel>Link</label>
     <div class="flex gap-2">
       <input class="flex-1 " appFormInput type="text" [(ngModel)]="quadrant.link">
       <button (click)="updateLink()" class="rounded-full border border-primary bg-primary hover:text-primary hover:bg-transparent p-2 w-10 h-10 text-white">
         <svg  xmlns="http://www.w3.org/2000/svg"  class="w-6" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
       </button>
     </div>
    </div>-->
    <div class="flex justify-between mb-4">
      <!--SERVICIOS CUADRANTE-->
      <div class="flex flex-wrap w-full gap-1 items-center">
        <span class="text-xs text-darkGray">Servicios: </span>
        <span *ngFor="let service of quadrant.services" class="text-xs bg-lightBlue flex flex-wrap text-primary p-1 rounded-3xl">{{service.name}}<svg *ngIf="service.pivot?.visible" (click)="changeServiceVisibility(quadrant,service)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ml-2 w-4 h-4 cursor-pointer hover:bg-primary hover:text-white duration-200 hover:scale-110 rounded-full"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg> <svg *ngIf="!service.pivot?.visible" (click)="changeServiceVisibility(quadrant,service)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4 h-4 ml-2 cursor-pointer"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg></span>
      </div>
      <button class="w-fit items-center" (click)="ops.toggle($event)">
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
        <p-overlayPanel #ops>
          <div>
            <div class="py-4">
              <label appFormLabel class="!text-base">Servicios</label>
              <div class="grid grid-cols-2 gap-x-2 gap-y-2">
                <div *ngFor="let item of services">
                  <div *ngIf="quadrant.services && !isServiceInArray(item,quadrant.services)" class="flex justify-between gap-2 items-center">
                    <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
                    <button (click)="addService(item)" class="text-white {{isServiceInArray(item,servicesToAdd) ? 'bg-primary' : 'bg-green'}}  rounded-full w-4 h-4 flex items-center justify-center">
                      <span *ngIf="!isServiceInArray(item,servicesToAdd)">+</span>
                      <span *ngIf="isServiceInArray(item,servicesToAdd)">✔</span>
                    </button>
                  </div>
                </div>
              </div>
              <!--          <span *ngIf="form.get('services')?.touched && form.get('services')?.invalid" appFormError>
                        Debes de seleccionar almenos un servicio
                      </span>-->
            </div>
            <button (click)="saveServices()" appNextButton>Guardar</button>
          </div>
        </p-overlayPanel>
      </button>
    </div>
    <!--VEHÍCULOS CUADRANTE-->
    <div class="flex justify-between mb-4">
      <div class="flex flex-wrap w-full gap-1 items-center mt-2">
        <span class="text-xs text-darkGray">Vehiculos: </span>
        <span *ngFor="let vehicle of quadrant.vehicles" class="text-xs bg-lightBlue flex flex-wrap text-primary p-1 rounded-3xl">
          {{vehicle.name}}
          <svg *ngIf="vehicle.pivot?.visible" (click)="changeVehicleVisibility(quadrant,vehicle)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="ml-2 w-4 h-4 cursor-pointer hover:bg-primary hover:text-white duration-200 hover:scale-110 rounded-full"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
          <svg *ngIf="!vehicle.pivot?.visible" (click)="changeVehicleVisibility(quadrant,vehicle)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4 h-4 ml-2 cursor-pointer hover:bg-primary hover:text-white duration-200 hover:scale-110 rounded-full"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>
          <svg *ngIf="superadmin" (click)="removeVehicle(quadrant,vehicle)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4 h-4 ml-1 cursor-pointer text-red2 hover:text-white duration-200 hover:scale-110 hover:bg-red2 rounded-full"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
        </span>
      </div>
      <button class="w-fit items-center" (click)="opv.toggle($event)">
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
        <p-overlayPanel #opv>
          <div class="py-4">
            <label appFormLabel class="!text-base">Vehículos</label>
            <div class="grid grid-cols-2 gap-x-2 gap-y-2">
              <div *ngFor="let item of vehicles">
                <div *ngIf="quadrant.vehicles && !isVehicleInArray(item,quadrant.vehicles)" class="flex justify-between gap-2 items-center">
                  <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
                  <button (click)="addVehicle(item)" class="text-white {{isVehicleInArray(item,vehiclesToAdd) ? 'bg-primary' : 'bg-green'}}  rounded-full w-4 h-4 flex items-center justify-center">
                    <span *ngIf="!isVehicleInArray(item,vehiclesToAdd)">+</span>
                    <span *ngIf="isVehicleInArray(item,vehiclesToAdd)">✔</span>
                  </button>
                </div>
              </div>
            </div>
            <!--          <span *ngIf="form.get('services')?.touched && form.get('services')?.invalid" appFormError>
                      Debes de seleccionar almenos un servicio
                    </span>-->
          </div>
          <button (click)="saveVehicles()" appNextButton>Guardar</button>
        </p-overlayPanel>
      </button>
    </div>
    <!--COMBUSTIBLES CUADRANTE-->
    <div class="flex justify-between mb-4">
      <div class="flex flex-wrap w-full gap-1 items-center mt-2">
        <span class="text-xs text-darkGray">Combustibles: </span>
        <span *ngFor="let fuel of quadrant.fuels" class="text-xs bg-lightBlue flex flex-wrap text-primary p-1 rounded-3xl">{{fuel.name}}<svg *ngIf="fuel.pivot?.visible" (click)="changeFuelVisibility(quadrant,fuel)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="ml-2 w-4 h-4 cursor-pointer hover:bg-primary hover:text-white duration-200 hover:scale-110 rounded-full"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg> <svg *ngIf="!fuel.pivot?.visible" (click)="changeFuelVisibility(quadrant,fuel)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-4 h-4 ml-2 cursor-pointer"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg></span>
      </div>
      <button class="w-fit items-center" (click)="opf.toggle($event)">
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5 h-5 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
        <p-overlayPanel #opf>
          <div>
            <div class="py-4">
              <label appFormLabel class="!text-base">Combustibles</label>
              <div class="grid grid-cols-2 gap-x-2 gap-y-2">
                <div *ngFor="let item of fuels">
                  <div *ngIf="quadrant.fuels && !isFuelInArray(item,quadrant.fuels)" class="flex justify-between gap-2 items-center">
                    <span class="ml-1 text-sm text-darkGray">{{ item.name }}</span>
                    <button (click)="addFuel(item)" class="text-white {{isFuelInArray(item,fuelsToAdd) ? 'bg-primary' : 'bg-green'}}  rounded-full w-4 h-4 flex items-center justify-center">
                      <span *ngIf="!isFuelInArray(item,fuelsToAdd)">+</span>
                      <span *ngIf="isFuelInArray(item,fuelsToAdd)">✔</span>
                    </button>
                  </div>
                </div>
              </div>
              <!--          <span *ngIf="form.get('services')?.touched && form.get('services')?.invalid" appFormError>
                        Debes de seleccionar almenos un servicio
                      </span>-->
            </div>
            <button (click)="saveFuels()" appNextButton>Guardar</button>
          </div>
        </p-overlayPanel>
      </button>
    </div>
  </div>
  <div>
<!--    <button (click)="removeQuadrant(i)" class="bg-red-500 hover:bg-red-600 text-white p-1 rounded-2xl">
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.25"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
    </button>-->
  </div>
</div>
