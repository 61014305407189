import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ActiveFilter} from "../../../../models/Table/ActiveFilter";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {ReservationService} from "../../../../core/services/reservations/reservation.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ActiveFiltersComponent} from "../active-filters/active-filters.component";
import {AuthFormInputDirective} from "../../../directives/auth/auth-form-input.directive";
import {CalendarModule} from "primeng/calendar";
import {LoaderComponent} from "../../loader/loader.component";
import {MultiSelectModule} from "primeng/multiselect";
import {NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {Filter} from "../../../../models/Table/Filter";
import {filter} from "rxjs";
import {TableBulkActionsComponent} from "../table-bulk-actions/table-bulk-actions.component";
import {Reservation} from "../../../../models/Reservation";
import {PrimeNGConfig} from "primeng/api";
import { AuthStateService } from '../../../../core/services/auth/auth-state.service';
import { RouterLink } from '@angular/router';
import { BookFormService } from '../../../../core/services/book-form.service';
import { User } from '../../../../models/User';

@Component({
  selector: 'app-table-filters',
  standalone: true,
  imports: [
    RouterLink,
    ActiveFiltersComponent,
    AuthFormInputDirective,
    CalendarModule,
    LoaderComponent,
    MultiSelectModule,
    NgForOf,
    NgIf,
    OverlayPanelModule,
    ReactiveFormsModule,
    FormsModule,
    TableBulkActionsComponent,
    NgTemplateOutlet,
    NgClass
  ],
  templateUrl: './table-filters.component.html',
})
export class TableFiltersComponent implements OnChanges {
  filtersForm: FormGroup;
  @Input() filters: ActiveFilter[] = [];
  @Input() filtersList: Filter[] = [];
  @Input() perPage!: number;
  @Input() perPageOptions: number[] = [];
  @Input() loading: boolean = false;
  @Input() displayNames: { [key: string]: string } | null= null;
  @Output() onChangeFilters: EventEmitter<ActiveFilter[]> = new EventEmitter<ActiveFilter[]>();
  @Input() reservationsCsv: Reservation[] | null = null;
  @Input() bulkActions: { label: string, key: string }[] = [];
  @Output() bulkActionClick: EventEmitter<string> = new EventEmitter<string>();

  @Output() onChangePerPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() onChangeColumn: EventEmitter<TableHeader[]> = new EventEmitter<TableHeader[]>();
  @Input() tableHeaders: TableHeader[] = [];

  @Input() allInDropdown: boolean = true;
  @Input() maxSelectedDropdowns: number = 2;

  @Input() showColumnsAndPerPage: boolean = true;

  @Input() isCalendar: boolean = false;

  isProfessional: boolean = false;

  user: User|null = null;

  today:Date = new Date();

  constructor(private fb: FormBuilder, private reservationService: ReservationService, private spinner: NgxSpinnerService,
              private primeNGConfig: PrimeNGConfig, private authStateService:AuthStateService, private bookFormService:BookFormService) {
    this.filtersForm = this.fb.group({})
    this.primeNGConfig.setTranslation({
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sá'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Borrar',
      firstDayOfWeek: 1 // Establecer el lunes como primer día de la semana
    });
    this.isProfessional = !this.authStateService.isNotProfessional();

    this.authStateService.currentUser.subscribe(data => {
      this.user = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filtersList.forEach(filter => {
      this.filtersForm.addControl(filter.key, new FormControl(null));
    });
    this.filters.forEach(filter => {
      this.patchFilter(filter);
    });
  }

  patchFilter(filter: ActiveFilter){
    const patchObject = {};
    // @ts-ignore
    patchObject[filter.column] = filter.value;
    this.filtersForm.patchValue(patchObject);
  }

  hasSearch(){
    // console.log(this.filtersList.filter(filter => {return filter.type === 'search'}).length);
    return this.filtersList.filter(filter => {return filter.type === 'search'}).length
  }
  searchItem(){
    return this.filtersList.filter(filter => {return filter.type === 'search'})[0];
  }

  search(): void {
    const formValues = this.filtersForm.value;
    const filters: ActiveFilter[] = [];

    // console.log(this.filtersForm.value);

    this.filtersList.forEach(filter => {

      // console.log(formValues[filter.key]);

      if (formValues[filter.key] && formValues[filter.key].length > 0) {

        let obj: any = {
          column: filter.key,
          operator: filter.operator,
          value: formValues[filter.key]
        }

        if ((filter.parsed === 'relationship-arr' || filter.parsed == 'array')) {
          obj['parsed'] = filter.parsed;
          obj['relation_column'] = filter.relation_column;
          obj['relation_filter_column'] = filter.relation_filter_column;
        } else if (filter.parsed === 'date-arr' || filter.parsed === 'date') {
          obj['parsed'] = filter.parsed;
          obj['format'] = 'dd/MM/yyyy';
        }

        filters.push(obj);
      }
    });


    this.onChangeFilters.emit(filters);
  }

  clearFilters(): void {
    this.filtersForm.reset();
    this.filters = [];
    this.filters.push(          {
      column:"date",
      format:"dd/MM/yyyy",
      operator: "between",
      parsed: "date-arr",
      value:[this.today,this.today]
    })
    this.onChangeFilters.emit(this.filters);
  }

  public resetBookForm(){
    if (this.user && this.isProfessional) {
      this.bookFormService.resetProfessional({
        registration_number: this.user.profile.name
      }, {name: this.user.profile.name, email: this.user.profile.email, phone_number: this.user.profile.phone})
    } else {
      this.bookFormService.reset(undefined);
    }
  }

  removeFilter(item: { pos: number; filter: ActiveFilter }): void {
    // Eliminar el filtro en la posición especificada
    this.filters.splice(item.pos, 1);

    // Establecer el valor del filtro eliminado a null
    item.filter.value = null;

    // Comprobar si hay algún filtro con la columna 'date'
    const hasDateFilter = this.filters.some(f => f.column === 'date');

    // Si no quedan filtros o no hay un filtro con 'date', añadir el filtro predeterminado
    if (this.filters.length === 0 || !hasDateFilter) {
      const defaultFilter: ActiveFilter = {
        column: "date",
        format: "dd/MM/yyyy",
        operator: "between",
        parsed: "date-arr",
        value: [this.today, this.today],
      };

      this.filters.push(defaultFilter);

      // Emitir el evento con los filtros actualizados
      this.onChangeFilters.emit(this.filters);

      console.log(this.filtersForm);

      // Actualizar el formulario con el filtro modificado (opcional)
      this.patchFilter(defaultFilter);
    } else {
      // Emitir el evento con los filtros actualizados
      this.onChangeFilters.emit(this.filters);

      console.log(this.filtersForm);

      // Actualizar el formulario con el filtro modificado (opcional)
      this.patchFilter(item.filter);
    }
  }

}
