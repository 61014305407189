import { Component } from '@angular/core';
import {BookFormService} from "../../../../core/services/book-form.service";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {updateShowSummary, updateStep} from "../../../../store/reservationForm/stepper.actions";
import {DatePipe, NgIf} from "@angular/common";
import {StepperNumberDirective} from "../../../../shared/directives/stepper/stepper-number.directive";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../models/Front/ReservationForm";

@Component({
  selector: 'app-summaryv2',
  standalone: true,
  imports: [
    DatePipe,
    NgIf,
    StepperNumberDirective
  ],
  templateUrl: './summaryv2.component.html',
  styleUrl: './summaryv2.component.css'
})
export class Summaryv2Component {

  showSummary:boolean=false;
  currentStep?: number;
  step1?: Step1;
  step2?: Step2;
  step3?: Step3;
  step4?: Step4;
  step5?: Step5;
  step6?: Step6;
  isProfessional: boolean = false;

  constructor(private bookReservationForm: BookFormService, private authStateService: AuthStateService) {
    this.authStateService.currentUser.subscribe(data => {
      if (data) {
        if (data.roles.includes('professional')) {
          this.isProfessional = true;
        }
      }
    });
    this.bookReservationForm.reservationForm$.subscribe({
      next: data => {
        this.showSummary = data.showSummary;
        this.step1 = data.steps.step1;
        this.step2 = data.steps.step2;
        this.step3 = data.steps.step3;
        this.step4 = data.steps.step4;
        this.step5 = data.steps.step5;
        this.step6 = data.steps.step6;
      }
    })
  }

  public toggleSummary() {
    this.bookReservationForm.toggleSummary();
  }

  public goStep(step:number) {
    // console.log('Current Step before:', this.currentStep);
    this.currentStep = step;
    this.bookReservationForm.setCurrentStep(this.currentStep);
    // console.log('Current Step after:', this.currentStep);

  }
}
