import {Component, OnInit} from '@angular/core';
import {UsersComponent} from "../users/users.component";
import {BreadcrumbService} from "../../../../core/services/breadcrumb/breadcrumb.service";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {User} from "../../../../models/User";
import {DecimalPipe, NgIf} from "@angular/common";
import {CalendarComponent} from "../calendar/calendar.component";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";
import {RouterLink} from "@angular/router";
import {StationFormService} from "../../../../core/services/stations/station-form.service";
import {StationService} from "../../../../core/services/stations/station.service";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {GenericTableComponent} from "../../../../shared/components/generic-table/generic-table.component";
import {TableHeader} from "../../../../models/Table/TableHeader";
import {Paginator} from "../../../../models/Paginator";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    UsersComponent,
    NgIf,
    CalendarComponent,
    NextButtonDirective,
    RouterLink,
    DecimalPipe,
    LoaderComponent,
    GenericTableComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {

  user: User|null = null;
  canCreateStations: boolean = false;
  canAddNewBooks: boolean = false;

  total = 0;
  total_true = 0;
  total_false = 0;

  loadingInfo = false;


  tableHeaders: TableHeader[] = [
    {
      name: 'ESTACIÓN',
      active: true,
      td_type: 'text',
      td_key: 'name',
      td_class: 'font-bold',
      sort: false,
    },
    {
      name: 'AÑO ACTUAL',
      active: true,
      td_type: 'numeric',
      td_key: 'total',
      sort: false,
    },
    {
      name: 'REALIZADAS',
      active: true,
      td_type: 'numeric',
      td_key: 'reservations_true_count',
      sort: false,
    },
    {
      name: 'PENDIENTES',
      active: true,
      td_type: 'numeric',
      td_key: 'reservations_false_count',
      sort: false,
    },

  ]

  data: any[] = [];


  constructor(private breadcrumbService: BreadcrumbService, private authStateService: AuthStateService,
              private stationService: StationService,) {
    this.breadcrumbService.setBreadcrumb({
      display_name: 'Dashboard',
      items: [{
        name: 'Inicio',
      }]
    })

    this.authStateService.currentUser.subscribe({
      next: (result) => {
        this.user = result;
        this.canCreateStations = result?.permissions.some(v => v === 'create_stations') ?? false;
        this.canAddNewBooks = result?.permissions.some(v => v === 'create_reservation_free_plate') ?? false;
      }
    })
  }

  ngOnInit() {
    this.loadingInfo = true;
    if (this.user?.roles[0] != 'operador') {
      this.stationService.generalHomeInfo().subscribe({
        next: (result) => {
          result.forEach((element) => {
            this.total += (+element.reservations_true_count + +element.reservations_false_count);
            this.total_true += +element.reservations_true_count;
            this.total_false += +element.reservations_false_count;
            element.total = (+element.reservations_true_count + +element.reservations_false_count);
          })
          this.data = result;
          this.loadingInfo = false;
        },
        error: err => {
          console.log(err);
          this.loadingInfo = false;
        }
      })
    } else {
      this.loadingInfo = false;
    }
  }
}
