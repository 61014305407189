import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {FrontService} from "../../../../../core/services/front/front.service";
import {AuthStateService} from "../../../../../core/services/auth/auth-state.service";
import {BookFormService} from "../../../../../core/services/book-form.service";
import {Step2, Step3, Step4} from "../../../../../models/Front/ReservationForm";
import {AvailableHour} from "../../../../../models/AvailableHours";
import {updateStep, updateStep4} from "../../../../../store/reservationForm/stepper.actions";
import {Service} from "../../../../../models/Service";
import {Vehicle} from "../../../../../models/Vehicle";
import {Fuel} from "../../../../../models/Fuel";
import {Station} from "../../../../../models/Station";
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {LoaderComponent} from "../../../../../shared/components/loader/loader.component";
import {MonthCalendarComponent} from "../../../../../shared/components/month-calendar/month-calendar.component";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-step4',
  standalone: true,
  imports: [
    CardShadowComponent,
    DatePipe,
    FrontBackDirective,
    LoaderComponent,
    MonthCalendarComponent,
    NextButtonDirective,
    NgForOf,
    NgIf,
    NgClass
  ],
  templateUrl: './step4.component.html',
  styleUrl: './step4.component.css'
})
export class Step4Component implements OnInit, AfterViewInit, OnDestroy {

  step2?: Step2;
  step3?: Step3;

  showMessage: string|null = null;

  blockedDates: string[] = [];

  @ViewChild('availableHours') availableHoursElement!: ElementRef;
  @ViewChild('continue') continueElement!: ElementRef;

  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();

  selectedDate: string|undefined;
  selectedHour: AvailableHour|undefined;
  hours: AvailableHour[] = [];

  loadingHours: boolean = false;
  loadingDates: boolean = false;

  isProfessional: boolean = false;
  professional_id?:number = undefined;

  private destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next(); // Emite un valor para completar las suscripciones
    this.destroy$.complete(); // Finaliza el Subject
  }

  constructor(private frontService: FrontService,private AuthStateService: AuthStateService, private bookFormService: BookFormService,) {
    this.bookFormService.reservationForm$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        this.step2 = data.steps.step2;
        this.step3 = data.steps.step3;

        this.selectedDate = data.steps.step4?.date;
        this.selectedHour = data.steps.step4?.hour;

        if (data.step == 4) {
          this.loadDates();
        }

/*        if(data.steps.step4 && data.steps.step4.month && data.steps.step4.year){
          this.month = data.steps.step4.month;
          this.year = data.steps.step4.year;
        } else {
          this.month=new Date().getMonth()+1;
          this.year = new Date().getFullYear();
        }*/

      }
    });

  }


  ngOnInit() {
    /*this.AuthStateService.currentUser.subscribe({
      next: value => {
        if (value && value.roles.includes('professional')) {
          this.isProfessional = true;
          this.professional_id = value.profile.id;

          const date = new Date();

          // Obtener el año, mes y día
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses en JavaScript empiezan en 0
          const day = String(date.getUTCDate()).padStart(2, '0');

          // Formato YYYY-mm-dd
          const formattedDate = `${year}-${month}-${day}`;

          this.selectedDate = formattedDate;

          if (this.step2 && this.step3 && this.selectedDate) {
            this.listAvailableHours(this.selectedDate, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station)
          }

        } else {
          this.loadDates();

        }
      }
    })*/
  }

  ngAfterViewInit() {
    // Este método garantiza que los elementos de ViewChild ya estén disponibles.
  }

  loadDates(force: boolean = false){
    if (this.step2 && this.step3) {
      if(this.selectedDate && !force){
        this.year = new Date(this.selectedDate).getFullYear();
        this.month = new Date(this.selectedDate).getMonth() + 1;
      }
      this.listAvailableDates(this.month, this.year, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station);
    }
  }

  onSelectedDay(date: string){
    this.selectedDate = date;

    this.selectedHour = undefined;

    if (this.step2 && this.step3) {
      this.listAvailableHours(date, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station);
    }
  }

  goBack(){
    this.bookFormService.setCurrentStep3WithReset(3);
    this.bookFormService.resetStep4();
  }

  public update() {
    const step4: Step4 = {
      hour: this.selectedHour,
      date: this.selectedDate,
      //month: this.selectedDate ? new Date(this.selectedDate).getMonth()+1 : new Date().getMonth()+1,
      //year: this.selectedDate ? new Date(this.selectedDate).getFullYear() : new Date().getFullYear(),
    };
    this.bookFormService.setStep4(step4);
  }
  public updateWithoutPass() {
    const step4: Step4 = {
      hour: this.selectedHour,
      date: this.selectedDate,
      //month: this.selectedDate ? new Date(this.selectedDate).getMonth()+1 : new Date().getMonth()+1,
      //year: this.selectedDate ? new Date(this.selectedDate).getFullYear() : new Date().getFullYear(),
    };
    this.bookFormService.setStep4WithoutPass(step4);
  }

  nextMonth() {
    this.month++;

    if (this.month > 12) {
      this.month = 1;
      this.year++;
    }
    this.selectedHour = undefined;
    this.selectedDate = undefined;
    this.hours = [];
    this.loadDates(true);
  }

  prevMonth() {
    this.month--;

    if (this.month < 1) {
      this.month = 12;
      this.year--;
    }
    this.selectedHour = undefined;
    this.selectedDate = undefined;
    this.hours = [];
    this.loadDates(true);
  }

  listAvailableHours(date: string, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined) {
    this.loadingHours = true;
    this.frontService.listAvailableHours(date, service!.id, vehicle!.id, fuel!.id, station!.id, this.professional_id).subscribe({
      next: (response) => {
        if (response.data) {
          this.hours = response.data.hours;
          if(this.selectedHour){
            let lastHourSelected = this.hours.find(h => h.id === this.selectedHour?.id)
            if(lastHourSelected && lastHourSelected.available < 1){
              this.hours = [];
              this.selectedHour = undefined;
              this.updateWithoutPass();
            }
          }
        }
        this.loadingHours = false;
        setTimeout(() => {
          this.scrollToAvailableHours();
        }, 100);
      }, error: err => {
        this.loadingHours = false;
      }
    })
  }

  scrollToAvailableHours() {
    this.availableHoursElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollToBottom(){
    this.continueElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  listAvailableDates(month: number, year: number, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined){
    this.loadingDates = true;
    this.frontService.listAvailableDates(month, year, service!.id, vehicle!.id, fuel!.id, station!.id, this.professional_id).subscribe({
      next: (response) => {
        if (response.data && response.success) {
          response.data.forEach(item => {
            if (item.available == 0) {
              this.blockedDates.push(item.date);
            }
            this.loadingDates = false;
          });
          if (this.blockedDates.some(bd => bd === this.selectedDate)) {
            // Si la fecha seleccionada está bloqueada, la limpiamos
            this.selectedDate = undefined;
            this.selectedHour = undefined;
            this.updateWithoutPass();
            this.hours = [];
          }
          if (this.step2 && this.step3 && this.selectedDate) {
            // Si la fecha es válida, cargamos las horas disponibles
            this.listAvailableHours(this.selectedDate, this.step2.service, this.step2.vehicle, this.step2.fuel, this.step3.station);
          }
        } else if (response.data && !response.success) {

          // @ts-ignore
          this.showMessage = response.data;
        }
      },
      error: err => {
        this.loadingDates = false;
      }
    })
  }

  onSelectHour(hour: AvailableHour){
    this.selectedHour = hour;
    this.scrollToBottom();
  }
}
