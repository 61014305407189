import { Component } from '@angular/core';
import {BookFormService} from "../../../../../../../../core/services/book-form.service";
import {FrontService} from "../../../../../../../../core/services/front/front.service";
import {Step2, Step3, Step4} from "../../../../../../../../models/Front/ReservationForm";
import {AvailableHour} from "../../../../../../../../models/AvailableHours";
import {FormSelectSquareDirective} from "../../../../../../../../shared/directives/forms/form-select-square.directive";
import {FormsModule} from "@angular/forms";
import {LoaderComponent} from "../../../../../../../../shared/components/loader/loader.component";
import {
  MonthCalendarComponent
} from "../../../../../../../../shared/components/month-calendar/month-calendar.component";
import {NextSquareButtonDirective} from "../../../../../../../../shared/directives/buttons/next-square.directive";
import {NgForOf, NgIf} from "@angular/common";
import {updateStep, updateStep4} from "../../../../../../../../store/reservationForm/stepper.actions";
import {Service} from "../../../../../../../../models/Service";
import {Vehicle} from "../../../../../../../../models/Vehicle";
import {Fuel} from "../../../../../../../../models/Fuel";
import {Station} from "../../../../../../../../models/Station";
import {State} from "../../../../../../../../models/State";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-step4',
  standalone: true,
  imports: [
    FormSelectSquareDirective,
    FormsModule,
    LoaderComponent,
    MonthCalendarComponent,
    NextSquareButtonDirective,
    NgForOf,
    NgIf
  ],
  templateUrl: './step4.component.html',
  styleUrl: './step4.component.css'
})
export class Step4Component {

  step2?: Step2;
  step3?: Step3;
  step4?: Step4;

  loadingDates: boolean = false;
  loadingHours: boolean = false;

  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();

  selectedDate: string|undefined;
  selectedHour: AvailableHour|undefined;

  hours: AvailableHour[] = [];

  blockedDates: string[] = [];

  selectedState?:State;
  selectedStation?:Station;
  selectedService?: Service;
  selectedVehicle?:Vehicle ;
  selectedFuel?:Fuel;

  quadrantMessage?: string = undefined;

  professional:boolean = false;
  professional_id?:number = undefined;

  private destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next(); // Emite un valor para completar las suscripciones
    this.destroy$.complete(); // Finaliza el Subject
  }


  constructor(private bookFormService: BookFormService, private frontService: FrontService){
    this.bookFormService.reservationForm$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        this.professional_id = data.professional_id;
        this.professional = data.professional;

        if (data.steps.step3?.subStep == 3 && data.steps.step2?.subStep == 4) {
          console.log('TERMINADO 3 y 2');

          if (!data.steps.step4) {
            this.selectedDate = undefined;
            this.selectedHour = undefined;
            this.selectedState = data.steps.step3.state;
            this.selectedStation =  data.steps.step3.station;
            this.selectedService = data.steps.step2.service;
            this.selectedVehicle = data.steps.step2.vehicle;
            this.selectedFuel = data.steps.step2.fuel;
            this.quadrantMessage = "";
            this.loadDates();
          } else {
            this.step4 = data.steps.step4
          }

          if (!this.step2 && !this.step3) {
            this.step3 = data.steps.step3;
            this.step2 = data.steps.step2;
            this.selectedState = data.steps.step3.state;
            this.selectedStation =  data.steps.step3.station;
            this.selectedService = data.steps.step2.service;
            this.selectedVehicle = data.steps.step2.vehicle;
            this.selectedFuel = data.steps.step2.fuel;
            this.quadrantMessage = "";
            this.loadDates();
            console.log('COMPLETADO PASO 3')
          }
        } else {
          this.quadrantMessage = "";
          console.log('EN PROCESO 3 y 2')
          this.step2 = undefined;
          this.step3 = undefined;
          this.step4 = undefined;
          this.selectedDate = undefined;
          this.selectedHour = undefined;
        }

      }
    })

  }

  nextMonth() {
    // Incrementa el mes actual
    this.month++;

    // Verifica si el mes excede diciembre
    if (this.month > 12) {
      this.month = 1;  // Reinicia el mes a enero
      this.year++;     // Incrementa el año
    }

    this.loadDates();
  }

  /**
   * Retrocede al mes anterior si no se baja del mes 1.
   */
  prevMonth() {
    // Decrementa el mes actual
    this.month--;

    // Verifica si el mes es menor a enero
    if (this.month < 1) {
      this.month = 12; // Ajusta el mes a diciembre
      this.year--;     // Decrementa el año
    }

    this.loadDates();
  }

  loadDates(){
    if (this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedStation) {
      this.listAvailableDates(this.month, this.year, this.selectedService, this.selectedVehicle, this.selectedFuel, this.selectedStation);
    }
  }

  listAvailableDates(month: number, year: number, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined){
    this.loadingDates = true;
    this.blockedDates = [];
    this.frontService.listAvailableDates(month, year, service!.id, vehicle!.id, fuel!.id, station!.id, this.professional_id).subscribe({
      next: (response) => {
        if (response.status_message) {
          this.quadrantMessage = response.status_message;
        }
        if (response.data) {
          response.data.forEach(item => {
            if (item.available == 0) {
              this.blockedDates.push(item.date);
              if(this.step4 && this.step4.date && this.step4.hour){
                console.log(this.step4);
                this.onSelectedDay(this.step4.date);
                if(this.selectedDate){
                  this.year = new Date(this.selectedDate).getFullYear();
                  this.month = new Date(this.selectedDate).getMonth() + 1;
                }
              }
            }
            this.loadingDates = false;
          });
        }
      },
      error: err => {
        this.loadingDates = false;
      }
    })
  }

  onSelectedDay(date: string){
    if(this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation){

      this.selectedDate = date;
      this.selectedHour = undefined;

      this.listAvailableHours(this.selectedDate, this.selectedService, this.selectedVehicle, this.selectedFuel, this.selectedStation);
      // this.store.dispatch(updateStep4({step4}));
    }
   /* let step = 4
    this.store.dispatch(updateStep({step}));

    this.selectedDate = date;
    this.selectedHour = undefined;

    if (this.step2 && this.step3) {
      this.listAvailableHours(this.selectedDate, this.selectedService, this.selectedVehicle, this.selectedFuel, this.selectedStation);
    }*/

  }

  listAvailableHours(date: string, service: Service | undefined, vehicle: Vehicle | undefined, fuel: Fuel | undefined, station: Station | undefined) {
    this.loadingHours = true;
    this.frontService.listAvailableHours(date, service!.id, vehicle!.id, fuel!.id, station!.id,  this.professional_id).subscribe({
      next: (response) => {
        if (response.data) {
          this.hours = response.data.hours;
          if(this.step4 && this.step4.hour){
            console.log(this.step4);
            this.selectedHour = this.hours.find(h => h.id === this.step4?.hour?.id);
            this.bookFormService.setStep4(this.step4);
            //this.onSelectHour()
          }
        }
        this.loadingHours = false;
      }, error: err => {
        this.loadingHours = false;
      }
    })
  }

  onSelectHour(){
    /*if(this.selectedService && this.selectedVehicle && this.selectedFuel && this.selectedState && this.selectedStation && this.selectedHour){
      const step4:Step4 = {
        hour:this.selectedHour,
        date:this.selectedDate,
      }
      this.store.dispatch(updateStep4({step4}));
    }*/
  }

  next(){
    if (this.selectedDate && this.selectedHour) {
      const step4: Step4 = {
        date: this.selectedDate,
        hour: this.selectedHour
      };
      this.bookFormService.setStep4(step4);
    } else {
      this.reset();
    }
  }

  reset(){
    const step4: Step4 = {
      date: undefined,
      hour: undefined
    };
    this.bookFormService.updateStep4(step4);
  }

}
