import { Component } from '@angular/core';
import {PaymentMethod} from "../../../../../models/PaymentMethod";
import {BookFormService} from "../../../../../core/services/book-form.service";
import {ReservationForm, Step6} from "../../../../../models/Front/ReservationForm";
import {updateShowSummary, updateStep, updateStep6} from "../../../../../store/reservationForm/stepper.actions";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {NextButtonDirective} from "../../../../../shared/directives/buttons/next.directive";
import {NgForOf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";

@Component({
  selector: 'app-step6',
  standalone: true,
  imports: [
    FrontBackDirective,
    NextButtonDirective,
    NgForOf,
    PaginatorModule
  ],
  templateUrl: './step6.component.html',
  styleUrl: './step6.component.css'
})
export class Step6Component {

  selectedPaymentMethod?: PaymentMethod;
  selectedPaymentMethodId?: number;
  paymentMethods: PaymentMethod[] = [];

  constructor(private bookFormService: BookFormService) {
    this.bookFormService.reservationForm$.subscribe({
      next: (form: ReservationForm) => {
        if (form.steps.step3 && form.steps.step3.station) {
          this.paymentMethods = form.steps.step3.station.payment_methods ?? [];
        }
        this.selectedPaymentMethod = form.steps.step6?.payment;
        this.selectedPaymentMethodId = form.steps.step6?.payment?.id;
      }
    })
  }

  public update() {
    if (this.selectedPaymentMethod) {
      this.bookFormService.setStep6({payment: this.selectedPaymentMethod});
      this.bookFormService.setShowSummary(false);
    }
  }

  goBack() {
   this.bookFormService.setCurrentStep(5);
  }
}
