<div class="flex flex-col w-full mx-auto gap-y-8 mt-10 animate-fade-in">

  <form [formGroup]="step5Form" class="flex flex-col mb-10 gap-y-4">
    <div class="flex flex-col text-start">
      <input formControlName="name" type="text" placeholder="Nombre (opcional)"
             class="w-full rounded-full bg-lightGray2 px-3 py-2 outline-0 border-0"/>
    </div>
    <div class="flex flex-col text-start">
      <input formControlName="email" type="email" placeholder="Email (opcional)"
             class="w-full rounded-full bg-lightGray2 px-3 py-2 outline-0 border-0"/>
      <div *ngIf="step5Form.get('email')?.invalid && step5Form.get('email')?.touched" appFormError>Email no válido</div>
    </div>
    <div class="flex flex-col text-start">
      <input formControlName="phone_number" placeholder="Móvil *" type="text"
             class="w-full rounded-full bg-lightGray2 text-darkGray px-3 py-2 outline-0 border-0"/>
      <div *ngIf="step5Form.get('phone_number')?.invalid && step5Form.get('phone_number')?.touched" appFormError>Número
        de teléfono no válido
      </div>
    </div>
    <div class="flex items-center justify-between mt-2">
      <button (click)="goBack()" appFrontBack>
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
             stroke-linecap="round" stroke-linejoin="round" class="w-5"><path stroke="none" d="M0 0h24v24H0z"
                                                                              fill="none"/><path d="M5 12l14 0"/><path
          d="M5 12l4 4"/><path d="M5 12l4 -4"/></svg>
      </span>
        <span>Atrás</span>

      </button>
      <button (click)="next()" [disabled]="step5Form.invalid" type="button" appNextSquare>
        Siguiente
        <app-loader size="extra-small" *ngIf="loading"></app-loader>
      </button>
      <!--<button type="button" (click)="update()" class="bg-blue-500 px-4 py-2 mt-10 shadow-2xl duration-300 text-xl rounded-2xl text-white w-fit">Continuar →</button>-->
    </div>
  </form>
</div>
