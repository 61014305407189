import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Response} from "../../../models/Response";
import {Station} from "../../../models/Station";
import {environment} from "../../../../environments/environment";
import {UserV2} from "../../../models/UserV2";
import {Paginator} from "../../../models/Paginator";
import {SortColumn} from "../../../models/Table/SortColumn";
import {ActiveFilter} from "../../../models/Table/ActiveFilter";
import {completeHttpFiltersAndSorts} from "../../../const/functions";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  list(sorts: SortColumn[] = [], filters: ActiveFilter[] = [], perPage = 50): Observable<Response<Paginator<UserV2>>>{
    let params = completeHttpFiltersAndSorts(sorts, filters);
    return this.http.get<Response<Paginator<UserV2>>>(`${environment.API_URL}/users/list/${perPage}`, {params});
  }

  create(data:any){
    return this.http.post<Response<UserV2>>(`${environment.API_URL}/users/create`,data)
  }

  update(userId:number,data:any){
    return this.http.patch<Response<UserV2>>(`${environment.API_URL}/users/update/${userId}`,data)
  }

  listOperadoras(): Observable<Response<UserV2[]>>{
    return this.http.get<Response<UserV2[]>>(`${environment.API_URL}/users/list-operadoras`);
  }

  listProfessionals(): Observable<Response<UserV2[]>>{
    return this.http.get<Response<UserV2[]>>(`${environment.API_URL}/users/list-professionals`);
  }

  importUsers(data:any):Observable<Response<null>>{
    return this.http.post<Response<null>>(`${environment.API_URL}/users/import-users`,data);
  }

}
