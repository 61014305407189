import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Step1, Step2, Step3} from "../../../../../../models/Front/ReservationForm";
import {BookFormService} from "../../../../../../core/services/book-form.service";
import {NgClass, NgIf} from "@angular/common";
import {Step1Component} from "./components/step1/step1.component";
import {Step2Component} from "./components/step2/step2.component";
import {Step3Component} from "./components/step3/step3.component";
import {Step4Component} from "./components/step4/step4.component";
import {Step5Component} from "./components/step5/step5.component";
import {Step6Component} from "./components/step6/step6.component";
import {Step7Component} from "../../../../../front/components/formv2/step7/step7.component";
import {StationService} from "../../../../../../core/services/stations/station.service";
import {Station} from "../../../../../../models/Station";
import {Inplace} from "primeng/inplace";

@Component({
  selector: 'app-appointment-form-v2',
  standalone: true,
  imports: [
    NgClass,
    Step1Component,
    NgIf,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    Step7Component
  ],
  templateUrl: './appointment-form-v2.component.html',
  styleUrl: './appointment-form-v2.component.css'
})
export class AppointmentFormV2Component implements OnInit {
  currentStep: number = 1;

  @Input() forceProfessional: boolean = false;

  @Output() loadingStations: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stationsLoaded: EventEmitter<Station[]> = new EventEmitter<Station[]>();

  constructor(private bookFormService: BookFormService, private stationService: StationService) {
    this.bookFormService.reservationForm$.subscribe({
      next: data => {
        this.currentStep = data.step;
        if (data.confirmed) {
          this.stationsLoaded.emit([]);
        }
      }
    })
  }

  ngOnInit() {
    this.bookFormService.reset(undefined,this.forceProfessional);
  }

  loadStations(data: {step2: Step2, step3: Step3}) {
    if (!this.forceProfessional) {
      this.loadingStations.emit(true)
      if(data.step2.service && data.step2.vehicle && data.step2.fuel && data.step3.state) {
        this.stationService.listAvailability(data.step2.service.id, data.step2.vehicle.id, data.step2.fuel.id,data.step3.state.id).subscribe({
          next: value => {
            if (value.data) {
              this.stationsLoaded.emit(value.data);
              this.loadingStations.emit(false);
              // this.stationsInforEmit.emit(value.data);
            }
            //  this.stationsInforLoading.emit(false);
          },
          error: (err) => {
            this.loadingStations.emit(false);
            // this.stationsInforLoading.emit(false);
          }
        })
      }
    }
  }

}
