import {Component, OnInit} from '@angular/core';
import {Step1Component} from "./step1/step1.component";
import {BookFormService} from "../../../../core/services/book-form.service";
import {ReservationForm, Step1} from "../../../../models/Front/ReservationForm";
import {NgIf} from "@angular/common";
import {Step2Component} from "./step2/step2.component";
import {Step3Component} from "./step3/step3.component";
import {Step4Component} from "./step4/step4.component";
import {Step5Component} from "./step5/step5.component";
import {Step6Component} from "./step6/step6.component";
import {Step7Component} from "./step7/step7.component";

@Component({
  selector: 'app-formv2',
  standalone: true,
  imports: [
    Step1Component,
    NgIf,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    Step7Component
  ],
  templateUrl: './formv2.component.html',
  styleUrl: './formv2.component.css'
})
export class Formv2Component implements OnInit {

  currentStep: number = 1;

  constructor(private bookFormService: BookFormService) {
    this.bookFormService.reservationForm$.subscribe({
      next: (form: ReservationForm) => {
        this.currentStep = form.step;
      }
    })
  }

  ngOnInit() {}
}
