import {Component, OnDestroy} from '@angular/core';
import {Step2, Step3} from "../../../../../models/Front/ReservationForm";
import {State} from "../../../../../models/State";
import {Station} from "../../../../../models/Station";
import {FrontService} from "../../../../../core/services/front/front.service";
import {BookFormService} from "../../../../../core/services/book-form.service";
import {updateStep, updateStep3} from "../../../../../store/reservationForm/stepper.actions";
import {CardSelectorComponent} from "../../utils/card-selector/card-selector.component";
import {CardShadowComponent} from "../../utils/card-shadow/card-shadow.component";
import {DatePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {FrontBackDirective} from "../../../../../shared/directives/buttons/front.back.directive";
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-step3',
  standalone: true,
  imports: [
    CardSelectorComponent,
    CardShadowComponent,
    DatePipe,
    FrontBackDirective,
    NgForOf,
    NgIf,
    UpperCasePipe
  ],
  templateUrl: './step3.component.html',
  styleUrl: './step3.component.css'
})
export class Step3Component implements OnDestroy {

  step2?: Step2;
  step3?: Step3;
  states?: State[];
  stations?: Station[];
  stateSelected?: State;
  stationSelected?:Station;
  // @Output() stepCompleted = new EventEmitter<void>();
  substep: number = 1;

  loading: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(private frontService:FrontService, private bookFormService: BookFormService,
              private router: Router) {
    this.bookFormService.reservationForm$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        if (data.step == 3) {
          this.step2 = data.steps.step2;
          this.step3 = data.steps.step3;
          this.stateSelected = data.steps.step3?.state;
          if(this.stateSelected){
            this.loadStations(this.stateSelected);
            this.stationSelected = data.steps.step3?.station;
            if(data.steps.step3?.subStep){
              this.substep = data.steps.step3?.subStep;
            }
          }
        }

      }
    })
    this.loadStates();
  }

  ngOnDestroy() {
    this.destroy$.next(); // Emite un valor para completar las suscripciones
    this.destroy$.complete(); // Finaliza el Subject
  }

  loadStates(){
    if(this.step2?.service && this.step2.vehicle && this.step2.fuel) {
      this.loading = true;
      this.frontService.listStates(this.step2.service.id,this.step2.vehicle.id,this.step2.fuel.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.states = response.data;
            }
            this.loading = false;
          },
          error: err => {
            this.loading = false;
          }
        }
      );
    }
  }

  public nextSubStep(step: number) {
    this.substep = step;
  }

  public backSubStep(){
    if (this.substep > 1) {
      this.substep--;
    } else {
      this.bookFormService.setCurrentStep(2);
    }
  }

  public update() {
    const step3: Step3 = {
      subStep: 1,
      state:this.stateSelected,
      station:this.stationSelected,
    };
    this.bookFormService.setStep3(step3);
  }

  public selectState(state:State) {
    this.stateSelected = state;
    this.loadStations(state);
    this.nextSubStep(2);
  }

  public loadStations(state:State){
    this.loading = true;
    if (this.step2) {
      this.frontService.listStations(this.step2.service!.id, this.step2.vehicle!.id, this.step2.fuel!.id, state.id).subscribe(
        {
          next: (response) => {
            if(response.data){
              this.stations = response.data;
              if (response.data.length == 1) {
                if (response.data[0].quadrant_link) {
                  this.redirect(response.data[0]);
                }
              }
            }
            this.loading = false;
          },
          error: err => {
            // console.log(err);
            this.loading = false;
          }
        }
      );
    }
  }

  redirect(station: Station){
    if (station && station.quadrant_link){
      const link = document.createElement('a');
      link.href = station.quadrant_link;
      link.target = '_blank';
      link.rel = 'noopener noreferrer'; // Importante por seguridad
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public selectStation(station: Station) {
    if (station.quadrant_link) {
      this.redirect(station);
    } else {
      this.stationSelected = station;
      this.update();
    }

  }

}
