import {Component, Input, input} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-stadistic-card',
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  templateUrl: './stadistic-card.component.html',
  styleUrl: './stadistic-card.component.css'
})
export class StadisticCardComponent {
  @Input() title!: string;
  @Input() info!: string;
  @Input() sub_info?: string;
  @Input() dates!: string;
  @Input() gradientColors: string = 'from-indigo-500 to-blue-600';
  @Input() dateBgColor: string = 'bg-indigo-800';
  @Input() iconBgColor: string = 'bg-indigo-700';

}
