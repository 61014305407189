<div class="w-full rounded-3xl shadow-sm border-2 border-lightGray2 relative "><!-- animate-fade-in-down -->

  <ng-content select="[GTHeader]"></ng-content>


  <div class="w-full relative overflow-x-auto overflow-y-auto border-lightGray2" [class.border-t]="filters.length > 0">

    <app-loader *ngIf="loading" size="large" />

    <div class="p-4" *ngIf="!loading">
      <ng-template #noResults >
        <caption class="text-center w-full caption-bottom py-4" *ngIf="!items2 || items2.length == 0">
          <span class="text-darkGray text-2xl uppercase">No hay resultados</span>
        </caption>
      </ng-template>
      <table class="table-auto w-full text-left">
        <thead>
        <tr class="text-primary border-b-2 border-lightGray2 border-dashed">

          <ng-container *ngFor="let header of tableHeaders">
            <th scope="col" *ngIf="header.active" class="py-3 font-medium relative" [class.cursor-pointer]="header.sort" (click)="(header.sort && header.td_key) ? toggleSort(header) : null">
              <div class="inline-flex items-center">
                {{ header.name }}
                <span class="ml-1 flex-shrink-0 w-4 text-center">
              <span *ngIf="header.sort && header.td_key && getSortOrder(header.td_key) == null">
                <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="currentColor"  class="w-3 fill-lightGray stroke-lightGray"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6a.95 .95 0 0 1 .073 .082l.006 .008l.016 .022l.042 .059l.009 .015l.007 .01l.014 .027l.024 .044l.007 .017l.01 .02l.012 .032l.015 .034l.007 .025l.008 .02l.005 .026l.012 .037l.004 .028l.006 .025l.003 .026l.006 .033l.002 .03l.003 .028v.026l.002 .033l-.002 .033v.026l-.003 .026l-.002 .032l-.005 .029l-.004 .03l-.006 .024l-.004 .03l-.012 .035l-.005 .027l-.008 .019l-.007 .026l-.015 .033l-.012 .034l-.01 .018l-.007 .018l-.024 .043l-.014 .028l-.007 .009l-.009 .016l-.042 .058l-.012 .019l-.004 .003l-.006 .01a1.006 1.006 0 0 1 -.155 .154l-.009 .006l-.022 .016l-.058 .042l-.016 .009l-.009 .007l-.028 .014l-.043 .024l-.018 .007l-.018 .01l-.034 .012l-.033 .015l-.024 .006l-.021 .009l-.027 .005l-.036 .012l-.029 .004l-.024 .006l-.028 .003l-.031 .006l-.032 .002l-.026 .003h-.026l-.033 .002h-12c-.89 0 -1.337 -1.077 -.707 -1.707l6 -6z" /><path d="M18 13l.033 .002h.026l.026 .003l.032 .002l.031 .006l.028 .003l.024 .006l.03 .004l.035 .012l.027 .005l.019 .008l.026 .007l.033 .015l.034 .012l.018 .01l.018 .007l.043 .024l.028 .014l.009 .007l.016 .009l.051 .037l.026 .017l.003 .004l.01 .006a.982 .982 0 0 1 .154 .155l.006 .009l.015 .02l.043 .06l.009 .016l.007 .009l.014 .028l.024 .043l.005 .013l.012 .023l.012 .034l.015 .033l.007 .026l.008 .02l.005 .026l.012 .036l.004 .029l.006 .024l.003 .028l.006 .031l.002 .032l.003 .026v.026l.002 .033l-.002 .033v.026l-.003 .026l-.002 .032l-.006 .031l-.003 .028l-.006 .024l-.004 .03l-.012 .035l-.005 .027l-.008 .019l-.007 .026l-.015 .033l-.012 .034l-.01 .018l-.007 .018l-.024 .043l-.014 .028l-.007 .009l-.009 .016l-.042 .058l-.012 .019l-.004 .003l-.006 .01l-.073 .081l-6 6a1 1 0 0 1 -1.414 0l-6 -6c-.63 -.63 -.184 -1.707 .707 -1.707h12z" /></svg>
              </span>
              <span *ngIf="header.sort && header.td_key && getSortOrder(header.td_key) === 'asc'">
                <svg  xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24"  fill="currentColor"  class="w-3 fill-lightGray stroke-lightGray"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11.293 7.293a1 1 0 0 1 1.32 -.083l.094 .083l6 6l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059l-.002 .059l-.005 .058l-.009 .06l-.01 .052l-.032 .108l-.027 .067l-.07 .132l-.065 .09l-.073 .081l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002h-12c-.852 0 -1.297 -.986 -.783 -1.623l.076 -.084l6 -6z" /></svg>
              </span>
              <span *ngIf="header.sort && header.td_key && getSortOrder(header.td_key) === 'desc'">
                <svg  xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24"  fill="currentColor"  class="w-3 fill-lightGray stroke-lightGray"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" /></svg>
              </span>
            </span>
              </div>
            </th>
          </ng-container>

          <th *ngIf="tableActions.length > 0"></th>

        </tr>
        </thead>
        <tbody >
        <tr *ngFor="let item of (items && items.data ? items?.data : items2); let i = index" class="text-darkGray border-b-2 border-dashed">
          <ng-container *ngFor="let header of tableHeaders">
            <td  class="py-3 font-[300]" [class]="header?.td_class" *ngIf="header.active">
              <ng-container [ngSwitch]="header.td_type">
                <ng-container *ngIf="header.td_key">
              <span *ngSwitchCase="'text'">
                <span *ngIf="!header.td_sub_key">{{ item[header.td_key] }}</span>
                <span *ngIf="header.td_sub_key">{{ item[header.td_key] ? item[header.td_key][header.td_sub_key] ?? (header.default ?? '-') : (header.default ?? '-') }}</span>
              </span>

               <span *ngSwitchCase="'numeric'">
                <span *ngIf="!header.td_sub_key">{{ item[header.td_key] | number}}</span>
                <span *ngIf="header.td_sub_key">{{ item[header.td_key] ? (item[header.td_key][header.td_sub_key] | number) ?? '-' : '-'  }}</span>
              </span>
              <p-inputSwitch *ngSwitchCase="'boolean-click'" [(ngModel)]="!!item[header.td_key]" (click)="booleanClick(header, i)" />
              <span *ngSwitchCase="'date'">{{ item[header!.td_key] | date:header.format }}</span>

                  <img  *ngSwitchCase="'media'" [class]="header.image_class" [src]="getMediaCollection(item.media, header.td_key)" alt="LOGO">
                  <div *ngSwitchCase="'image'">
                    <img *ngIf="header.td_sub_key && item[header.td_key]; else noimage" [class]="header.image_class" [src]="item[header.td_key][header.td_sub_key]" alt="LOGO">
                    <ng-template #noimage>
                      <img  [class]="header.image_class" src="/assets/img/not-found.png" alt="NOT FOUND">
                    </ng-template>
                  </div>

                </ng-container>
              </ng-container>
            </td>
          </ng-container>
          <td class="py-3 font-[300]" *ngIf="tableActions.length > 0">
            <div class="flex gap-2" *ngIf="showDirectAction">
              <div [pTooltip]="action.name" tooltipPosition="top"  (click)="onClickAction(action, item,i,$event)" class="flex justify-center items-center gap-2 cursor-pointer" [style.color]="action.color" *ngFor="let action of tableActions">
                <div [innerHTML]="safeIconHtml(action)"></div>
              </div>
            </div>
            <button *ngIf="!showDirectAction" (click)="actions.toggle($event)" class="max-h-6 text-primary border-primary border p-1 rounded-md aspect-square hover:bg-primary hover:text-white text-center flex items-center">
              <span>...</span>
              <p-overlayPanel #actions>
                <ul class="p-2">
                  <li (click)="actions.toggle($event);onClickAction(action, item,i,$event)" class="flex justify-center items-center gap-2 cursor-pointer" [style.color]="action.color" *ngFor="let action of tableActions">
                    {{action.name}}  <div [innerHTML]="safeIconHtml(action)"></div>
                  </li>
                </ul>
              </p-overlayPanel>
            </button>

          </td>
        </tr>
        </tbody>
        <caption *ngIf="(items && items.data.length > 0); else noResults" class="caption-bottom">
          <app-paginator [filters]="filters" [sorts]="sortColumns" (onChangePage)="onChangePage($event)" [paginator]="items"></app-paginator>
        </caption>
      </table>
    </div>
  </div>

</div>


<p-confirmDialog #cancel>
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-col items-center p-5 surface-overlay border-round bg-white rounded-2xl">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
        <i class="pi pi-question text-5xl"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>
      <p class="mb-0">{{ message.message }}</p>
      <div class="flex items-center gap-2 mt-4">
        <button
          (click)="cancel.accept()"
          class="bg-primary text-white p-2 bg-opacity-95 rounded-md hover:bg-opacity-90">
          Sí
        </button>
        <button
          pButton
          label="No"
          (click)="cancel.reject()"
          class="bg-primary bg-opacity-0 p-2 hover:bg-opacity-10 text-primary border border-primary">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
