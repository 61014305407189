import {Component, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {AsideService, AsideState} from "../../../../core/services/aside-service/aside-service.service";
import {JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {AsideNavItemComponent} from "./components/aside-nav-item/aside-nav-item.component";
import {slideInOutAnimation} from "../../../../const/animations";
import {menu, MenuItem} from "../../../../const/menu";
import {AuthStateService} from "../../../../core/services/auth/auth-state.service";
import {RouterLink} from "@angular/router";
import {BookFormService} from "../../../../core/services/book-form.service";
import { User } from '../../../../models/User';

@Component({
  selector: 'app-dashboard-aside',
  standalone: true,
  imports: [
    JsonPipe,
    NgIf,
    NgClass,
    AsideNavItemComponent,
    NgForOf,
    RouterLink,
  ],
  templateUrl: './dashboard-aside.component.html',
  styleUrl: './dashboard-aside.component.css',
  encapsulation: ViewEncapsulation.None,
  animations: [
    slideInOutAnimation
  ]
})
export class DashboardAsideComponent {
  @ViewChild('template', { static: true }) template: any;
  isCollapsed = false;
  asideState!: AsideState
  menuState:string = 'out';
  mobileMenuOpen: boolean = false;
  menuItems = menu;
  user: User|null = null;

  permissions: string[] = [];
  isProfessional: boolean = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public asideService: AsideService,
    public authStateService: AuthStateService,
    public bookFormService: BookFormService,
  ) {
    this.asideService.asideState$.subscribe({
      next: (data: AsideState) => {
        this.asideState = data;
      }
    })
    this.asideService.movileMenuOpenState$.subscribe({
      next: (open: boolean) => {
        this.mobileMenuOpen = open;
        this.menuState = open ? 'in' : 'out';
      }
    })

    this.authStateService.currentUser.subscribe(data => {
      this.user = data;
    });

    this.isProfessional = !this.authStateService.isNotProfessional();
    this.authStateService.currentUser.subscribe(data => {
      this.permissions = data?.permissions ?? [];

    })
  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  permissionCheck(item: MenuItem){
    if (item.permission) {
      return this.permissions.indexOf(item.permission) !== -1;
    } else {
      return true;
    }
  }

  public resetBookForm(){
    if (this.user && this.isProfessional) {
      this.bookFormService.resetProfessional({
        registration_number: this.user.profile.name
      }, {name: this.user.profile.name, email: this.user.profile.email, phone_number: this.user.profile.phone})
    } else {
      this.bookFormService.reset(undefined);
    }
  }
}
