<div class="animate-fade-in-down">

  <h1 class="text-2xl">Completa tus datos</h1>
  <p class="text-xs mb-20">Necesarios para la reserva</p>
  <form [formGroup]="step5Form" class="flex flex-col mb-10 gap-y-4">
    <div class="flex flex-col text-start">
      <label class="text-darkGray text-sm mb-1">Nombre <span class="text-xs text-darkGray">(Opcional)</span></label>
      <input formControlName="name" type="text" class="w-full rounded-full bg-lightGray2 px-3 py-2 outline-0 border-0" />
    </div>
    <div class="flex flex-col text-start">
      <label class="text-darkGray text-sm mb-1">Email <span class="text-xs text-darkGray">(Opcional)</span></label>
      <input formControlName="email" type="email" class="w-full rounded-full bg-lightGray2 px-3 py-2 outline-0 border-0" />
    </div>
    <div class="flex flex-col text-start">
      <label class="text-darkGray text-sm mb-1">Móvil<sup class="text-red2">*</sup></label>
      <input formControlName="phone_number" type="text" class="w-full rounded-full bg-lightGray2 text-darkGray px-3 py-2 outline-0 border-0" />
      <div *ngIf="step5Form.get('phone_number')?.invalid && step5Form.get('phone_number')?.touched" appFormError>Número de teléfono no válido</div>
    </div>
    <div class="flex items-center justify-between mt-2">
      <button (click)="goBack()" appFrontBack>
      <span>
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="w-5"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l4 4" /><path d="M5 12l4 -4" /></svg>
      </span>
        <span>Atrás</span>

      </button>
      <div class="max-w-48">
        <button (click)="update()" [disabled]="step5Form.invalid" type="button" appNextButton>
          Continuar
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="w-5">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 12l14 0" />
            <path d="M15 16l4 -4" />
            <path d="M15 8l4 4" />
          </svg>
        </button>
      </div>
      <!--<button type="button" (click)="update()" class="bg-blue-500 px-4 py-2 mt-10 shadow-2xl duration-300 text-xl rounded-2xl text-white w-fit">Continuar →</button>-->
    </div>
  </form>
</div>
