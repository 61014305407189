import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {CardShadowComponent} from "../utils/card-shadow/card-shadow.component";
import {DatePipe, formatDate, NgForOf, NgIf} from "@angular/common";
import {FormSelectSquareDirective} from "../../../../shared/directives/forms/form-select-square.directive";
import {FormsModule} from "@angular/forms";
import {FrontBackDirective} from "../../../../shared/directives/buttons/front.back.directive";
import {LoaderComponent} from "../../../../shared/components/loader/loader.component";
import {MonthCalendarComponent} from "../../../../shared/components/month-calendar/month-calendar.component";
import {NextButtonDirective} from "../../../../shared/directives/buttons/next.directive";
import {NextOutlinedButtonDirective} from "../../../../shared/directives/buttons/next-outlined.directive";
import {OutlineButtonDirective} from "../../../../shared/directives/outline/outline-button.directive";
import {OutlineInputDirective} from "../../../../shared/directives/outline/outline-input.directive";
import {OldReservation, Reservation} from "../../../../models/Reservation";
import {Observable} from "rxjs";
import {Step1, Step2, Step3, Step4, Step5, Step6} from "../../../../models/Front/ReservationForm";
import {select, Store} from "@ngrx/store";
import {
  selectCurrentStep,
  selectShowSummary,
  selectStep1,
  selectStep2, selectStep3, selectStep4, selectStep5, selectStep6
} from "../../../../store/reservationForm/stepper.selector";
import {
  updateStep,
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4
} from "../../../../store/reservationForm/stepper.actions";
import {BookFormService} from "../../../../core/services/book-form.service";

@Component({
  selector: 'app-old-reservation',
  standalone: true,
  imports: [
    CardShadowComponent,
    DatePipe,
    FormSelectSquareDirective,
    FormsModule,
    FrontBackDirective,
    LoaderComponent,
    MonthCalendarComponent,
    NextButtonDirective,
    NextOutlinedButtonDirective,
    NgForOf,
    NgIf,
    OutlineButtonDirective,
    OutlineInputDirective
  ],
  templateUrl: './old-reservation.component.html',
  styleUrl: './old-reservation.component.css'
})
export class OldReservationComponent {

  @Output() onReturnClick = new EventEmitter();
  @Input() oldReservation!: OldReservation;

  showSummary$: Observable<boolean>;
  showSummary:boolean=false;
  currentStep$: Observable<number>;
  currentStep: number = 1;
  @Output() onClearOldReservation = new EventEmitter<string>();
  step1$: Observable<Step1 |undefined>;
  step1?: Step1;
  step2$: Observable<Step2 | undefined>;
  step2?: Step2;
  step3$: Observable<Step3 | undefined>;
  step3?: Step3;
  step4$: Observable<Step4 | undefined>;
  step4?:Step4;
  step5$:Observable<Step5 | undefined>;
  step5?:Step5;
  step6$:Observable<Step6 | undefined>;
  step6?:Step6;

  constructor(private bookFormService:BookFormService, private store: Store, private cd:ChangeDetectorRef) {
    this.showSummary$ = this.store.pipe(select(selectShowSummary));
    this.currentStep$ = this.store.pipe(select(selectCurrentStep));
    this.step1$ = this.store.pipe(select(selectStep1));
    this.step2$ = this.store.pipe(select(selectStep2));
    this.step3$ = this.store.pipe(select(selectStep3));
    this.step4$ = this.store.pipe(select(selectStep4));
    this.step5$ = this.store.pipe(select(selectStep5));
    this.step6$ = this.store.pipe(select(selectStep6));
  }

  defaultReservation(){
    let step1:Step1 = {
      registration_number:this.oldReservation.registration_number
    }
    let step2:Step2 = {
      subStep:3,
      service:this.oldReservation.service,
      vehicle:this.oldReservation.vehicle,
      fuel:this.oldReservation.fuel,
    }
    let step3:Step3 = {
      subStep:2,
      state:this.oldReservation.station.state,
      station:this.oldReservation.station
    }
    let step4:Step4 = {
      date:this.oldReservation.date,
      hour:this.oldReservation.hour,
    }
    // console.log(step4);
    let step = 5;
    this.bookFormService.fillFormUntilStep4(step1,step2,step3,step4);
    this.onClearOldReservation.emit('default');
  }

  otherDateReservation(){
    // console.log(this.oldReservation)
    let step1:Step1 = {
      registration_number:this.oldReservation.registration_number
    }
    let step2:Step2 = {
      subStep:3,
      service:this.oldReservation.service,
      vehicle:this.oldReservation.vehicle,
      fuel:this.oldReservation.fuel,
    }
    let step3:Step3 = {
      subStep:2,
      state:this.oldReservation.station.state,
      station:this.oldReservation.station
    }
/*    let step4:Step4 = {
      month:new Date(this.oldReservation.date).getMonth()+1,
      year:new Date(this.oldReservation.date).getFullYear(),
    }*/
    let step = 4;
    this.bookFormService.fillFormUntilStep3(step1,step2,step3)
    this.onClearOldReservation.emit('otherDate');
  }

  otherStationReservation(){
    // console.log(this.oldReservation)
    let step1:Step1 = {
      registration_number:this.oldReservation.registration_number
    }
    let step2:Step2 = {
      subStep:3,
      service:this.oldReservation.service,
      vehicle:this.oldReservation.vehicle,
      fuel:this.oldReservation.fuel,
    }
    let step3:Step3 = {
      subStep:2,
      state:this.oldReservation.station.state,
    }
    let step = 3;
    this.bookFormService.fillFormUntilStep3State(step1,step2,step3);
    this.onClearOldReservation.emit('otherStation');
  }
}
